import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const saveProfile = newProps => (
    {
        type: ActionTypes.SAVE_PROFILE,
        payload: axios.post(`${getEnvVar('API_BASE')}/save-profile`, newProps)
    }
);

export const setProfileEditingSection = name => (
    {
        type: ActionTypes.SET_PROFILE_EDITING_SECTION,
        payload: name
    }
);

export const getSubscriptions = () => (
    {
        type: ActionTypes.USER_SUBSCRIPTIONS,
        payload: axios.get(`${getEnvVar('API_BASE')}/subscription-state`)
    }
);

export const cancelSubscription = subscription_id => (
    {
        type: ActionTypes.CANCEL_SUBSCRIPTION,
        payload: axios.post(`${getEnvVar('API_BASE')}/cancel-subscription`, { subscription_id })
    }
);

export const resumeSubscription = subscription_id => (
    {
        type: ActionTypes.UPGRADE_SUBSCRIPTION,
        payload: axios.post(`${getEnvVar('API_BASE')}/resume-subscription`, { subscription_id })
    }
);

export const upgradeToAnnual = subscription_id => (
    {
        type: ActionTypes.UPGRADE_SUBSCRIPTION,
        payload: axios.post(`${getEnvVar('API_BASE')}/upgrade-to-annual`, { subscription_id })
    }
);

export const fetchReferralCode = () => (
    {
        type: ActionTypes.FETCH_REFERRAL_CODE,
        payload: axios.get(`${getEnvVar('API_BASE')}/referral-code`)
    }
);

export const fetchMyLearning = () => (
    {
        type: ActionTypes.FETCH_MY_LEARNING,
        payload: axios.get(`${getEnvVar('API_BASE')}/my-learning`)
    }   
);

export const fetchMyFavorites = () => (
    {
        type: ActionTypes.FETCH_MY_FAVORITES,
        payload: axios.get(`${getEnvVar('API_BASE')}/my-favorites`)
    }   
);

export const fetchMyProgressPosts = () => (
    {
        type: ActionTypes.FETCH_PROGRESS_POSTS,
        payload: axios.get(`${getEnvVar('API_BASE')}/my-progress-posts`)
    }   
);
