import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons'

const Icon = styled.div`
    font-size: 40px;
    flex-grow: 0;
`;

const MessageContent = styled.div`
    margin-left: 25px;
    flex-grow: 1;

    h3 {
        margin: 0 0 5px 0;
        font-size: 20px;
    }
`;


const Message = styled.div`
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border-radius: 7px;

    margin: 10px 0;

    a {
        color: #fff;
    }

    ${props => {
        if (props.success) {
            return `
                background-color: #33ae23;
            `;
        }

        if (props.warning) {
            return `
                background-color: #e3db60;
                color: #333;

                a {
                    color: #333;
                }
            `;
        }

        return `
            background-color: #c60000;
        `;
    }}

    ${props => {
        if (props.small) {
            return `
                padding: 10px 12px;

                ${Icon} {
                    font-size: 25px;
                }

                ${MessageContent} {
                    h3 {
                        font-size: 16px;
                    }

                    font-size: 14px;
                }
            `;
        }
    }}

    ${props => {
        if (props.noIcon) {
            return `
                ${MessageContent} {
                    margin-left: 0;
                }
            `;
        }
    }}
`;

export default (props) => {
    const { heading, children, success, warning, noIcon } = props;

    const getIcon = () => {
        if (success) {
            return faCheckCircle;
        }
        if (warning) {
            return faExclamationTriangle;
        }
        return faTimes;
    };

    return (
        <Message {...props}>
            {!noIcon && <Icon><FontAwesomeIcon icon={getIcon()} /></Icon>}
            <MessageContent>
                {heading && <h3>{heading}</h3>}
                {children}
            </MessageContent>
        </Message>
    );
};