import React, { useState } from 'react';
import CourseDetails from './CourseDetails';
import ProgressPostStage from './ProgressPostStage';

const ProgressPost = ({ currentVideo, course }) => {
    const [showResponse, setShowResponse] = useState(false);
    
    return (
        <>
            <ProgressPostStage progressPost={currentVideo?.progress_post} showResponse={showResponse} />
            <CourseDetails id="details" course={course} showResponse={showResponse} setShowResponse={setShowResponse} />
        </>
    );
};

export default ProgressPost;
