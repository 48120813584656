import styled from 'styled-components';

export const ModalWrapper = styled.div`
    max-width: 800px;
    min-height: 300px;
    margin: 0 auto;
    background-color: #fff;
    position: relative; 

    transition: transform 0.3s;
`;

const mobileBP = '500px';

const mobilePadding = `
    @media screen and (max-width: ${mobileBP}) {
        padding: 15px 20px;
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 35px;
    background-color: ${props => props.theme.colors.modalHeader};
    color: ${props => props.theme.colors.modalHeaderText};

    h2 {
        margin: 0;
        font-size: 25px;
        font-weight: 600;
        flex-grow: 1;

        @media screen and (max-width: ${mobileBP}) {
            font-size: 20px;
            line-height: 1.6em;
        }
    }

    ${mobilePadding}
`;

export const ModalClose = styled.div`
    font-size: 24px;
    line-height: normal;
    cursor: pointer;
    color: ${props => props.theme.colors.lightGrayText};
`;

export const ModalBody = styled.div`
    padding: 30px 35px;
    position: relative;

    ${mobilePadding}
`;

export const Modal = styled.div`
    position: fixed; 
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;

    padding: 80px 15px;
    overflow-y: auto;

    transition: background-color 0.3s;

    ${ModalWrapper} {
        transform: translateY(calc(-100% - 80px));
    }

    @media screen and (max-width: 800px) {
        padding-top: 30px;

        ${ModalWrapper} {
            transform: translateY(calc(-100% - 32px));
        }
    }

    ${props => {
        if (props.open) {
            return `
                background-color: rgba(0, 0, 0, 0.74);

                ${ModalWrapper} {
                    transform: translateY(0);
                }
            `;
        } else {
            return `pointer-events: none;`;
        }
    }}
`;

