import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../history';
import { getEnvVar } from 'utils/get-env-var';

import Home from './Home';
import Courses from './Courses';
import Curriculum from './Curriculum';
import Course from './Course';
import Account from './Account';
import ResetPassword from './ResetPassword';
import ConfirmEmail from './ConfirmEmail';
import StaticPage from './StaticPage';
import ReferralCodeApplied from './ReferralCodeApplied';
import ScrollToTop from './ScrollToTop';
import JoinTrigger from './JoinTrigger';
import LoginModal from './LoginModal';
import JoinModal from './JoinModal';
import ThankYou from './ThankYou';
import Faqs from './Faqs';
import BookLandingPage from './LandingPage/Book';
import EmailFormThankYou from './LandingPage/EmailFormConfirmation';

const RouterWrapper = () => {
    const ROOT_ROUTE = getEnvVar('ROOT_ROUTE');

    return (
        <Router history={history}>
            <Switch>
                <Route path={`${ROOT_ROUTE}/`} exact render={props => <Home {...props} />} />
                <Route path={`${ROOT_ROUTE}/join/:plan?`} exact render={props => <JoinTrigger {...props} />} />
                <Route path={`${ROOT_ROUTE}/thank-you`} exact render={props => <ThankYou {...props} />} />
                <Route path={`${ROOT_ROUTE}/courses`} exact render={props => <Courses {...props} />} />
                <Route path={`${ROOT_ROUTE}/curriculum`} exact render={props => <Curriculum {...props} />} />
                <Route path={`${ROOT_ROUTE}/course/:slug`} exact render={props => <Course {...props} />} />
                <Route path={`${ROOT_ROUTE}/reset-password`} exact render={props => <ResetPassword {...props} />} />
                <Route path={`${ROOT_ROUTE}/confirm-email`} exact render={props => <ConfirmEmail {...props} />} />
                <Route path={`${ROOT_ROUTE}/account`} exact render={props => <Account {...props} />} />
                <Route path={`${ROOT_ROUTE}/referral/:code`} exact render={props => <ReferralCodeApplied {...props} />} />
                <Route path={`${ROOT_ROUTE}/faq`} exact render={props => <Faqs {...props} />} />
                <Route path={`${ROOT_ROUTE}/on-drumming`} exact render={props => <BookLandingPage {...props} />} />
                <Route path={`${ROOT_ROUTE}/email/thank-you`} exact render={props => <EmailFormThankYou {...props} />} />
                <Route component={StaticPage} />
            </Switch>
            <ScrollToTop />
            <LoginModal />
            <JoinModal />
        </Router>
    );
};

export default RouterWrapper;
