import React from 'react';

import { ThreeGrid, GridItem } from 'components/_core/grids';
import CourseThumb from 'components/_core/CourseThumb';

const CourseGrid = ({ courses, Grid = ThreeGrid, ...props }) => {
    return (
        <Grid threeBreak="1200px" twoBreak="1000px" stackPoint="700px">
            {courses.map((c, i) => (
                <GridItem key={i}>
                    <CourseThumb {...c} {...props} />
                </GridItem>
            ))}
        </Grid>
    );
};

export default CourseGrid;