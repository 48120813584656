import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import styled from 'styled-components';

import CourseHeader from './CourseHeader';
import CourseOutline from './CourseOutline';
import CourseStage from './CourseStage';
import CourseDetails from './CourseDetails';
import CourseModal from 'components/Courses/CourseModal';
import CourseFetcher from './CourseFetcher';
import DownloadData from './DownloadData';
import { getEnvVar } from 'utils/get-env-var';

import { setCurrentVideo } from 'redux/actions';
import ProgressPost from './ProgressPost';

const outlineWidth = 400;
const headerHeight = 80;

const Container = styled.div`
    position: absolute;
    top: ${headerHeight}px;
    left: 0;
    width: 100%;
    height: calc(100% - ${headerHeight}px);   
`;

const CourseWrap = styled(Container)`
    overflow: hidden;
    height: 100%;
    top: 0;
`;

const CourseMain = styled(Container)`
    background-color: #17181a;
    width: calc(100% - ${outlineWidth}px);
    height: calc(100% - ${headerHeight}px);

    overflow-y: auto;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const Course = () => {
    const dispatch = useDispatch();
    const course = useSelector(state => state.currentCourse.courseDetails.course);
    const currentVideo = useSelector(state => state.currentCourse.currentVideo);
    const currentVideoSet = currentVideo.type === 'progress' || !!currentVideo.videoId;
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    useEffect(() => {
        if (course && !currentVideoSet && course.previewVideoId && isSubscriber) {
            dispatch(setCurrentVideo({ id: `course-${course.id}`, videoId: course.previewVideoId, isPreview: true }));
        }
    }, [dispatch, course, currentVideoSet, isSubscriber]);

    if (!isSubscriber) {
        return <Redirect to={`${getEnvVar('ROOT_ROUTE')}/courses`} />;
    }

    return (
        <CourseWrap id="wrap">
            <CourseHeader id="header" title={course?.name} percentComplete={course?.percent_complete} />
            <CourseFetcher>
                <CourseMain id="main">
                    {
                        currentVideo.type === 'progress' 
                        ? <ProgressPost course={course} currentVideo={currentVideo} /> 
                        : (
                            <>
                                <CourseStage id="stage" course={course} />
                                <CourseDetails id="details" course={course} />
                            </>
                        )
                    }
                </CourseMain>
                <CourseOutline id="outline" course={course} />
                <CourseModal id="modal" />
            </CourseFetcher>
            <DownloadData />
        </CourseWrap>
    );
};

export default Course;
