import { combineReducers } from 'redux';

import courseList from './course-list';
import currentCourse from './current-course';
import user from './user';
import { contentStats } from './content-stats';
import { sales } from './sales';
import { toastNotifications } from './toast-notifications';
import { impact } from './impact';

export default combineReducers({
    courseList,
    currentCourse,
    user,
    contentStats,
    sales,
    toastNotifications,
    impact
});
