import styled from 'styled-components';

export const CourseMetaData = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: ${props => props.lightBackground ? '#666' : props.theme.colors.lightGrayText};
    margin-bottom: 10px;
    font-weight: 400;
`;

const levelKey = level => {
    if (!level) return 'all_levels';
    return level.replace(' ', '_').toLowerCase();
}

export const CourseLevel = styled.div`
    color: ${props => {
        if (props.lightBackground) {
            return '#666';
        }
        
        return props.theme.levelColors[levelKey(props.level)];
    }};
`;

export const CourseLength= styled.div``;