import React, { useEffect } from 'react';
import InlineLoader from 'components/_core/InlineLoader';
import Message from 'components/_core/Message';
import { fetchCourseForModal } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const CourseFetcher = (
    {
        loader,
        slug,
        children
    }
) => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.courseList.courseModal.fetchedCourse);
    const error = useSelector(state => state.courseList.courseModal.courseError);
    const loadedCourseSlug = useSelector(state => state.courseList.courseModal.modalCourse?.slug);

    useEffect(() => {
        if (loadedCourseSlug !== slug) {
            dispatch(fetchCourseForModal(slug));
        }
    }, [dispatch, slug, loadedCourseSlug])

    if (error) {
        return (
            <Message heading="Error loading course" error noIcon small>
                Refresh the page to try again.
            </Message>
        );
    }

    if (!fetched) {
        return loader || <InlineLoader />;
    }

    return children;
};

export default CourseFetcher;
