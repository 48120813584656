import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CourseList from './CourseList';
import CourseGrid from './CourseGrid';

import { coursesFiltered } from 'redux/selectors/courses-filtered';

const NoCourses = styled.div`
    text-align: center;
    font-size: 22px;
    color: #444;
    margin-top: 60px;
`;

const CourseResults = ({ type, showCategories, showLevels }) => {
    const courses = useSelector(coursesFiltered({
        type, 
        useCategoryFilter: showCategories,
        useLevelFilter: showLevels
    }));
    
    const listView = useSelector(state => state.courseList.filters.listView);

    if (!courses || !courses.length) {
        return <NoCourses>No courses match your filtering</NoCourses>;
    }

    if (listView) {
        return <CourseList courses={courses} />;
    }

    return <CourseGrid courses={courses} />;
};

export default CourseResults;