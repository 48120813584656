import React from 'react';
import styled from 'styled-components';

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { GridItem, ThreeGrid } from 'components/_core/grids';

import brianChiusano from 'img/homepage/user-photos/brian-chiusano.jpeg';
import karolineGawenda from 'img/homepage/user-photos/karoline-gawenda.png';
import jamVillanueva from 'img/homepage/user-photos/jam-villanueva.jpg';
import joriRouto from 'img/homepage/user-photos/jori-routo.jpg';
import johnHoff from 'img/homepage/user-photos/john-hoff.jpg';
import ryanHassan from 'img/homepage/user-photos/ryan-hassan.jpg';
import teresaEsguerra from 'img/homepage/user-photos/teresa-esguerra.jpg';
import hitashiBansal from 'img/homepage/user-photos/hitashi-bansal.png';
import julekJanski from 'img/homepage/user-photos/julek-janski.png';

const Wrapper = styled(W)`
    background-color: #fff;
`;

const Container = styled(C)`
    padding: 30px 0;
`;

const TextHeading = styled.div`
    margin: 0 auto 30px;
    max-width: 640px;
    color: #000;
    text-align: center;

    h2 {
        font-size: 30px;
        margin: 0;
        font-weight: 200;

        strong {
            font-style: italic;
            font-weight: 700;
        }
    }

    p {
        line-height: 1.4em;
        color: #666;
        font-style: italic;
        margin-top: 7px;
    }
`;

const PersonGrid = styled(ThreeGrid)`
    margin: -3px;

    ${GridItem} {
        padding: 3px;
    }

    @media screen and (max-width: 1000px) {
        ${GridItem}:nth-child(n+7) {
            display: none;
        }
    }

    @media screen and (max-width: 400px) {
        ${GridItem}:nth-child(n+5) {
            display: none;
        }
    }
`;


const Person = styled(GridItem)`
    text-align: center;

    p {
        color: ${props => props.theme.colors.teal};
        font-weight: 700;
        line-height: 1.4em;
        margin: 5px 0 30px;
        color: #666;

        span {
            font-style: italic;
            font-weight: 400;
        }
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
`;

const AllLevels = () => {
    return (
        <Wrapper>
            <Container>
                <TextHeading>
                    <h2>
                        <strong>Beginners</strong> and <strong>Professionals</strong> study here.
                    </h2>
                    <p>The measure of a good method is that it remains useful throughout the entire journey to mastery.</p>
                </TextHeading>

                <PersonGrid>
                    <Person>
                        <img src={teresaEsguerra} alt="Teresa Esguerra" loading="lazy" />
                        <p>
                            Teresa Esguerra<br/>
                            <span>Built to Spill</span>
                        </p>
                    </Person>
                    <Person>
                        <img src={brianChiusano} alt="Brian Chiusano" loading="lazy" />
                        <p>
                            Brian Chiusano<br/>
                            <span>(Drumming for 2 years)</span>
                        </p>
                    </Person>
                    <Person>
                        <img src={ryanHassan} alt="Ryan Hassan" loading="lazy" />
                        <p>
                            Ryan Hassan<br/>
                            <span>Rod Stewart</span>
                        </p>
                    </Person>
                    <Person>
                        <img src={hitashiBansal} alt="Hitashi Bansal" loading="lazy" />
                        <p>
                            Hitashi Bansal<br/>
                            <span>(Drumming for 2 years)</span>
                        </p>
                    </Person>
                    <Person>
                        <img src={jamVillanueva} alt="Jam Villanueva" loading="lazy" />
                        <p>
                            Jam Villanueva<br/>
                            <span>Ben&Ben</span>
                        </p>
                    </Person>
                    <Person>
                        <img src={julekJanski} alt="Julek Janski" loading="lazy" />
                        <p>
                            Julek Janski<br/>
                            <span>(Drumming for 2 years)</span>
                        </p>
                    </Person>

                    <Person>
                        <img src={johnHoff} alt="John Hoff" loading="lazy" />
                        <p>
                            John Hoff<br/>
                            <span>The Color Fred</span>
                        </p>
                    </Person>
                    <Person>
                        <img src={karolineGawenda} alt="Karoline Gawenda" loading="lazy" />
                        <p>
                            Karoline Gawenda<br/>
                            <span>(Drumming for 2 years)</span>
                        </p>
                    </Person>
                    <Person>
                        <img src={joriRouto} alt="Jori Routo" loading="lazy" />
                        <p>
                            Jori Routo<br/>
                            <span>ASTE</span>
                        </p>
                    </Person>
                </PersonGrid>
            </Container>
        </Wrapper>
    );
};

export default AllLevels;
