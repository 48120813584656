import React from 'react';
import styled from 'styled-components';

const NavItem = styled.li`
    color: #eee;

    padding: 20px 10px 16px 10px;
    margin: 0 15px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    border-bottom: 4px solid transparent;

    ${props => {
        if (props.active) {
            return `border-bottom-color: ${props.theme.colors.teal};`
        } else {
            return `cursor: pointer;`;
        }
    }}

`;

const Nav = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    padding: 0;
    margin: 0;

    border-bottom: 1px solid #333;
`;

const TabView = ({ sections, activeTab, setActiveTab }) => {
    const nav = (
        <Nav>
            {sections.map((section, i) => {
                return (
                    <NavItem key={`tab-section-${i}`} active={activeTab === i} onClick={() => setActiveTab(i)}>{section.title}</NavItem>
                );
            })}
        </Nav>
    );

    if (activeTab > sections.length - 1) {
        activeTab = 0;
    }

    const ActiveSection = (sections[activeTab] || {}).Content;

    return (
        <>
            {nav}
            <ActiveSection />
        </>
    );
};

TabView.displayName = 'TabView';

export default TabView;
