import React, { useEffect } from 'react';
import styled from 'styled-components';
import C from "components/_core/Container";
import InlineLoader from "components/_core/InlineLoader";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeaturedCourses } from "redux/actions";
import CourseGrid from 'components/Courses/CourseGrid';
import { FourGrid, GridItem } from 'components/_core/grids';
import W from 'components/_core/Wrapper';

const Wrapper = styled(W)`
    background-color: #fff;
`;

const Container = styled(C)`
    padding: 30px 0;
`;

const TopText = styled.div`
    margin: 0 auto 30px;

    max-width: 400px;
    color: #000;
    text-align: center;

    font-style: italic;

    h2 {
        font-size: 30px;
        font-weight: 600;
        margin: 0;
    }

    p {
        color: #666;
        margin: 0;
        line-height: 1.4em;
    }
`;

const FeaturedCoursesGrid = styled(FourGrid)`
    @media screen and (max-width: 1000px) {
        ${GridItem}:nth-child(n+9) {
            display: none;
        }
    }

    @media screen and (max-width: 400px) {
        ${GridItem}:nth-child(n+4) {
            display: none;
        }
    }
`;

const FeaturedCouses = () => {
    const dispatch = useDispatch();
    const featuredCourses = useSelector(state => state.courseList.featuredCourses.courses);

    useEffect(() => {
        dispatch(fetchFeaturedCourses());
    }, [dispatch]);
    
    if (!featuredCourses) {
        return <div style={{ margin: '30px 0' }}><InlineLoader /></div>;
    }

    return (
        <Wrapper>
            <Container>
                <TopText>
                    <h2>Most Popular Courses</h2>
                    <p>Each course develops proficiency and creativity with the subject through step-by-step methods.</p>
                </TopText>
                <CourseGrid Grid={FeaturedCoursesGrid} courses={featuredCourses} showButton hideDescription light />
            </Container>
        </Wrapper>
    )
};

export default FeaturedCouses;
