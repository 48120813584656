import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InlineLoader from 'components/_core/InlineLoader';
import Message from 'components/_core/Message';

import { fetchCourse } from 'redux/actions';

const headerHeight = 80;

const Container = styled.div`
    position: absolute;
    top: ${headerHeight}px;
    left: 0;
    width: 100%;
    height: calc(100% - ${headerHeight}px);
    
    background-color: #17181a;
    overflow-y: auto;

    padding: 30px 15px;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const CourseFetcher = (
    {
        children,
        match: { params: { slug: slugInParams } }
    }
) => {
    const dispatch = useDispatch();

    const fetched = useSelector(state => state.currentCourse.courseDetails.fetched);
    const error = useSelector(state => state.currentCourse.courseDetails.error);

    useEffect(() => {
        dispatch(fetchCourse(slugInParams));
    }, [slugInParams, dispatch]);

    if (error) {
        return (
            <Container>
                <Message heading="Error loading course" error noIcon small>
                    Refresh the page to try again.
                </Message>
            </Container>
        );
    }

    if (!fetched) {
        return <Container><InlineLoader text="Loading Course" /></Container>;
    }

    return children;
};

export default withRouter(CourseFetcher);
