import React from 'react';
import styled from 'styled-components';
import { getEnvVar } from 'utils/get-env-var';

import Wrapper from 'components/_core/Wrapper';
import Header from 'components/_core/Header';
import Hero from 'components/_core/Hero';
import UnderlineLink from 'components/_core/UnderlineLink';
import LoginMenuItem from 'components/LoginMenuItem';
import Container from 'components/_core/Container';
import Footer from 'components/_core/Footer';
import Profile from './Profile';
import Subscriptions from './Subscriptions';
import ReferFriend from './ReferFriend';

const navItems = [
    () => <li className="hide-mobile"><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/courses`}>Courses</UnderlineLink></li>,
    () => <li><LoginMenuItem /></li>
];

const AccountContainer = styled(Container)`
    padding-bottom: 60px;
`;

const AccountColumns = styled.div`
    display: flex;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const AccountColumn = styled.div`
    flex-basis: 50%;
    max-width: 450px;
    padding: 0 20px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    @media screen and (max-width: 800px) {
        padding: 20px 0;
    }
`;

const AccountWrapper = () => (
    <>
        <Wrapper>
            <Header hideLogoUntilScroll navItems={navItems} />
            <Hero hideCoursesBtn>Account</Hero>
            <AccountContainer>
                <AccountColumns>
                    <AccountColumn>
                        <Profile />
                    </AccountColumn>
                    <AccountColumn>
                        <Subscriptions />
                    </AccountColumn>
                </AccountColumns>
                <ReferFriend />
            </AccountContainer>
        </Wrapper>
        <Footer />
    </>
);

export default AccountWrapper;
