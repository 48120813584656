import React, { useState } from 'react';
import styled from 'styled-components';
import Input from './Input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const PasswordWrapper = styled.div`
    position: relative;
`;

const PasswordIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 20px;
    color: #666;
    transform: translate(0, -50%);
`;

const PasswordInput = props => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <PasswordWrapper>
            <Input {...props} type={showPassword ? 'text' : 'password'} />
            <PasswordIcon onClick={() => setShowPassword(!showPassword)} icon={showPassword ? faEyeSlash : faEye} /> 
        </PasswordWrapper>
    );
};

export default PasswordInput;
