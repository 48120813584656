import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const fetchCourses = () => (
    {
        type: ActionTypes.FETCH_COURSES,
        payload: axios.get(`${getEnvVar('API_BASE')}/courses`)
    }
);

export const setMyLearningView = view => (
    {
        type: ActionTypes.SET_MY_LEARNING_VIEW,
        payload: view
    }
);

export const fetchFeaturedCourses = () => (
    {
        type: ActionTypes.FETCH_FEATURED_COURSES,
        payload: axios.get(`${getEnvVar('API_BASE')}/featured-courses`)
    }
);

export const toggleListView = () => (
    {
        type: ActionTypes.TOGGLE_LIST_VIEW
    }
);

export const setFilter = (key, val) => (
    {
        type: ActionTypes.SET_FILTER,
        payload: { [key]: val }
    }
);

export const resetFilterParams = payload => (
    {
        type: ActionTypes.RESET_FILTERS,
        payload
    }
);

export const toggleCourseSearch = () => {
    return (dispatch, getState) => {
        const state = getState();
        const searchOpen = state.courseList.filters.searchOpen;

        if (!searchOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        dispatch({
            type: ActionTypes.TOGGLE_COURSE_SEARCH
        });
    }
};
