import { createSelector } from 'reselect';

const videoId = (state, ownProps) => ownProps.videoId;
const processingVideos = state => state.currentCourse.completeVideos.videosProcessing;

export const isVideoCompletenessProcessing = createSelector(
    [videoId, processingVideos],
    (id, processingVideosIds) => {
        return processingVideosIds.has(id);
    }
);
