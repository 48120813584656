import React from 'react';
import styled from 'styled-components';

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { GridItem, ThreeGrid, TwoGrid } from 'components/_core/grids';
import BackgroundImg from 'img/homepage/personalized-feedback-bg.jpg';
import StepsImg from 'img/homepage/personalized-feedback.png';
import { Testimonial } from './TestimonialGrid';

const Wrapper = styled(W)`
    position: relative;
    padding: 40px 30px 50px;
    overflow: hidden;
`;

const Background = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
`;

const Container = styled(C)`
    padding: 30px 0;
`;

const StepsText = styled(GridItem)`
    max-width: 450px;

    h2 {
        margin-bottom: 20px;
        span {
            font-size: 60px;
            line-height: 1.1em;
        }

        @media screen and (max-width: 600px) {
            span {
                font-size: 40px;
            }
        }
    }

    ol {
        font-size: 25px;
        padding-left: 60px;
        margin-top: 40px;

        li {
            margin-bottom: 20px;
            font-weight: 900;

            span {
                font-weight: 400;
            }
        }
    }
`;

const FeedbackStepsImgGridItem = styled(GridItem)`
`;

const FeedbackStepsImg = styled.img`
    width: 100%;
    max-width: 350px;
`;

const FeedbackGrid = styled(TwoGrid)`
    @media screen and (max-width: 850px) {
        ${StepsText}, ${FeedbackStepsImgGridItem} {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
`;

const TestiGrid = styled(ThreeGrid)`
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;

    @media screen and (max-width: 800px) {
        ${Testimonial}:nth-child(3) {
            display: none;
        }
    }

    @media screen and (max-width: 400px) {
        ${Testimonial}:nth-child(2) {
            display: none;
        }
    }
`;



const PersonalizedFeedback = () => {
    return (
        <Wrapper>
            <Container>
                
                <FeedbackGrid>
                    <StepsText>
                        <h2>
                            <span>Personalized Feedback</span><br/>
                            After Every Chapter
                        </h2>

                        <ol>
                            <li><span>Finish chapter</span></li>
                            <li><span>Submit Progress Post</span></li>
                            <li><span>Get personalized feedback, exercises, and advice</span></li>
                        </ol>
                    </StepsText>
                    <FeedbackStepsImgGridItem>
                        <FeedbackStepsImg src={StepsImg} alt="Personalized feedback steps" />
                    </FeedbackStepsImgGridItem>
                </FeedbackGrid>

                <TestiGrid>
                    <Testimonial>
                        <p>“That 10 min personalized video is worth the cost of subscription and then some.”</p>
                        <p className="teal">&mdash; Danny Y.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“JP, wow! I can’t believe the level of feedback and personal touch you give your members. This is absolutely amazing.”</p>
                        <p className="teal">&mdash; Jon P.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“Thanks again for taking the time to really analyze my playing and provide such valuable personalized feedback.” </p>
                        <p className="teal">&mdash; Ryan O.</p>
                    </Testimonial>
                </TestiGrid>
            
            </Container>

            <Background src={BackgroundImg} />
        </Wrapper>
    );
};

export default PersonalizedFeedback;
