import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getSubscriptions } from 'redux/actions';
import InlineLoader from 'components/_core/InlineLoader';
import CancelSubscription from './CancelSubscription';
import UpgradeSubscription from './UpgradeSubscription';
import { currencySymbol } from 'utils/currency-symbol';
import ResumeSubscription from './ResumeSubscription';

const SubscriptionHeading = styled.h2`
    margin: 0;
    font-weight: normal;
    margin-bottom: 20px;

    .inactive {
        color: #c60000;
    }
    .active {
        color: #33ae23;
    }
    .canceling {
        color: #fff200;
    }
`;

const Property = styled.div`
    margin-bottom: 20px;
    padding-bottom: 20px;
    max-width: 400px;

    border-bottom: 1px solid #222;

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
    }
`;

const PropertyLabel = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 5px;
`;


const PropertyValue = styled.div``;

const Subscriptions = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.user.subscription.fetched);
    const subState = useSelector(state => state.user.subscription.state) || {};

    useEffect(() => {
        if (!fetched) {
            dispatch(getSubscriptions());
        }
    }, [dispatch, fetched]);

    if (!fetched) {
        return <InlineLoader text="Loading subscriptions" />;
    }

    if (!subState.has_active_subscription) {
        return <SubscriptionHeading>Subscription status: <span className="inactive">Inactive</span></SubscriptionHeading>;
    }

    const nextBillDate = new Date(subState.next_bill_date * 1000);

    const state = subState.canceling ? 'canceling' : 'active';

    return (
        <>
            <SubscriptionHeading>Subscription status: <span className={state}>{state === 'active' ? 'Active' : 'Canceling'}</span></SubscriptionHeading>
            {!subState.canceling && <div>
                <Property>
                    <PropertyLabel>Plan</PropertyLabel>
                    <PropertyValue>
                        {subState.plan}
                    </PropertyValue>
                </Property>
                <Property>
                    <PropertyLabel>Price</PropertyLabel>
                    <PropertyValue>{currencySymbol(subState.currency)}{subState.price/100} / {subState.interval}</PropertyValue>
                </Property>
                {!subState.canceling && <Property>
                    <PropertyLabel>Next Invoice</PropertyLabel>
                    <PropertyValue>{currencySymbol(subState.currency)}{subState.next_bill_amount} on {nextBillDate.getMonth()+1}/{nextBillDate.getDate()}/{nextBillDate.getFullYear()}</PropertyValue>
                </Property>}
            </div>}

            <UpgradeSubscription />

            {!subState.canceling ? <CancelSubscription /> : <ResumeSubscription />}
        </>
    );
};

export default Subscriptions;