
import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';
import { setAxiosAuthHeader } from 'utils/set-axios-auth-header';
import { setCookie } from 'utils/set-cookie';

export const auth = store => next => action => {
    next(action);

    if (
        action.type === ActionTypes.CREATE_ACCOUNT + '_FULFILLED' || 
        action.type === ActionTypes.LOGIN + '_FULFILLED' || 
        action.type === ActionTypes.RESET_PASSWORD + '_FULFILLED' || 
        action.type === ActionTypes.VALIDATE_JWT + '_FULFILLED' ||
        action.type === ActionTypes.SAVE_PROFILE + '_FULFILLED'
    ) {
        const jwt = getDeepProp(action, 'payload', 'data', 'token');

        // set cookie
        setCookie('token', jwt, 30);

        // set axios header
        setAxiosAuthHeader(jwt);
    }

    if (action.type === ActionTypes.VALIDATE_JWT + '_REJECTED' || action.type === ActionTypes.LOGOUT) {
        // set cookie
        setCookie('token', '', -1);
    }
};
