import styled from 'styled-components';
import { Link } from 'react-router-dom';

const UnderlineLink = as => styled(as)`
    position: relative;
    padding-bottom: 3px;
    font-family: ${props => props.theme.alternativeFont};
    font-weight: 500 !important;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        border-bottom: 2px solid ${props => props.theme.colors.teal};
        transition: width 0.3s;
    }

    &:hover { 
        &:after {
            width: 100%;
        }
    }
`;

export const UnderlineATag = UnderlineLink('a');
export default UnderlineLink(Link);
