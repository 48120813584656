import React, { useState } from 'react';
import styled from 'styled-components';

import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';
import { toast } from 'react-toastify';

import { Modal, ModalHeader, ModalWrapper, ModalClose, ModalBody as ModalBodyBase } from 'components/_core/Modal';
import Input from 'components/_core/Input';
import Checkbox from 'components/_core/Checkbox';
import LoaderButton from 'components/_core/LoaderButton';
import Message from 'components/_core/Message';
import { useDispatch, useSelector } from 'react-redux';
import { addProgressPost } from 'redux/actions';

const ModalBody = styled(ModalBodyBase)`
    color: #545454;
    font-size: 18px;

    margin-right: 45px;

    ol {
        margin: 0;

        li {
            strong {
                font-style: italic;
            }

            .small {
                font-size: 15px;
                line-height: 1.4em;
            }
        }
    }
`;

const ModalFooter = styled.div`
    background-color: ${props => props.theme.colors.offWhite};
    border-top: 1px solid ${props => props.theme.colors.grayBorder};
    padding: 30px 45px;
    color: #545454;

    h2 {
        margin: 0;
    }

    ol {
        margin: 0;
        padding-left: 20px;

        li {
            margin: 7px 0;
        }
    }
`;

const FormWrap = styled.form`
    max-width: 400px;
    margin: 0 auto;

    ${Checkbox} {
        margin-bottom: 10px;
    }
`;

const ProgressPostModal = ({ open, closeModal, videoId }) => {
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    const [sendToJp, setSendToJp] = useState(false);
    const [url, setUrl] = useState('');

    const courseId = useSelector(state => state.currentCourse.courseDetails.course.id);

    const save = e => {
        e.preventDefault();

        if (!url || !url.length) {
            return setError('Please enter the Youtube url');
        }

        if (!/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/.test(url)) {
            return setError('Please enter a valid Youtube url');
        }

        setError(false);
        setSaving(true);

        (async () => {
            try {
                const resp = await axios.post(`${getEnvVar('API_BASE')}/save-progress-post`, { videoId, courseId, sendToJp, url });

                dispatch(addProgressPost(videoId, { youtube_id: resp.data.progressVideoId, response_requested: sendToJp }));

                toast.info('Your progress post has been saved');
                closeModal();
                
            } catch {
                setError('An unexpected error occured. Please try again.');
            }

            setSaving(false);
        })();
    };

    return (
        <Modal open={open} onClick={() => closeModal()}>
            <ModalWrapper onClick={e => e.stopPropagation()} style={{ borderRadius: '5px', overflow: 'hidden' }}>
                <ModalHeader style={{ padding: '14px 20px'}}>
                    <h2 style={{marginLeft: '10px'}}>Progress Post</h2>
                    <ModalClose onClick={() => closeModal()}>&times;</ModalClose>
                </ModalHeader>
                <ModalBody>
                    <ol>
                        <li><p><strong>Practice</strong> the last lesson for about a week.</p></li>
                        <li><p><strong>Film</strong> a 1-3 minute video improvising or demonstrating the course material up to this point (phone video is fine).</p></li>
                        <li><p><strong>Upload</strong> to YouTube and set privacy setting to "unlisted".</p></li>
                        <li><p><strong>Paste</strong> your video URL below.</p></li>
                        <li><p><strong>Check</strong> the “get personalized feedback” checkbox if you would like to receive a video with feedback from one of the instructors.</p>
                        <p className="small">Mistakes are welcome! Stopping and starting is welcome! Explaining what you’re thinking or struggling with is welcome! Try not to do multiple takes. Fire away and upload. Keep it casual.</p></li>
                    </ol>

                    <FormWrap>
                        {error && <Message error small>{error}</Message>}
                        <Checkbox style={{ marginBottom: '10px' }} disabled={saving} label="Get personalized feedback" checked={sendToJp} onChange={val => setSendToJp(val)} />
                        <Input type="text" disabled={saving} value={url} onChange={e => setUrl(e.target.value)} placeholder="Paste your Youtube URL here" />
                        <LoaderButton teal fluid loading={saving} disabled={saving} onClick={save}>Save</LoaderButton>
                    </FormWrap>
                    
                </ModalBody>
                <ModalFooter>
                    <h2>Why do this?</h2>
                    <ol>
                        <li>Medium-pressure tasks help cement new things in your muscle memory.</li>
                        <li>Professional eyes on your playing help you improve your groove, dynamic balance, technique, etc.</li>
                        <li>Personalized feedback keeps you on track in the course.</li>
                        <li>Regular posting enables you to track your progress.</li>
                        <li>This is how we get to know your playing.</li>
                    </ol>
                </ModalFooter>
            </ModalWrapper>
        </Modal>
    );
};

export default ProgressPostModal;
