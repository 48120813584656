import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = {};

export const editProfile = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.SAVE_PROFILE + '_PENDING': {
            return {
                ...state,
                processing: true,
                error: null
            };
        }
        case ActionTypes.SAVE_PROFILE + '_REJECTED': {
            const error = getDeepProp(action, 'payload', 'response', 'data', 'msg');

            return {
                ...state,
                error,
                processing: false
            };
        }
        case ActionTypes.SAVE_PROFILE + '_FULFILLED': {
            return {
                ...state,
                processing: false,
                success: true,
                error: null
            };
        }
        case ActionTypes.SET_PROFILE_EDITING_SECTION: {
            return {
                ...initialState,
                editingSection: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
