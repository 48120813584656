import { setImpactEmailSent } from "redux/actions";

export const impactMiddleware = store => next => action => {
    next(action);

    const state = store.getState();

    // if the user's email is known send it to trackdesk

    const email = state.user?.userData?.email || action?.payload?.tracking_email;

    // eslint-disable-next-line
    const isValidEmail = !!(email && (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)));

    if (isValidEmail && state.impact?.emailSet !== email) {
        store.dispatch(setImpactEmailSent(email));
    
        (async () => {
            let hashedEmail = '';
            // for some annoying reason Impact requires the email to be hashed with SHA-1
            if (window.TextEncoder && window.crypto?.subtle) {
                const buffer = await window.crypto.subtle.digest('SHA-1', new TextEncoder().encode(email));
                hashedEmail = Array.from(new Uint8Array(buffer)).map( x => x.toString(16).padStart(2,'0') ).join('');
            }
            window.ire('identify', {customerId: '', customerEmail: hashedEmail });
        })();
    }
};
