import React from 'react';
import styled from 'styled-components';

import Button from './Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const LoaderButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default ({ loading, children, ...props }) => {
    return (
        <LoaderButton {...props}>
            {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
            <div style={loading ? { marginLeft: '5px' } : {}}>{children}</div>
        </LoaderButton>
    );
};
