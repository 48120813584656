import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { setProfileEditingSection } from 'redux/actions';

import Input from 'components/_core/Input';
import Button from 'components/_core/Button';
import LoaderButton from 'components/_core/LoaderButton';
import Message from 'components/_core/Message';

const FormWrapper = styled.div`
    width: 100%;
`;

const FluidButtons = styled.div`
    display: flex;

    ${Button} {
        flex-basis: 50%;
    }
`;

const BasicForm = (
    {
        value,
        cancel,
        save,
        inputType,
        placeholder,
        name,
        FormInput,
        checkIfValidationError
    }
) => {
    FormInput = FormInput || Input;
    const dispatch = useDispatch();
    const saving = useSelector(state => state.user.editProfile.processing);
    const success = useSelector(state => state.user.editProfile.success);
    const error = useSelector(state => state.user.editProfile.error);

    const [newValue, setNewValue] = useState(value);
    const [validationError, setValidationError] = useState();

    useEffect(() => {
        if (success) {
            setTimeout(() => dispatch(setProfileEditingSection(null)), 1500);
        }
    }, [success, dispatch]);

    const doSave = () => {
        const e = checkIfValidationError(newValue);
        setValidationError(e);

        if (e) {
            return;
        }

        save({ [name]: newValue });
    };

    return (
        <FormWrapper>
            {success && <Message small success>Saved successfully</Message>}
            {error && <Message small error>{error}</Message>}
            {validationError && <Message small error>{validationError}</Message>}

            <FormInput 
                name={name}
                type={inputType || 'text'}
                placeholder={placeholder} 
                value={newValue}
                disabled={saving}
                onChange={e => setNewValue(e.target.value)} />

            <FluidButtons>
                <Button disabled={saving} onClick={cancel}>Cancel</Button>
                <LoaderButton loading={saving} disabled={saving} color="orange" onClick={doSave}>Save</LoaderButton>
            </FluidButtons>
        </FormWrapper>
    );
};

export default BasicForm;