import { ActionTypes } from 'utils/constants';

import { paramsFromQuerystring }  from 'utils/params-from-querystring';

const paramsFromQS = paramsFromQuerystring();

const defaultState = { listView: false, myLearningView: 'courses', sort: '-live_after', ...paramsFromQS };
const initialState = { ...defaultState, ...paramsFromQS };

export const filters = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.TOGGLE_LIST_VIEW: {
            return {
                ...state,
                listView: !state.listView
            }
        }
        case ActionTypes.SET_MY_LEARNING_VIEW: {
            return {
                ...state,
                myLearningView: action.payload
            }
        }
        case ActionTypes.SET_FILTER: {
            return {
                ...state,
                ...action.payload
            };
        }
        case ActionTypes.RESET_FILTERS: {
            return defaultState;
        }
        case ActionTypes.TOGGLE_COURSE_SEARCH: {
            return {
                ...state,
                searchOpen: !state.searchOpen
            };
        }
        case ActionTypes.LOGOUT:
        case ActionTypes.VALIDATE_JWT + '_REJECTED': {
            return {
                ...state,
                myLearningView: 'courses'
            };
        }
        default: {
            return state;
        }
    }
};