import styled from 'styled-components';

export const ListView = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const ListViewItem = styled.li`
    background-color: ${props => props.theme.colors.midGray};
    border-bottom: 1px solid ${props => props.theme.backgroundColor};
    padding: 15px 30px;
`;