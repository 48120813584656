import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';
import { getCookie } from 'utils/get-cookie';

const initialState = {};

// set initial jwt with cookie
const token = getCookie('token');
if (token) {
    initialState.jwt = token;
    initialState.jwtValidated = false;
}

export const auth = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.SUBSCRIBE + '_FULFILLED':
        case ActionTypes.VALIDATE_JWT + '_FULFILLED':
        case ActionTypes.CREATE_ACCOUNT + '_FULFILLED':
        case ActionTypes.RESET_PASSWORD + '_FULFILLED':
        case ActionTypes.SAVE_PROFILE + '_FULFILLED':
        case ActionTypes.LOGIN + '_FULFILLED': {
            const jwt = getDeepProp(action, 'payload', 'data', 'token');
            return {
                ...state,
                jwt,
                loggedIn: true,
                jwtValidated: true,
                validating: false
            };
        }
        case ActionTypes.VALIDATE_JWT + '_PENDING': {
            return {
                ...state,
                validating: true
            };
        }
        case ActionTypes.LOGOUT:
        case ActionTypes.VALIDATE_JWT + '_REJECTED': {
            return {};
        }
        default: {
            return state;
        }
    }
};
