import React from 'react';
import styled from 'styled-components';

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { GridItem, ThreeGrid } from 'components/_core/grids';
import YouTubeVideoThumb from './VideoThumb';
import { TestiGrid as TGrid, Testimonial } from './TestimonialGrid';

const Wrapper = styled(W)`
    background-color: #fff;
`;

const Container = styled(C)`
    padding: 30px 0;
`;

const TextHeading = styled.div`
    margin: 0 auto 30px;
    color: #000;
    text-align: center;

    h2 {
        font-size: 30px;
        margin: 0;
        font-weight: 200;

        strong {
            font-style: italic;
            font-weight: 700;
        }
    }
`;

const TestiGrid = styled(TGrid)`
    margin: 40px 0;
`;

const ImprovIsSkill = () => {
    return (
        <Wrapper>
            <Container>
                <TextHeading>
                    <h2>
                        Most people think improvisation<br/>
                        <strong>can't be taught...</strong>
                    </h2>
                </TextHeading>

                <TestiGrid>
                    <Testimonial>
                        <p>“People talk a lot about soloing as storytelling, but I feel like <span className="highlight">I actually get what that means now</span>.”</p>
                        <p className="teal">&mdash; Will R.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“<span className="highlight">The freedom I've aquired</span> on the drum set in the past 16 weeks is insane.”</p>
                        <p className="teal">&mdash; Brandon G.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“<span className="highlight">I could never have improvised</span> something for this long before I started the course.”</p>
                        <p className="teal">&mdash; David V.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“Took a solo last night. First time I've ever take one without any kind of plan, and <span className="highlight">couldn't have done it without JP's courses</span>.”</p>
                        <p className="teal">&mdash; Dan C.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“The website took my ability to improvise and <span className="highlight">increased it tenfold</span>.”</p>
                        <p className="teal">&mdash; Nicholas H.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“I never thought I'd learn to play a drum solo at 51. I still have a lot of work to do but your methods <span className="highlight">opened up pandora's box of ideas</span>.”</p>
                        <p className="teal">&mdash; Rayray M.</p>
                    </Testimonial>
                </TestiGrid>

                <TextHeading>
                    <h2>
                        ...but <strong>improvisation is a skill</strong>, not a gift.<br/>
                        Here's <strong>proof</strong> from students just like you.
                    </h2>
                </TextHeading>

                <ThreeGrid>
                    <GridItem><YouTubeVideoThumb videoId="eeJ428zXQIQ" /></GridItem>
                    <GridItem><YouTubeVideoThumb videoId="YGKUC2UT8q0" /></GridItem>
                    <GridItem><YouTubeVideoThumb videoId="BP8Ajv8M9i8" /></GridItem>
                </ThreeGrid>

                <TestiGrid>
                    <Testimonial>
                        <p>“For the first time in my life, I can say I play the drums with <span className="highlight">zero imposter syndrome</span>.”</p>
                        <p className="teal">&mdash; Hoang K.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“It’s the first time in years that I’m sitting on the set and <span className="highlight">I feel confident and free</span>.”</p>
                        <p className="teal">&mdash; Stef G.</p>
                    </Testimonial>

                    <Testimonial>
                        <p>“I can’t believe how much <span className="highlight">more expressive</span> I am as a drummer now.”</p>
                        <p className="teal">&mdash; Boh K.</p>
                    </Testimonial>
                </TestiGrid>
            </Container>
        </Wrapper>
    )
};

export default ImprovIsSkill;
