
import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

export const downloadVideo = store => next => action => {
    if (action.type === ActionTypes.DOWNLOAD_VIDEO + '_FULFILLED') {
        const link = getDeepProp(action, 'payload', 'data', 'download_link');
        if (link) {
            window.open(link);
        }
    }

    next(action);
};
