import React from 'react';
import { SubscribeBtn } from 'components/buttons';
import SaleBar from './SaleBar';
import { TRIAL_DAYS } from 'utils/constants';

const FreeTrailBar = () => (
    <SaleBar>
        <SubscribeBtn large orange pill bold>{TRIAL_DAYS > 0 ? 'Try 7 Days Free' : 'Join Now'}</SubscribeBtn>
        <p>100% Satisfaction Guarantee</p>
    </SaleBar>
);

export default FreeTrailBar;
