import { createSelector } from 'reselect';

const videoId = state => state.currentCourse.currentVideo.id;
const favoriteVideos = state => state.currentCourse.favoriteVideos.ids;

export const isCurrentVideoFavorite = createSelector(
    [videoId, favoriteVideos],
    (id, favoriteIds) => {
        return favoriteIds.has(id);
    }
);
