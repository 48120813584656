import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'components/_core/Checkbox';

import { toggleVideoComplete } from 'redux/actions';
import { isVideoComplete } from 'redux/selectors/is-video-complete';
import { isVideoCompletenessProcessing } from 'redux/selectors/is-video-completeness-processing';

const VideoCompleteCheckbox = ({ videoId }) => {
    const dispatch = useDispatch();
    const complete = useSelector(state => isVideoComplete(state, { videoId }));
    const processing = useSelector(state => isVideoCompletenessProcessing(state, { videoId }));

    return (
        <Checkbox 
            onChange={val => dispatch(toggleVideoComplete(videoId, val))} 
            checked={complete} 
            processing={processing} 
        />
    );
};

export default VideoCompleteCheckbox;
