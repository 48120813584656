export const paramsFromQuerystring = () => {
    const querystring = window.location.search;
    let paramsFromQS = {};
    if (querystring) {
        const params = querystring.substr(1).split('&');

        paramsFromQS = params.reduce((a, p) => {
            p = p.split('=');
            a[p[0]] = decodeURIComponent(p[1]);
            return a;
        }, {});
    }

    return paramsFromQS;
};
