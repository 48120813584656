import { createSelector } from 'reselect';

const filters = state => state.courseList.filters;
const courses = state => state.courseList.courses.courses;

export const coursesFiltered = ({ type, useCategoryFilter, useLevelFilter }) => createSelector(
    [filters, courses],
    (filters, courses) => {
        let nextCourses = [...courses];

        if (type) {
            nextCourses = nextCourses.filter(c => c.type === type);
        }
        
        if (useLevelFilter && filters.level && filters.level !== 'all_levels') {
            nextCourses = nextCourses.filter(c => {
                return c.level === filters.level || c.level === 'all_levels';
            });
        }

        if (useCategoryFilter && filters.category) {
            nextCourses = nextCourses.filter(c => {
                return c.categories?.includes(filters.category);
            });
        }

        // sort
        const desc = filters.sort.substr(0,1) === '-';
        const sortKey = filters.sort.replace('-', '');

        nextCourses = nextCourses.sort((a, b) => {
            if (desc) {
                if (b[sortKey] < a[sortKey]) {
                    return -1;
                }

                if (b[sortKey] > a[sortKey]) {
                    return 1;
                }

                return 0;
            }

            if (a[sortKey] < b[sortKey]) {
                return -1;
            }

            if (a[sortKey] > b[sortKey]) {
                return 1;
            }

            return 0;
        });

        return nextCourses;
    }
);
