import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Message from 'components/_core/Message';
import LoaderButton from 'components/_core/LoaderButton';

import { cancelSubscription } from 'redux/actions';
import CancelModal from './CancelModal';

const CancelWrap = styled.div`
    margin-top: 30px;
`;

const CancelDisclosure = styled.p`
    font-size: 12px;
    color: #aaa;
`;

const CancelSubscription = () => {
    const dispatch = useDispatch();
    const canceling = useSelector(state => state.user.cancelSubscription.canceling);
    const error = useSelector(state => state.user.cancelSubscription.error);
    const [modalOpen, setModalOpen] = useState(false);

    let nextBillDate = useSelector(state => state.user.subscription.state.next_bill_date);
    nextBillDate = new Date(nextBillDate * 1000);

    return (
        <>
            <CancelWrap>
                {error && <Message error small>An error occured. Please try again.</Message>}
                <LoaderButton loading={canceling} onClick={() => setModalOpen(true)}>Cancel Subscription</LoaderButton>
                <CancelDisclosure>Your subscription will remain active until {nextBillDate.getMonth()+1}/{nextBillDate.getDate()}/{nextBillDate.getFullYear()} after cancelation</CancelDisclosure>
            </CancelWrap>
            <CancelModal 
                open={modalOpen} 
                close={() => setModalOpen(false)} 
                confirm={() => {
                    setModalOpen(false); 
                    dispatch(cancelSubscription());
                }}
            />
        </>
    );
};

export default CancelSubscription;
