import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const fetchUserDownloadData = () => (
    {
        type: ActionTypes.FETCH_USER_DOWNLOAD_DATA,
        payload: axios.get(`${getEnvVar('API_BASE')}/user-download-data`)
    }
);

export const downloadVideo = (videoId, quality) => (
    {
        type: ActionTypes.DOWNLOAD_VIDEO,
        payload: axios.post(`${getEnvVar('API_BASE')}/download-video`, { videoId, quality })
    }
);
