import { combineReducers } from 'redux';

import { login } from './login';
import { resetPassword } from './reset-password';
import { confirmEmail } from './confirm-email';
import { join } from './join';
import { createAccount } from './create-account';
import { auth } from './auth';
import { userData } from './user-data';
import { userCurrentCourse } from './user-current-course';
import { editProfile } from './edit-profile';
import { subscription } from './subscription';
import { cancelSubscription } from './cancel-subscription';
import { upgradeSubscription } from './upgrade-subscription';
import { resumeSubscription } from './resume-subscription';
import { referAFriend } from './refer-a-frield';
import { myLearning } from './my-learning';
import { myFavorites } from './my-favorites';
import { downloads } from './downloads';
import { myProgressPosts } from './my-progress-posts';

export default combineReducers({
    login,
    resetPassword,
    confirmEmail,
    join,
    createAccount,
    auth,
    userData,
    currentCourse: userCurrentCourse,
    editProfile,
    subscription,
    cancelSubscription,
    upgradeSubscription,
    resumeSubscription,
    referAFriend,
    myLearning,
    myFavorites,
    downloads,
    myProgressPosts
});
