import React from 'react';
import styled from 'styled-components';

import WithScrollPosition from '../hoc/scroll-position-provider';

import Container from './Container';
import FullLogo from './FullLogo';
import InlineNav from './InlineNav';

const HeaderWrapper = styled.div.attrs(props => {
    props.backgroundColor = 'rgba(0,0,0,0)';

    if (props.hideLogoUntilScroll) {
        props.logoOpacity = 0;
    }

    props.maxWidth = props.maxWidth || props.theme.containerMaxWidth;
    
    if (props.scrollPosition > 50 || props.dontFadeBarIn) {
        props.backgroundColor = 'rgba(0,0,0,0.8)';
        props.logoOpacity = 1;
    }
})`
    position: fixed;
    top: ${props => props.hasActiveSaleBanner ? '60px' : '0'};
    left: 0;
    width: 100%;
    z-index: 99999;

    transition: background-color 0.3s;
    background-color: ${props => props.backgroundColor};
    padding: 0 ${props => props.theme.horizontalPadding};

    & > ${Container} {
        display: flex;
        justify-content: space-between;
        align-items: center;

        max-width: ${props => props.maxWidth};

        padding: 10px 0;

        & > .full-logo {
            transition: opacity 0.3s;
            opacity: ${props => props.logoOpacity};
        }

        & > ${InlineNav} {
            a, button {
                color: ${props => props.theme.textColor};
                text-decoration: none;
                font-weight: 100;
                text-transform: uppercase;
                font-size: 16px;
            }
            button {
                margin-bottom: -2px;
            }

            li {
                margin-right: 45px;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .hide-tablet {
            display: none !important;
        }
    }

    @media screen and (max-width: 530px) {
        .logo-circle {
            width: 50px;
        }
        .logo-text {
            font-size: 16px;
        }

        & > ${Container} {
            & > ${InlineNav} {
                ul {
                    padding: 0;

                    li {
                        margin-right: 25px;
                    }
                }
            }
        }

        .hide-mobile {
            display: none !important;
        }
    }

    @media screen and (max-width: 370px) {
        .logo-text {
            font-size: 12px;
        }
    }
`;

const PageTitle = styled.div``;

const Header = props => (
    <HeaderWrapper {...props}>
        <Container>
            <FullLogo size="small" />
            {props.title && <PageTitle>{props.title}</PageTitle>}
            <InlineNav>
                <ul>
                    {props.navItems.map((Item, i) => <Item key={`nav-item-${i}`} />)}
                </ul>
            </InlineNav>
        </Container>
    </HeaderWrapper>
);

export default WithScrollPosition(Header);