import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Thumb, Chin, Top } from './Thumb';
import { CourseMetaData, CourseLevel, CourseLength } from './CourseMetaData';
import { LEVEL_MAPPING } from 'utils/constants';
import { getEnvVar } from 'utils/get-env-var';
import { openCourseModal } from 'redux/actions';
import { truncateText } from 'utils/truncate-text';
import Button from './Button';

const CourseTop = styled(Top)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(102, 102, 102, 0.15);
    }
`;

const CourseCompleteCheck = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0,0,0,0.3);
    border-radius: 5px;
    z-index: 3;
    width: 28px;
    height: 28px;

    &::after {
        content: '';
        display: block;
        border: 1px solid #fff;
        border-top: none;
        border-right: none;
        width: 14px;
        height: 7px;

        transform: rotate(-45deg);
        
        position: absolute;
        top: 8px;
        left: 6px;
    }
`;

const CourseTitle = styled.div`
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    margin: 0;

    letter-spacing: -0.02em
    line-height: 1.3em;
    z-index: 3;

    font-family: ${props => props.theme.alternativeFont};

    position: absolute;
    bottom: 0
    left: 0;
    right: 0;
    padding: 30px 15px 15px 15px;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.5+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
`;

const CourseChin = styled(Chin)`
    flex-grow: 1;
    
    p {
        font-size: 13px
        margin: 0;
        color: ${props => props.theme.colors.lightGrayText};
        line-height: normal;
    }

    ${Button} {
        font-weight: 400;
    }

    ${props => {
        if (props.light) {
            return `
                background-color: #eee;
            `;
        }
    }}
`;

const CourseThumb = props => {
    const dispatch = useDispatch();
    
    return (
        <Thumb onClick={() => dispatch(openCourseModal(props.slug, props.name))}>
            <CourseTop photo={`${getEnvVar('UPLOAD_PATH')}/medium_${props.photo}`}>
                <CourseTitle>{props.name}</CourseTitle>
                {props.complete && <CourseCompleteCheck />}
            </CourseTop>
            <CourseChin light={props.light}>
                <CourseMetaData lightBackground={props.light}>
                    <CourseLevel lightBackground={props.light} level={props.level}>{LEVEL_MAPPING[props.level]}</CourseLevel>
                    <CourseLength>{props.videoCount} Videos</CourseLength>
                </CourseMetaData>
                {props.description && !props.hideDescription && <p dangerouslySetInnerHTML={{__html: truncateText(props.description, 160)}} />}
                {props.showButton && <Button teal fluid>Preview Course</Button>}
            </CourseChin>
        </Thumb>
    );
};

export default CourseThumb;
