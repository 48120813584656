import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import Home from './Home';
import { setJoinPlan, toggleJoinModal } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const JoinTrigger = ({
    match: { params: { plan } },
    ...props
}) => {
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => state.user.auth.loggedIn);

    const nextScreen = useMemo(() => loggedIn ? 'subscribe' : 'create-account', [loggedIn])
    
    useEffect(() => {
        dispatch(toggleJoinModal());
    }, [dispatch]);

    useEffect(() => {
        if (!plan) return;
        
        dispatch(setJoinPlan({ plan, nextScreen }));
    }, [dispatch, plan, nextScreen]);

    return <Home {...props} />;
};

export default withRouter(JoinTrigger);
