import React from 'react';

const MobileView = () => {
    return (
        <>
            <p><em>Use a larger screen to view the interactive curriculum.</em></p>
            <a href="/img/curriculum-view.jpg" target="_blank" rel="noopener noreferrer"><img src="/img/curriculum-view.jpg" width="300" alt="The JP Bouvet Curriculum" /></a>
        </>
    );
};

export default MobileView;
