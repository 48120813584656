import React from 'react';
import styled from "styled-components";
import C from "components/_core/Container";
import W from "components/_core/Wrapper";
import Button from 'components/_core/Button';
import { useSelector } from 'react-redux';

const Wrapper = styled(W)`
    background-color: #eee;

    & + * {
        padding-top: 25px !important;
    }
`;

const Container = styled(C)`
    padding: 25px 0;
    text-align: center;

    p {
        margin: 10px 0 0 0;
        font-size: 14px;
        color: #666;
    }

    ${Button} {
        text-transform: none;
        border: 2px solid #fff;
        box-shadow: 0px 6px 0 0 #00000021;
    }
`;

const SaleBar = ({ children, showForSubscribers }) => {
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    if (isSubscriber && !showForSubscribers) {
        return null;
    }

    return (
        <Wrapper>
            <Container>
                {children}
            </Container>
        </Wrapper>
    )
};

export default SaleBar;
