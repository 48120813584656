import React from 'react';
import styled from 'styled-components';
import { ThreeGrid, GridItem } from 'components/_core/grids';
import CourseThumb from 'components/_core/CourseThumb';

const SectionHeading = styled.h2`
    margin: 20px 0 10px;
    color: #ddd;
`;

const NextSteps = styled.div`
    margin: 30px 0 80px;

    padding-top: 30px;

    border-top: 1px solid #333;

    h2 {
        font-size: 20px
        margin: 0;
        margin-bottom: 30px;
        font-weight: 600;
    }
`;

export default props => {
    if (!props.course.nextSteps || !props.course.nextSteps.length) {
        return null;
    }

    return (
        <NextSteps>
            <SectionHeading>Recommended Next Steps</SectionHeading>
            <ThreeGrid twoBreak="1300px" stackPoint="650px">
                {(props.course.nextSteps || []).map(course => <GridItem key={`next-${course.id}`}><CourseThumb {...course} /></GridItem>)}
            </ThreeGrid>
        </NextSteps>
    );
};
