import { ActionTypes } from 'utils/constants';

const initialState = { activeTab: 0 };

export const courseDetails = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_COURSE_DETAILS_TAB: {
            return {
                ...state,
                activeTab: action.payload
            };
        }
        case ActionTypes.FETCH_COURSE + '_PENDING': {
            return {
                ...initialState,
                fetching: true
            };
        }
        case ActionTypes.FETCH_COURSE + '_FULFILLED': {
            const activeTab = action.payload.currentVideo ? 1 : 0;
            
            return {
                ...state,
                fetching: false,
                fetched: true,
                course: action.payload.data.course,
                activeTab
            };
        }
        case ActionTypes.FETCH_COURSE + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: true
            };
        }
        case ActionTypes.COPY_MODAL_COURSE: {
            return {
                ...initialState,
                fetching: false,
                fetched: true,
                course: action.payload
            };
        }
        case ActionTypes.SET_CURRENT_VIDEO: {
            if (action.payload.isPreview) {
                return state;
            }
            
            return {
                ...state,
                activeTab: 1
            };
        }
        case ActionTypes.ADD_PROGRESS_POST: {
            const contents = [...state.course.contents].map(chapter => {
                const videos = [...chapter.videos].map(video => {
                    if (video.id === action.payload.videoId) {
                        video.progress_post = action.payload.progressPost;
                    }

                    return video;
                });

                return {
                    ...chapter,
                    videos
                };
            });

            const course = {
                ...state.course,
                contents
            };

            return {
                ...state,
                course,
                progress_post: action.payload.progressPost
            };
        }
        default: {
            return state;
        }
    }
};