import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Message from 'components/_core/Message';
import { getEnvVar } from 'utils/get-env-var';


import MailchimpSubscribe from "react-mailchimp-subscribe"
import Button from 'components/_core/Button';

const url = "https://jpbouvetmethod.us6.list-manage.com/subscribe/post?u=0874dfdae8aad219583aafc58&amp;id=72a1b7a97b&amp;f_id=00cb2ce3f0";

const Wrap = styled.div`
    margin: 20px auto;
    max-width: 325px;
`;

const FormWrapper = styled.div`
    border-radius: 7px;
    border: 1px solid #bbb;
    padding: 10px 30px 10px 15px;
    position: relative;
`;

const EmailInput = styled.input`
    appearance: none;
    background: none;
    border: none;
    font-size: 18px;
    outline: none;
    font-family: 'Open Sans',sans-serif;
    width: 100%;
`;

const CTA = styled(Button)`
    text-transform: none;

    padding: 13px 30px;
    width: 100%;

    max-width: 350px;
    
    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;

    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;

    div {
        font-style: italic;
        font-size: 15px;
    }
`;
const EmailForm = (props) => {
    const [email, setEmail] = useState('');

    const submitForm = (e, subscribe) => {
        e.preventDefault();
        subscribe({ EMAIL: email, SITESUBTYP: 'none', tags: '3403257', b_0874dfdae8aad219583aafc58_72a1b7a97b: '' });
    };

    return <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => {
            const processing = status === 'sending';

            if (status === 'success') {
                return <Redirect to={`${getEnvVar('ROOT_ROUTE')}/email/thank-you`} />;
            }

            if (message === '0 - Please enter a value' || message === '0 - An email address must contain a single @') {
                message = 'Please enter a valid email address';
            }

            return (
                <div className="footer-mc">
                    {status === "error" && <Message small noIcon><span dangerouslySetInnerHTML={{ __html: message }} /></Message>}
                    <form onSubmit={e => submitForm(e, subscribe)}>
                        <Wrap>
                            <FormWrapper>
                                <EmailInput type="email" value={email} disabled={processing} onChange={e => setEmail(e.target.value)} placeholder="Email Address" />
                            </FormWrapper>
                            <CTA teal pill disabled={processing} type="submit">
                                Begin Course
                            </CTA>
                        </Wrap>
                    </form>
                </div>
            );
        }}
    />;
};

export default EmailForm;
