import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = {};

export const createAccount = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.TOGGLE_JOIN_MODAL: {
            return {...initialState};
        }
        case ActionTypes.CREATE_ACCOUNT + '_PENDING': {
            return {
                ...state,
                processing: true,
                error: null
            };
        }
        case ActionTypes.CREATE_ACCOUNT + '_REJECTED': {
            const error = getDeepProp(action, 'payload', 'response', 'data', 'msg') || 'An unexpected error has occured. Please try again.';

            return {
                ...state,
                error,
                processing: false
            };
        }
        case ActionTypes.CREATE_ACCOUNT + '_FULFILLED': {
            return {
                ...state,
                processing: false,
                success: true,
                error: null
            };
        }
        default: {
            return state;
        }
    }
};
