import React from "react";
import styled from "styled-components";

import BookMockImg from "./book-mock.png";
import starsImg from "./stars.png";
import { ButtonA } from "components/_core/Button";

const Wrap = styled.div`
    border-top: #ff7a57 10px solid;
    padding: 20px 0;
    background-color: #00676e;
`;

const Container = styled.div`
    max-width: 900px;
    margin: 0 auto;
    text-align: center;

    padding: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    height: 100%;
`;

const BookColumn = styled(Column)`
    flex-basis: 60%;
    flex-shrink: 0;
    margin-right: -100px;
`;


const BookMock = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

    @media screen and (max-width: 700px) {
        max-width: 400px;
    }
`;

const DetailsColumn = styled(Column)`
    text-align: center;

`;

const Stars = styled.img`
    width: 130px;
    margin: 0 auto;
`;

const Testimonial = styled.div`
    color: #fff;
    font-size: 1.2rem;
    margin-top: 5px;

    p {
        margin: 0;
    }
`;

export const BuyButton = styled(ButtonA)`
    text-transform: none;

    padding: 20px 30px;
    width: 100%;

    max-width: 400px;
    
    border: 3px solid #fff;
    background-color: #ffd712;
    box-shadow: 0px 6px 0 0 #00000021;
    color: #000;

    margin: 30px 0;
    font-size: 22px;

    &:hover {
        background-color: #ffd712 !important;
        opacity: 0.9;
    }
`;

const SitePlug = styled.div`
    color: #fff;

    max-width: 350px;
    margin: 0 auto;

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1.1rem;
    }

    a {
        color: #fff;
    }
`;

const Plug = styled.p`
    display: block;

    font-size: 1rem;
    background-color: #ff7a57;
    padding: 5px;
    font-weight: 700;
`;

const BookTop = () => {
    return (
        <Wrap>
            <Container>
                <BookColumn>
                    <BookMock src={BookMockImg} />
                </BookColumn>
                <DetailsColumn>
                    <Stars src={starsImg} alt="5 Stars" />
                    <Testimonial>
                        <p>"The book took my ability to improvise and increased it tenfold. Probably a little longer."</p>
                        <p>&mdash; <em>Famous Drummer (Frank Zappa)</em></p>
                    </Testimonial>

                    <BuyButton href="#" target="_blank" rel="noopener noreferrer" className="btn" bold pill large>Buy on Amazon</BuyButton>

                    <SitePlug>
                        <h2>Want to learn to improvise with step-by-step courses?</h2>
                        <Plug>Get $100 off annual subscriptions</Plug>
                        <p>to JP's online drum school (see below) When you emall your receipt to <a href="mailto:jp@jpbouvetmethod.com">jp@jpbouvetmethod.com</a></p>
                    </SitePlug>

                </DetailsColumn>
            </Container>
        </Wrap>
    )
};

export default BookTop;
