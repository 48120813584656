import { ActionTypes } from "utils/constants";

export const setEmailForImpact = email => (
    {
        type: ActionTypes.SET_EMAIL_FOR_IMPACT,
        payload: { tracking_email: email }
    }
);

export const setImpactEmailSent = email => (
    {
        type: ActionTypes.IMPACT_EMAIL_SENT,
        payload: email
    }
);

export const setImpactClickId = clickId => (
    {
        type: ActionTypes.SET_IMPACT_CLICK_ID,
        payload: clickId
    }
);
