import React from "react";
import styled from "styled-components";
import JPsmiling from "img/landingpage/jp-smiling.png";
import { GridItem, ThreeGrid } from "components/_core/grids";
import YouTubeVideoThumb from "components/Home/VideoThumb";
import { TestiGrid, Testimonial } from "components/Home/TestimonialGrid";
import starsImg from 'img/landingpage/stars.png';
import arrowLeft from 'img/landingpage/puddin-arrow-left.png';
import arrowRight from 'img/landingpage/puddin-arrow-right.png';
import arrowMiddle from 'img/landingpage/puddin-arrow-middle.png';

const YellowContainer = styled.div`
    background-color: #f7f93d;
    padding: 30px 15px 0 30px;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        background-color: #fff;
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        height: 300px;
        rotate: -3.5deg;
        transform-origin: top left;

    }

    @media screen and (max-width: 700px) {
        padding: 0;
    }
`;

const YellowWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 700px) {
        display: block;
    }
`;

const JPPic = styled.div`
    flex-basis: 40%;
    padding-right: 30px;

    overflow: hidden;

    img {
        width: 100%;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        height: auto;
        flex-basis: unset;
        padding-right: 0;
        padding-top: 100%;
        position: relative;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            object-position: top;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

`;

const Content = styled.div`
    flex-basis: 60%;
    padding: 30px 0 130px 0;

    max-width: 600px;

    h2 {
        font-size: 2.5rem;
        margin: 0;
    }

    p {
        font-size: 20px;
    }

    @media screen and (max-width: 950px) {
        h2 {
            font-size: 2rem;
        }
    
        p {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 700px) {
        padding: 30px 15px 60px;
        margin: 0 auto;
    }
`;

const WhiteContainer = styled.div`
    background-color: #fff;
    position: relative;
    min-height: 300px;
    padding: 0 15px;

    h2 {
        margin: 0;
    }
`;

const PuddingHeadiner = styled.h2`
    color: #fff;
    font-size: 5rem;
    font-weight: 900;
    text-transform: uppercase;
    rotate: -3.5deg;
    display: inline-block;
    padding: 10px 30px;
    white-space: nowrap;

    position: absolute;
    top: -130px;
    left: 50%;
    transform: translateX(-50%);

    z-index: 2;

    &::before {
        content: '';
        display: block;
        background-color: ${props => props.theme.colors.teal};
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        transform: skew(-1.5deg, -1.5deg) rotate(2deg);
    }

    @media screen and (max-width: 700px) {
        top: -75px;
        font-size: 3rem;
    }

    @media screen and (max-width: 450px) {
        top: -50px;
        font-size: 2.3rem;
    }

`;

const Arrows = styled.div`
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;

    @media screen and (max-width: 700px) {
        justify-content: center;
        img {
            display: none;
        }

        img:nth-child(2) {
            display: block;
        }
    }
`;

const Arrow = styled.img`

    width: 20px;
`;

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
`;

const VideoGrid = styled(ThreeGrid)`
    @media screen and (max-width: 700px) {
        div:nth-child(3) {
            display: none;
        }
    }
`;

const TestiGridPuddin = styled(TestiGrid)`
    @media screen and (max-width: 700px) {
        div {
            display: none;
        }
        div:nth-child(1) {
            display: block;
            flex-basis: 100%;
        }
    }
`;


const PuddinPops = ({ Button }) => {
    return (
        <>
            <YellowContainer>
                <YellowWrapper>
                    <JPPic>
                        <img src={JPsmiling} alt="JP smiling" />
                    </JPPic>
                    <Content>
                        <h2>My job is to get you unstuck and IMPROVISING FREELY</h2>
                        <p>Would you love to improvise grooves, fills, and drum solos effortlessly while developing your own unique voice on the drums?</p>

                        <p>But you find yourself playing the same licks and patterns over and over, wondering how to take your drumming to the next level?</p>

                        <p>Don’t take it from me.<br/>
                        <strong><em>The proof is in the pudding:</em></strong></p>
                    </Content>
                </YellowWrapper>
            </YellowContainer>
            <WhiteContainer>
                <PuddingHeadiner>
                    The Pudding!
                </PuddingHeadiner>

                <Arrows>
                    <Arrow src={arrowLeft} />
                    <Arrow src={arrowMiddle} />
                    <Arrow src={arrowRight} />
                </Arrows>

                <Wrapper>
                    <VideoGrid>
                        <GridItem><YouTubeVideoThumb videoId="eeJ428zXQIQ" /></GridItem>
                        <GridItem><YouTubeVideoThumb videoId="YGKUC2UT8q0" /></GridItem>
                        <GridItem><YouTubeVideoThumb videoId="BP8Ajv8M9i8" /></GridItem>
                    </VideoGrid>

                    <TestiGridPuddin>
                        <Testimonial>
                            <img src={starsImg} width="110" alt="Five starts" />
                            <p>“For the first time in my life, I can say I play the drums with <span className="highlight">zero imposter syndrome</span>.”</p>
                            <p className="teal">&mdash; Hoang K.</p>
                        </Testimonial>
                        <Testimonial>
                            <img src={starsImg} width="110" alt="Five starts" />
                            <p>“It’s the first time in years that I’m sitting on the set and <span className="highlight">I feel confident and free</span>.”</p>
                            <p className="teal">&mdash; Stef G.</p>
                        </Testimonial>

                        <Testimonial>
                            <img src={starsImg} width="110" alt="Five starts" />
                            <p>“I can’t believe how much <span className="highlight">more expressive</span> I am as a drummer now.”</p>
                            <p className="teal">&mdash; Boh K.</p>
                        </Testimonial>
                    </TestiGridPuddin>

                    <div style={{ textAlign: 'center' }}><Button /></div>
                </Wrapper>
            </WhiteContainer>
        </>
    );
};

export default PuddinPops;
