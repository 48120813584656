import { ActionTypes } from 'utils/constants';

export const courses = (state = {}, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_COURSES + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_COURSES + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                error: true
            };
        }
        case ActionTypes.FETCH_COURSES + '_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                courses: action.payload?.data?.courses || []
            };
        }
        default: {
            return state;
        }
    }
};
