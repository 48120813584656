import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const validateReferralCode = code => (
    {
        type: ActionTypes.VALIDATE_REFERRAL_CODE,
        payload: axios.get(`${getEnvVar('API_BASE')}/validate-referral-code?code=${code}`)
    }
);
