import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Message from 'components/_core/Message';
import LoaderButton from 'components/_core/LoaderButton';

import { resumeSubscription } from 'redux/actions';

const ResumeSubscription = () => {
    const dispatch = useDispatch();
    const processing = useSelector(state => state.user.resumeSubscription.processing);
    const error = useSelector(state => state.user.resumeSubscription.error);
    const subState = useSelector(state => state.user.subscription.state) || {};

    const go = useCallback(() => {
        if (window.confirm(`Nice choice! Your subscription will reume as if you never left. Continue?`)) {
            dispatch(resumeSubscription(subState.subscription_id));
        }
    }, [dispatch, subState.subscription_id]);

    if (error) {
        return <Message error>Error resuming. Please contact support.</Message>
    }

    return (
        <>
            <LoaderButton disabled={processing} loading={processing} style={{ marginTop: '20px' }} orange onClick={go}>Resume Subscription</LoaderButton>
        </>
    );
};

export default ResumeSubscription;
