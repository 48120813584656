import React from 'react';
import styled from 'styled-components';

import CourseFetcher from './CoursesFetcher';
import CourseResults from './CourseResults';
import CategoryFilter from './CategoryFilter';
import ViewSwitch from './ViewSwitch';
import SearchTrigger from './SearchTrigger';
import LevelFilter from './LevelFilter';
import Sort from './Sort';

// import CurriculumButton from './CurriculumButton';

const FilterRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px; 
    position: relative;

    @media screen and (max-width: 750px) {
        flex-direction: column-reverse;
    }
`;

const Filters = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;

    & > * {
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 750px) {
        flex-direction: column;

        > * {
            margin: 2px 0;
        }
    }
`;

const ViewSwitchWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 750px) {
        margin-bottom: 3px;
        justify-content: space-between;
    }
`;

const Courses = ({ type, showLevels, showCategories, showSearch }) => {
    return (
        <>
            <FilterRow>
                <Filters>
                    {showLevels && <LevelFilter />}
                    {showCategories && <CategoryFilter />}
                    {showSearch && <SearchTrigger />}
                </Filters>
                <ViewSwitchWrapper>
                    {/* <CurriculumButton /> */}
                    <Sort />
                    <ViewSwitch />
                </ViewSwitchWrapper>
            </FilterRow>
            <CourseFetcher>
                <CourseResults type={type} showCategories={showCategories} showLevels={showLevels} />
            </CourseFetcher>
        </>
    );
};

export default Courses;
