import styled from 'styled-components';

const Container = styled.div`
    color: ${props => props.theme.textColor};
    width: 100%;
    max-width: ${props => props.theme.containerMaxWidth};
    margin: 0 auto;
`;

export default Container;
