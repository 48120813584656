import React from 'react';
import styled from 'styled-components';

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { GridItem, TwoGrid } from 'components/_core/grids';
import PhoneImg from 'img/homepage/facebook-community.png';
import { TRIAL_DAYS } from 'utils/constants';
import { SubscribeBtn as s } from 'components/buttons';

const Wrapper = styled(W)`
    position: relative;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 50%);

    @media screen and (max-width: 850px) {
        background: #fff;
    }
`;

const SubscribeBtn = styled(s)`
    text-transform: none;

    text-transform: none;
    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;
`;


const Container = styled(C)``;

const Text = styled(GridItem)`
    padding: 80px 150px 80px 0 !important;

    h2 {
        margin-bottom: 20px;
        color: #000;

        .main {
            font-size: 60px;
            line-height: 1.1em;
            text-shadow: 0 5px #00000020;
            margin-bottom: 10px;
        }

        .teal {
            color: ${props => props.theme.colors.teal};
        }

        @media screen and (max-width: 600px) {
            .main {
                font-size: 40px;
            }
        }
    }

    .testi {
        margin: 30px 0;
        

        p {
            color: #666;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5em;
            font-style: italic;

            &.byline {
                font-style: normal;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 80px 50px 80px 0 !important;
    }

    @media screen and (max-width: 400px) {
        padding: 30px 0 !important;
    }
`;

const PhoneGridItem = styled(GridItem)`
    padding: 120px 0 0 120px !important;

    @media screen and (max-width: 1100px) {
        padding: 120px 0 0 50px !important;
    }

    @media screen and (max-width: 400px) {
        padding: 60px 0 0 0 !important;
    }
`;

const TextGridItem = styled(TwoGrid)`
    margin: 0;

    // at 850px remove the PhoneGridItem, make Text full width
    @media screen and (max-width: 850px) {
        ${Text} {
            flex-basis: 100%;
        }

        ${PhoneGridItem} {
            display: none;
        }
    }
`;

const Phone = styled.img`
    width: 100%;
    max-width: 400px;
    margin-bottom: -5px;
`;


const FacebookCommunity = () => {
    return (
        <Wrapper>
            <Container>
                <TextGridItem>
                    <Text>
                        <h2>
                            <div className="main">The Improvisers Club</div>
                            <div>Student Facebook Group</div>
                            <div className="teal">Community + Access to JP</div>
                        </h2>

                        <div className="testi">
                            <p>“It’s exactly what I want to be a part of. A bunch of drummers learning from and teaching each other with a master guide (you) who is actually active on the page and offering feedback.”</p>
                            <p className="byline">&mdash; John M.</p>
                        </div>

                        <SubscribeBtn fluid large orange pill bold>{TRIAL_DAYS > 0 ? 'Try 7 Days FREE' : 'Join Now'}</SubscribeBtn>
                    </Text>
                    <PhoneGridItem>
                        <Phone src={PhoneImg} alt="Personalized feedback steps" />
                    </PhoneGridItem>
                </TextGridItem>
            
            </Container>
        </Wrapper>
    );
};

export default FacebookCommunity;
