import { combineReducers } from 'redux';

import { courses } from './courses';
import { filters } from './filters';
import { courseModal } from './course-modal';
import { categories } from './categories';
import { curriculum } from './curriculum';
import { featuredCourses } from './featured-courses';

export default combineReducers({
    courses,
    filters,
    courseModal,
    categories,
    curriculum,
    featuredCourses
});
