const defaultTheme = {
    // colors
    colors: {
        teal: '#00b4b5',
        orange: '#e5761c',
        blue: '#628be6',
        yellow: '#e6b062',
        green: '#8ce18c',
        offWhite: '#eaeaea',
        medGrayText: '#676767',
        lightGrayText: '#999999',
        lightGray: '#545454',
        midGray: '#292a2d',
        darkGray: '#17181a',
        modalHeader: '#4e4e4e',
        modalHeaderText: '#fff',
        grayBorder: '#d3d3d3'
    },

    levelColors: {
        beginner: '#62e681',
        intermediate: '#628be6',
        advanced: '#e6b062',
        all_levels: '#fff'
    },

    backgroundColor: '#000',
    textColor: '#fff',
    
    // widths
    containerMaxWidth: '1200px',

    // fonts
    bodyFont: "'Open Sans', sans-serif",
    alternativeFont: "'new-hero', sans-serif",

    // padding
    horizontalPadding: '20px',
    verticalPadding: '30px'
};

export default defaultTheme;