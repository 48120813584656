import { ActionTypes } from 'utils/constants';

const initialState = { open: false };

export const courseOutline = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.TOGGLE_COURSE_OUTLINE: {
            return {
                ...state,
                open: !state.open
            };
        }
        case ActionTypes.SET_COURSE_OUTLINE_ACCORDIAN_SECTION: {
            return {
                ...state,
                activeSection: action.payload
            };
        }
        case ActionTypes.SET_CURRENT_VIDEO: {

            return {
                ...state,
                activeSection: action.payload.activeSection
            };
        }
        case ActionTypes.COPY_MODAL_COURSE:
        case ActionTypes.FETCH_COURSE + '_FULFILLED': {
            if (!action.payload.activeSection) {
                return initialState;
            }
            
            return {
                ...state,
                activeSection: action.payload.activeSection
            };
        }
        default: {
            return state;
        }
    }
};