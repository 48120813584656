import React from 'react';
import Router from './Router';
import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'themes';
import GlobalStyle from './_core/GlobalStyle';
import TokenValidator from './TokenValidator';
// import SubscribeAfterSuccess from './SubscribeAfterSuccess';

import '../themes/toastify-style.css';
import ImpactClickId from './ImpactClickId';

const Main = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <TokenValidator>
                <Router />
            </TokenValidator>
            <ImpactClickId />
            <ToastContainer />
            {/* <SubscribeAfterSuccess /> */}
        </ThemeProvider>
    );
}

export default Main;
