import React from 'react';
import { useSelector } from 'react-redux';

import LoginMenuItem from 'components/LoginMenuItem';

import Wrapper from '../_core/Wrapper';
import Header from '../_core/Header';
import Hero from './Hero';
import Container from '../_core/Container';
import Footer from '../_core/Footer';

import CourseModal from './CourseModal';
import CourseSearch from './CourseSearch';

import MyLearning from './MyLearning';
import MyLearningView from './MyLearningView';
import MyLearningViewSwitcher from './MyLearningViewSwitcher';
import UnderlineLink from 'components/_core/UnderlineLink';
import { getEnvVar } from 'utils/get-env-var';

const navItems = [
    () => <li><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/account`}>Account</UnderlineLink></li>,
    () => <li><LoginMenuItem /></li>
];

const SubscriberLanding = () => {
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    return (
        <>
            <Wrapper style={{ marginBottom: '60px' }}>
                <Header hideLogoUntilScroll={!isSubscriber} dontFadeBarIn={isSubscriber} navItems={navItems} />
                {isSubscriber ? <MyLearning /> : <Hero />}
                <Container style={{ padding: '30px 0' }}>
                    <MyLearningViewSwitcher />
                    <MyLearningView />
                </Container>
            </Wrapper>
            <Footer />
            <CourseModal />
            <CourseSearch />
        </>
    );
};

export default SubscriberLanding;
