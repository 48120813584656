import styled from 'styled-components';

const InlineNav = styled.nav`
    ul {
        display: flex;
        align-items: center;
        list-style: none;

        li {
            margin-right: 5px;
            margin-top: 2px;

            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
`;

export default InlineNav;