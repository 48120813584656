export default (course, currentVideo) => {
    const sections = course.contents;

    for (let i = 0, e = sections.length; i < e; i++) {
        const videos = sections[i].videos;

        const found = videos.find(v => v.id === currentVideo.id);

        if (found) {
            return i;
        }
    }

    return -1;
};
