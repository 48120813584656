import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faPlay, faStar, faLink, faUpload, faMusic } from '@fortawesome/free-solid-svg-icons'
import VideoCompleteCheckbox from './VideoCompleteCheckbox';
import Accordian from 'components/_core/Accordian';
import ActionsMenu from './ActionsMenu';

import { setCurrentVideo, toggleCourseOutline, setActiveSection } from 'redux/actions';

const Wrapper = styled.div`
    background-color: #292a2d;
    position: absolute;
    top: 80px;
    right: 0;
    width: 400px;
    height: calc(100% - 80px);
    max-width: 100%;

    padding-bottom: 180px;

    overflow-y: auto;

    @media screen and (max-width: 1000px) {
        transition: right 0.3s;
        z-index: 10;

        ${props => {
            if (!props.open) {
                return `
                    right: -400px;
                `;
            }
        }}
    }
`;

const ChapterHeader = styled.div`
    padding: 20px 25px;
    cursor: pointer;
    border-bottom: 1px solid #444;
    color: #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
    }

    ${props => {
        if (props.active) {
            return `cursor: default;`;
        }
    }}
`;

const LessonList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #3a3b3d;
`;

const Lesson = styled.li`
    color: #ddd;
    padding: 15px 25px;
    border-bottom: 1px solid #444;
    border-left: 6px solid transparent;
    position: relative;

    cursor: pointer;

    display: flex;
    align-items: center;

    h3 {
        font-size: 16px;
        margin: 0 15px 0 0;
        font-weight: 300;
        flex-grow: 1;
    }

    ${props => {
        if (props.playing) {
            return `
                cursor: default;
                background-color: #017a7b;
            `;
        }
    }}

    ${props => {
        if (!props.isVideo) {
            return `
                font-style: italic;
            `;
        }
    }}
`;

const Indicator = styled.div`
    color: ${props => props.theme.colors.teal};
    position: absolute;
    left: 4px;
    top: 50%;
    margin-top: -8px;
    font-size: 12px;

    ${props => {
        if (props.favorite) {
            return `
                color: ${props.theme.colors.orange};
                left: 2px;
            `;
        }
    }}
`;

const TitleIcon = styled.div`
    margin: 0 15px 0 2px;
`;

const InProgressText = styled.p`
    margin-top: 20px;
    font-size: 20px;
    color: #666;
    text-align: center;
`;

const CourseOutline = ({ course }) => {
    const dispatch = useDispatch();

    const currentVideo = useSelector(state => state.currentCourse.currentVideo);
    const open = useSelector(state=> state.currentCourse.courseOutline.open);
    const activeSection = useSelector(state => state.currentCourse.courseOutline.activeSection);

    const playVideo = video => {
        dispatch(toggleCourseOutline());
        dispatch(setCurrentVideo(video));
    };

    const doAction = video => {
        if (video.type === 'link') {
            return window.open(video.link);
        }

        playVideo(video);
    };
    
    return (
        <Wrapper open={open}>
            <Accordian
                setActiveSection={idx => dispatch(setActiveSection(idx))}
                activeSection={activeSection > -1 ? activeSection : 0}
                sections={course.contents || []}
                HeaderComponent={headerProps => (
                    <ChapterHeader active={headerProps.active} onClick={() => headerProps.onClick()}>
                        <h3>{headerProps.name}</h3>
                        <FontAwesomeIcon icon={headerProps.active ? faChevronDown : faChevronUp} />
                    </ChapterHeader>
                )}
                ItemsComponent={itemProps => (
                    <LessonList>
                        {itemProps.videos.map((video, i) => {
                            const isVideo = video.type === 'video';
                            const highlighted = currentVideo.id === video.id;
                            const playing = isVideo && highlighted;
                            
                            return (
                                <Lesson isVideo={isVideo} playing={highlighted} onClick={() => doAction(video)} key={`video-${i}`}>
                                    {playing && <Indicator><FontAwesomeIcon icon={faPlay} /></Indicator>}
                                    {video.favorited && !playing && <Indicator favorite><FontAwesomeIcon icon={faStar} /></Indicator>}
                                    {isVideo && <VideoCompleteCheckbox videoId={video.id} />}
                                    {video.type === 'link' && <TitleIcon><FontAwesomeIcon icon={faLink} /></TitleIcon>}
                                    {video.type === 'progress' && <TitleIcon><FontAwesomeIcon icon={faUpload} /></TitleIcon>}
                                    {video.type === 'audio' && <TitleIcon><FontAwesomeIcon icon={faMusic} /></TitleIcon>}
                                    <h3>{video.name}</h3>
                                    <ActionsMenu video={video} />
                                </Lesson>
                            );
                        })}
                    </LessonList>
                )}
            />
            {course.in_progress && <InProgressText>Next lesson coming Saturday</InProgressText>}
        </Wrapper>
    );
};

export default CourseOutline;
