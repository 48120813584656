import { applyMiddleware, createStore, compose } from 'redux';

import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { auth } from './middleware/auth';
import { currentVideoDetails } from './middleware/current-video-details';
import { downloadVideo } from './middleware/download-video';
import { impactMiddleware } from './middleware/impact';

import reducers from './reducers';

const initalState = {};

const isDev = process.env.NODE_ENV === 'development';

const composeEnhancers =
    isDev && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) :
    compose;

const middleware = composeEnhancers(
    applyMiddleware(promise, thunk, auth, currentVideoDetails, downloadVideo, impactMiddleware)
);

const store = createStore(
                            reducers,
                            initalState,
                            middleware
                        );
export default store;