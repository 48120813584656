import { ActionTypes } from 'utils/constants';

export const subscription = (state = {}, action) => {
    switch(action.type) {
        case ActionTypes.USER_SUBSCRIPTIONS + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.USER_SUBSCRIPTIONS + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                error: true
            };
        }
        case ActionTypes.USER_SUBSCRIPTIONS + '_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                state: action.payload?.data?.state
            };
        }
        case ActionTypes.CANCEL_SUBSCRIPTION + '_FULFILLED': {
            const subState = {
                ...state.state,
                canceling: true
            };

            return {
                ...state,
                state: subState
            };
        }
        case ActionTypes.UPGRADE_SUBSCRIPTION + '_FULFILLED': {
            return {};
        }
        default: {
            return state;
        }
    }
};
