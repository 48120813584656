import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = {};

export const myFavorites = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_MY_FAVORITES + '_PENDING': {            
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_MY_FAVORITES + '_FULFILLED': {  
            const favorites = getDeepProp(action, 'payload', 'data', 'favorites') || {};
          
            return {
                ...state,
                favorites,
                fetched: true
            };
        }
        case ActionTypes.FETCH_MY_FAVORITES + '_REJECTED': {    
            const error = getDeepProp(action, 'payload', 'response', 'data', 'msg');
        
            return {
                ...state,
                fetching: false,
                fetched: false,
                error
            };
        }
        case ActionTypes.LOGOUT:
        case ActionTypes.VALIDATE_JWT + '_REJECTED': {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
