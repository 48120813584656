import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import LoaderButton from 'components/_core/LoaderButton';
import Input from 'components/_core/Input';
import PasswordInput from 'components/_core/PasswordInput';
import UnderlineButton from 'components/_core/UnderlineButton';
import { Modal, ModalBody } from 'components/_core/Modal';
import { UserModalWrapper, UserModalCloseBtn, UserModalBodyGray } from 'components/_core/UserModal';
import Message from 'components/_core/Message';

import { toggleLoginModal, toggleJoinModal, login, sendResetPasswordLink } from 'redux/actions';

const LoginModal = () => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.user.login.modalOpen);
    const processing = useSelector(state => state.user.login.processing);
    const backendError = useSelector(state => state.user.login.error);
    const success = useSelector(state => state.user.login.success);

    const defaultUser = { email: '', password: '' };
    const [user, setUser] = useState(defaultUser);
    const [validationError, setValidationError] = useState();
    const [forgotMode, setForgotMode] = useState(false);

    const setUserProp = (key, e) => {
        const newUser = {
            ...user,
            [key]: e.target.value
        };
        setUser(newUser);
    };

    if (!open && (user.email !== defaultUser.email || user.password !== defaultUser.password)) {
        setTimeout(() => setUser(defaultUser), 1000);
    }

    if (!open && forgotMode) {
        setTimeout(() => setForgotMode(false), 1000);
    }

    const submit = e => {
        e.preventDefault();
        let goodToGo = true;

        const requiredProps = forgotMode ? ['email'] : ['email', 'password'];
        requiredProps.forEach(prop => {
            const value = user[prop] || '';
            if (value.trim() === '') {
                goodToGo = false;
                setValidationError('Please fill out all fields');
            }
        });

        if (!goodToGo) {
            return;
        }
        
        setValidationError(null);

        if (forgotMode) {
            return dispatch(sendResetPasswordLink(user));
        }

        return dispatch(login(user));
    };

    const goToJoin = () => {
        dispatch(toggleLoginModal());
        
        setTimeout(() => dispatch(toggleJoinModal()), 300);
    };

    if (success && open) {
        dispatch(toggleLoginModal());
    }
    
    const dialogText =  forgotMode
        ? {
            heading: 'Forgot Password',
            helpText: 'Enter your email address and we will send you a link to reset your password.',
            buttonText: 'Send Reset Link',
            underButtonLink: 'Back to login'
        }
        : {
            heading: 'Login',
            helpText: 'Login to your account here',
            buttonText: 'Login',
            underButtonLink: 'Forgot your password?'
        };

    return (
        <Modal open={open} onClick={() => dispatch(toggleLoginModal())}>
            <UserModalWrapper onClick={e => e.stopPropagation()}>
                <ModalBody>
                    <UserModalCloseBtn onClick={() => dispatch(toggleLoginModal())}>&times;</UserModalCloseBtn>
                    <h2>{dialogText.heading}</h2>
                    <p>{dialogText.helpText}</p>
                    {(validationError || backendError) && <Message error small style={{ textAlign: 'left' }}>{validationError || backendError}</Message>}
                    <form onSubmit={submit}>
                        <Input 
                            name="email"
                            type="text" 
                            placeholder="Email" 
                            value={user.email}
                            disabled={processing}
                            onChange={e => setUserProp('email', e)} />
                        {!forgotMode && <PasswordInput 
                            name="password"
                            placeholder="Password"  
                            value={user.password}
                            disabled={processing}
                            onChange={e => setUserProp('password', e)} />}
                        <LoaderButton orange fluid bold disabled={processing} loading={processing}>{dialogText.buttonText}</LoaderButton>
                    </form>
                    <UnderlineButton onClick={() => setForgotMode(!forgotMode)} style={{ marginTop: '20px', fontSize: '12px', fontWeight: '300' }}>{dialogText.underButtonLink}</UnderlineButton>
                </ModalBody>
                <UserModalBodyGray>
                    Don't have an account yet? <UnderlineButton onClick={() => goToJoin()}>Join now</UnderlineButton>.
                </UserModalBodyGray>
            </UserModalWrapper>
        </Modal>
    );

};

LoginModal.displayName = 'LoginModal';

export default LoginModal;
