import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import CreateAccount from './CreateAccount';
import Plans from './Plans';
import Subscribe from './Subscribe';

import { Modal, ModalBody } from 'components/_core/Modal';
import { UserModalWrapper, UserModalCloseBtn } from 'components/_core/UserModal';
import { toggleJoinModal, toggleLoginModal, setJoinModalScreen } from 'redux/actions';

const JoinModalWrapper = styled(UserModalWrapper)`
    ${props => {
        if (props.screen === 'plans') {
            return `
                max-width: 700px;

                ${ModalBody} {
                    padding: 0;
                }
            `;
        }
    }}
`;

const LoginModal = () => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.user.join.modalOpen);
    const screen = useSelector(state => state.user.join.screen);
    const loggedIn = useSelector(state => state.user.auth.loggedIn);

    const toggleModal = () => dispatch(toggleJoinModal());
    const setScreen = screen => dispatch(setJoinModalScreen(screen));
    const goToLogin = () => {
        toggleModal();
        
        setTimeout(() => dispatch(toggleLoginModal()), 300);
    };

    const Screen = props => {
        if (screen === 'create-account') {
            return <CreateAccount {...props} />;
        } else if (screen === 'subscribe') {
            return <Subscribe {...props} />;
        } else {
            return <Plans {...props} />;
        }
    };

    return (
        <Modal open={open} onClick={toggleModal}>
            <JoinModalWrapper screen={screen} onClick={e => e.stopPropagation()}>
                <UserModalCloseBtn onClick={toggleModal}>&times;</UserModalCloseBtn>
                <Screen goToLogin={goToLogin} setJoinModalScreen={setScreen} loggedIn={loggedIn} toggleJoinModal={toggleModal} />
            </JoinModalWrapper>
        </Modal>
    );

};

LoginModal.displayName = 'LoginModal';

export default LoginModal;
