import { ActionTypes } from 'utils/constants';

const initialState = { screen: 'plans' };

export const join = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.TOGGLE_JOIN_MODAL: {
            return {
                ...state,
                modalOpen: !state.modalOpen
            };
        }
        case ActionTypes.SET_JOIN_MODAL_SCREEN: {
            return {
                ...state,
                screen: action.payload
            }
        }
        case ActionTypes.CREATE_ACCOUNT + '_FULFILLED': {
            return {
                ...state,
                screen: 'subscribe'
            };
        }
        case ActionTypes.SET_JOIN_PLAN: {
            return {
                ...state,
                plan: action.payload.plan,
                screen: action.payload.nextScreen
            };
        }
        default: {
            return state;
        }
    }
};
