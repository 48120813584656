import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const toggleLoginModal = () => {
    return (dispatch, getState) => {
        const state = getState();
        const open = state.user.login.modalOpen;
        
        document.body.style.overflow = open ? 'visible' : 'hidden';

        dispatch({
            type: ActionTypes.TOGGLE_LOGIN_MODAL
        });
    };
};

export const login = payload => (
    {
        type: ActionTypes.LOGIN,
        payload: axios.post(`${getEnvVar('API_BASE')}/login`, payload)
    }
);
