import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Vimeo from '@vimeo/player';
import { getEnvVar } from 'utils/get-env-var';

import { Modal, ModalHeader, ModalWrapper, ModalClose, ModalBody as BaseModalBody } from 'components/_core/Modal';
import Button from 'components/_core/Button';
import InlineLoader from 'components/_core/InlineLoader';
import CourseOutline from './CourseOutline';
import CourseFetcher from './CourseFetcher';
import ProgressCircle from 'components/_core/ProgressCircle';
import { SubscribeBtn } from 'components/buttons/SubscribeBtn';
import PreviewVideoList from './PreviewVideoList';
import Testimonials from './Testimonials';
import { LEVEL_MAPPING } from 'utils/constants';

const TopContent = styled.div`
    position: relative;
    margin-bottom: 20px;

    @media screen and (max-width: 700px) {
        display: block;
    }
`;

const ModalBody = styled(BaseModalBody)`
    border-radius: 0 0 5px 5px;
`;

const Description = styled.div`
    h2 {
        margin: 0 0 15px 0;

        @media screen and (max-width: 500px) {
            font-size: 20px
        }
    }

    p {
        color: ${props => props.theme.colors.lightGray};
        margin: 5px 0;
    }
`;

const Details = styled.p`
    color: ${props => props.theme.colors.teal};
    text-transform: uppercase;
    font-size: 13px;
`;

const CTA = styled.div`
    ${Button} {
        width: 250px;
        max-width: 100%;
        padding: 20px 30px;
        font-weight: 600;
    }
`;

const VideoPreview = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    
    transition: opacity 0.3s;

    #preview-wrap {
        height: 100%;
    }
 
    ${props => {
        if (props.show) {
            return `
                opacity: 1;
                pointer-events: all;
            `;
        } else {
            return `
                opacity: 0;
                pointer-events: none;
            `;
        }
    }}

    #preview-wrap {
        pointer-events: none;
        
        iframe {
            width: 100%;
            height: 100%;
        }
    }
`;

const PlayPauseBtn = styled.button`
    position: absolute;
    bottom: 0;
    left: 0;

    padding: 0 0 20px 20px;

    border: 0;
    outline: 0;
    background: none;
    color: #fff;

    &:before, &:after {
        content: '';
        display: inline-block;
    }

    &:before {
        width: 0;
    }

    ${props => {
        if (props.playing) {
            return `
                &:before, &:after {
                    border-left: 7px solid #fff;
                    height: 20px;
                    margin: 0 3px;
                }
            `;
        } else {
            return `
                &:before {
                    border: solid transparent;
                    height: 0;

                    border-color: rgba(255, 255, 255, 0);
                    border-left-color: #fff;

                    border-width: 10px;
                    border-left-width: 15px;
                    margin: 0 3px;
                }

                &:after {
                    opacity: 0;
                }
            `;
        }
    }}
`;

const ExitPreviewBtn = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;

    padding: 8px 12px;
    font-size: 12px;

    border: 0;
    outline: 0;
    background-color: rgba(50, 50, 50, 0.5);
    border-radius: 5px;
    color: #fff;

    cursor: pointer;

    &:hover {
        background-color: rgba(50, 50, 50, 0.8);
    }
`;


export default class CourseModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    openPreview(videoId) {
        if (this.player) {
            this.player.destroy();
        }
        const opts = {
            id: videoId,
            autoplay: true,
            width: '100%',
            height: '100%',
            controls: false
        };

        this.player = new Vimeo(this.previewPlayerTarget, opts);
        this.setState({ playingPreview: true });

        this.props.playCoursePreview();
    }

    exitPreview() {
        if (this.player) {
            this.player.destroy();
        }

        this.props.stopCoursePreview();
    }

    playPause() {
        if (this.state.playingPreview) {
            this.pausePreview();
        } else {
            this.playPreview();
        }
    }

    pausePreview() {
        if (!this.player) {
            return;
        }

        this.setState({ playingPreview: false });

        this.player.pause();
    }

    playPreview() {
        if (!this.player) {
            return;
        }

        this.setState({ playingPreview: true });

        this.player.play();
    }

    copyCourseAndClose() {
        this.props.copyModalCourse(); // copy the loaded course from the modal to the current course reducer
        this.props.closeCourseModal();
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const course = this.props.course || {};
        const previewVideoId = course.previewVideoId;
        
        return (
            <Modal open={this.props.open} onClick={() => this.props.closeCourseModal()}>
                <ModalWrapper onClick={e => e.stopPropagation()} style={{ borderRadius: '5px', overflow: 'hidden' }}>
                    <ModalHeader style={{ padding: '7px 20px'}}>
                        <ProgressCircle percent={course.percent_complete} />
                        <h2 style={{marginLeft: '10px'}}>{this.props.courseName}</h2>
                        <ModalClose onClick={() => this.props.closeCourseModal()}>&times;</ModalClose>
                    </ModalHeader>
                    <ModalBody>
                        <CourseFetcher slug={this.props.courseSlug} loader={<InlineLoader text="Loading Course" dark />}>
                            <TopContent>
                                <Description>
                                    <h2>Description</h2>
                                    <p dangerouslySetInnerHTML={{ __html: course.description }} />
                                </Description>
                                <Details>{course.videoCount} Lessons &bull; {LEVEL_MAPPING[course.level]}</Details>
                                <CTA>
                                    {this.props.isSubscriber && <Button as={Link} onClick={() => this.copyCourseAndClose()} to={`${getEnvVar('ROOT_ROUTE')}/course/${course.slug}`} color="teal">Go to course</Button>}
                                    {!this.props.isSubscriber && <SubscribeBtn />}
                                    {!this.props.isSubscriber && !this.props.loggedIn && <p style={{ color: '#999', fontSize: '13px', marginTop: '10px' }}>Already a subscriber? <span style={{ cursor: 'pointer'}} onClick={() => this.props.toggleLoginModal()}>Login</span></p>}
                                </CTA>
                                <VideoPreview show={this.props.playingPreview}>
                                    <div id="preview-wrap" ref={ref => this.previewPlayerTarget = ref}></div>
                                    <ExitPreviewBtn onClick={() => this.exitPreview()}>Exit Preview</ExitPreviewBtn>
                                    <PlayPauseBtn playing={this.state.playingPreview} onClick={() => this.playPause()} />
                                </VideoPreview>
                            </TopContent>
                            <PreviewVideoList 
                                videos={course.more_preview_videos}
                                openPreview={videoId => this.openPreview(videoId)}
                                mainPreviewId={previewVideoId}
                                mainPreviewPhoto={course.photo}
                            />
                            <Testimonials testimonials={course.testimonials} />
                            
                        </CourseFetcher>
                    </ModalBody>
                    {course.contents && <CourseOutline chapters={course.contents} />}
                </ModalWrapper>
            </Modal>
        );
    }
}
