import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import StagePlaceholder from 'img/progress-post-stage.png';

const Stage = styled.div`
    background-color: #000;
    width: 100%;

    @media screen and (max-width: 450px) and (min-height: 600px) {
        position: fixed;
        z-index: 9;
    }
`;

const FixedStagePlaceholder = styled.div`
    @media screen and (max-width: 450px) and (min-height: 600px) {
        width: 100%;
        height: auto;

        padding-top: 56.25%;
    }
`;

const VideoWrap = styled.div`
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
`;

const Video = styled.div`
    width: 100%;
    height: auto;

    padding-top: 56.25%;

    ${props => {
        if (props.outlineOpen) {
            return `
                @media screen and (max-width: 1000px) {    
                    pointer-events: none;
                }
            `;
        }
    }}

    iframe, img { 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const ProgressPostStage = ({ progressPost, showResponse }) => {
    const outlineOpen = useSelector(state => state.currentCourse.courseOutline.open);

    const video = useMemo(() => {
        if (!progressPost) return null;

        if (showResponse) {
            return progressPost.response_youtube_id;
        }

        return progressPost.youtube_id;
    }, [progressPost, showResponse]);

    return (
        <>
            <Stage>
                <VideoWrap>
                    <Video outlineOpen={outlineOpen}>
                        {
                            video 
                            ? <iframe width="560" height="315" src={`https://www.youtube.com/embed/${video}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            : <img src={StagePlaceholder} alt="Upload your progress post" />
                        }
                    </Video>
                </VideoWrap>
            </Stage>
            <FixedStagePlaceholder />
        </>
    );
    
};

ProgressPostStage.displayName = 'ProgressPostStage';

export default ProgressPostStage;
