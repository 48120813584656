import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ListView, ListViewItem } from 'components/_core/ListView';
import ProgressPostsFetcher from './ProgressPostsFetcher';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvVar } from 'utils/get-env-var';
import { forceCurrentVideoForNextLoad } from 'redux/actions';

const ProgressPostListViewItem = styled(ListViewItem)`
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;

    cursor: pointer;

    & > * {
        margin-right: 10px;
    }

    .course-name {
        font-size: 13px;
        color: #777;
    }

    .response-received {
        font-size: 13px;
        color: ${props => props.theme.colors.green};
        font-style: italic;
    }

    &:hover {
        background-color: #333;
    }

    @media screen and (max-width: 550px) {
        display: block;
        position: relative;

        span {
            display: block;
            max-width: 75%;
        }

        .video-name {
            margin-bottom: 5px;
        }

        .response-received {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            max-width: 25%;
            text-align: right;
        }
    }
`;

const NoPosts = styled.div`
    text-align: center;
    color: #666;
    font-size: 25px;
    margin-top: 30px;
`;

const MyProgressPosts = () => {
    const dispatch = useDispatch();
    const progressPosts = useSelector(state => state.user.myProgressPosts.posts) || [];

    return (
        <ProgressPostsFetcher>
            <ListView>
                {!progressPosts.length && <NoPosts>You haven't posted any Progress Posts yet</NoPosts>}
                {progressPosts.map((video, i) => (
                    <ProgressPostListViewItem 
                        key={`post-${video.video_id}`} 
                        as={Link} 
                        onClick={() => dispatch(forceCurrentVideoForNextLoad(video.video_id))}
                        to={`${getEnvVar('ROOT_ROUTE')}/course/${video.course_slug}`}
                    >
                        <span className="video-name">{video.video_name}</span>
                        <span className="course-name">{video.course_name}</span>
                        {video.response_video_id && <span className="response-received">Response Received</span>}
                    </ProgressPostListViewItem>
                ))}
            </ListView>
        </ProgressPostsFetcher>
        
    )
};

export default MyProgressPosts;