import React, { useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const SelectWrap = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: 40px

    @media screen and (max-width: 750px) {
        width: 100%;
    }
`;

const Select = styled.div`
    cursor: pointer;

    border-radius: 5px;

    font-size: 15px;
    font-weight: 500;
    whitespace: no-wrap;
    background-color: ${props => props.theme.colors.midGray}; 

    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.width}px;

    @media screen and (max-width: 750px) {
        width: 100%;
    }

    z-index: 3;
    
    ${props => {
        if (props.open) {
            return `
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                z-index: 5;
            `;
        }

        if (props.disabled) {
            return `
                cursor: not-allowed;
                opacity: 0.5;
            `;
        }
    }}
`;

const SelectTrigger = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: #bbb;    

    ${props => {
        if (props.value && props.value !== '') {
            return `color: ${props.useLevelColors ? props.theme.levelColors[props.value] : '#fff'}`;
        }
    }}

`;

const SelectIcon = styled.div`
    color: #bbb;
`;

const SelectOptions = styled.div`
    display: none;

    max-height: 300px;
    overflow-y: auto;
    
    border-top: 1px solid #222;

    ${props => {
        if (props.open) {
            return `
                display: block
            `;
        }
    }}
`;

const SelectOption = styled.div`
    padding: 10px 20px;
    color: ${props => props.useLevelColors ? props.theme.levelColors[props.value] : '#ddd'};
    cursor: pointer;

    border-bottom: 1px solid #222;

    &:hover {
        background-color: ${props => props.theme.colors.lightGray};
    }

    &:last-child {
        border-radius: 0 0 5px 5px;
        border: none;
    }
`;

const Curtain = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
`;

export default ({ value, onChange, options, width, placeholder, useLevelColors, disabled, loading }) => {
    let [open, setOpen] = useState();

    const getLabelForValue = value => {
        const selected = options.find(opt => opt.value === value);
        
        return selected ? selected.label : null;
    };

    const toggleOpen = () => {
        if (disabled) {
            return;
        }

        setOpen(!open);
    };

    return (
        <>
            <SelectWrap width={width}>
                <Select width={width} open={open} disabled={disabled}>
                    <SelectTrigger onClick={() => toggleOpen()} value={value} useLevelColors={useLevelColors}>
                        {getLabelForValue(value) || placeholder}
                        <SelectIcon><FontAwesomeIcon spin={loading} icon={loading ? faCircleNotch : (open ? faChevronUp : faChevronDown)} /></SelectIcon>
                    </SelectTrigger>
                    <SelectOptions open={open}>
                        {options.map(option => <SelectOption key={`option-${option.value}`} value={option.value} useLevelColors={useLevelColors} onClick={() => { onChange(option.value); setOpen(false); }}>{option.label}</SelectOption>)}
                    </SelectOptions>
                </Select>
            </SelectWrap>
            {open && <Curtain id="curtain" onClick={() => setOpen(false)} />}
        </>
    );
};
