import React from 'react';
import styled from 'styled-components';

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { TestiGrid, Testimonial } from './TestimonialGrid';

const Wrapper = styled(W)`
    background-color: #fff;
`;

const Container = styled(C)`
    padding: 30px 0;
`;

const TextHeading = styled.div`
    margin: 0 auto 30px;
    color: #000;
    text-align: center;

    h2 {
        font-size: 30px;
        margin: 0;
        font-weight: 200;

        strong {
            font-style: italic;
            font-weight: 700;
        }
    }
`;


const UniversityRigor = () => {
    return (
        <Wrapper>
            <Container>
                <TextHeading>
                    <h2>
                        <strong>University-quality education</strong> for all levels.
                    </h2>
                </TextHeading>

                <TestiGrid>
                    <Testimonial>
                        <p>“<span className="highlight">I actually got accepted</span> to Humber College studying 95% of your stuff.”</p>
                        <p className="teal">&mdash; Lyam S.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“<span className="highlight">Despite studying at conservatory</span>, I’ve learned much more useful stuff studying your videos.”</p>
                        <p className="teal">&mdash; Filip P.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“I have learned more with your method <span className="highlight">than when I went to university</span>.”</p>
                        <p className="teal">&mdash; Luis S.</p>
                    </Testimonial>
                </TestiGrid>

            </Container>
        </Wrapper>
    )
};

export default UniversityRigor;
