import { ActionTypes } from 'utils/constants';

export const curriculum = (state = { linePos: [] }, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_CURRICULUM + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_CURRICULUM + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                error: true
            };
        }
        case ActionTypes.FETCH_CURRICULUM + '_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload?.data || {}
            };
        }
        case ActionTypes.SET_CURRICULUM_LINE_POS:
            const { idx, pos } = action.payload;

            const nextPos = [...state.linePos];
            nextPos[idx] = { ...nextPos[idx] } || {};

            if (!nextPos[idx].top) {
                nextPos[idx].top = pos;
            }

            nextPos[idx].bottom = pos;
            
            return { 
                ...state,
                linePos: nextPos
            };
        default: {
            return state;
        }
    }
};