import { ActionTypes } from 'utils/constants';

const initialState = {};

export const referAFriend = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.VALIDATE_REFERRAL_CODE + '_PENDING': {
            return {
                ...state,
                validating: true
            };
        }
        case ActionTypes.VALIDATE_REFERRAL_CODE + '_REJECTED': {
            return {
                ...state,
                validating: false,
                error: true
            };
        }
        case ActionTypes.VALIDATE_REFERRAL_CODE + '_FULFILLED': {
            return {
                ...state,
                validating: false,
                valid: true,
                reward: action.payload?.data?.reward
            };
        }
        case ActionTypes.FETCH_REFERRAL_CODE + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_REFERRAL_CODE + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                error: true
            };
        }
        case ActionTypes.FETCH_REFERRAL_CODE + '_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                ...action.payload?.data
            };
        }
        default: {
            return state;
        }
    }
};
