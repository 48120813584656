import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getEnvVar } from 'utils/get-env-var';

const LogoDiv = styled(Link).attrs(props => {
    props.size = props.size || 'default';

    props.shaddow = '0 0 20px rgba(0, 0, 0, 0.7)';

    if (props.light) {
        props.color = '#000';
        props.shaddow = 'none';
    }

    switch(props.size) {
        case 'xsmall':
            props.fontSize = '15px';
            break;
        case 'small':
            props.fontSize = '18px';
            break;
        case 'large':
            props.fontSize = '24px';
            break;
        case 'xlarge':
            props.fontSize = '30px';
            break;
        default: 
            props.fontSize = '24px';
            break;
    }

    return props;
})`
    font-family: ${props => props.theme.alternativeFont};
    font-size: ${props => props.fontSize};
    color: ${props => props.color || props.theme.textColor};
    font-weight: 100;
    text-shadow: ${props => props.shaddow};
    text-decoration: none;
    white-space: nowrap;

    @media screen and (max-width: 565px) {
        display: none;
    }
`;

const LogoText = props => { 
    const theProps = {...props};
    delete theProps.stacked;
    delete theProps.theme;

    return <LogoDiv to={`${getEnvVar('ROOT_ROUTE')}/`} className="logo-text" {...theProps}><strong>JP Bouvet</strong> Method</LogoDiv>;
};


export default LogoText;