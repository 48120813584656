import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { toggleCourseSearch } from 'redux/actions';

const SearchIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 22px;
    font-weight: 100;
    color: #666;

    @media screen and (max-width: 750px) {
        position: absolute;
        top: 5px;
        right: 125px;
    }
`;

const SearchTrigger = () => {
    const dispatch = useDispatch();

    return (
        <SearchIcon icon={faSearch} onClick={() => dispatch(toggleCourseSearch())} />
    );
};

SearchTrigger.displayName = 'SearchTrigger';

export default SearchTrigger;
