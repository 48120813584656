import React from 'react';
import { useSelector } from 'react-redux';

import LoginRedirect from './LoginRedirect';
import AccountWrapper from './AccountWrapper';

const Account = () => {
    const isLoggedIn = useSelector(state => state.user.auth.loggedIn);

    if (!isLoggedIn) {
        return <LoginRedirect />;
    }

    return <AccountWrapper />;
};

export default Account;
