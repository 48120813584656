import { ActionTypes } from 'utils/constants';

export const cancelSubscription = (state = {}, action) => {
    switch(action.type) {
        case ActionTypes.CANCEL_SUBSCRIPTION + '_PENDING': {
            return {
                ...state,
                canceling: true
            };
        }
        case ActionTypes.CANCEL_SUBSCRIPTION + '_REJECTED': {
            return {
                ...state,
                canceling: false,
                error: true
            };
        }
        case ActionTypes.CANCEL_SUBSCRIPTION + '_FULFILLED': {
            return {
                ...state,
                canceling: false
            };
        }
        default: {
            return state;
        }
    }
};
