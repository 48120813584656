import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'components/_core/Select';
import { setFilter, fetchCategories } from 'redux/actions';

const CategoryFilter = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.courseList.categories.fetched);
    const categories = useSelector(state => state.courseList.categories.categories);
    const value = useSelector(state => state.courseList.filters.category);

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    let categoryOpts = [];
    
    if (categories && categories.length) {
        categoryOpts = categories.map(cat => ({ value: cat.slug, label: cat.name }));
    }

    categoryOpts.unshift({ value: '', label: 'All Categories' });

    return (
        <Select 
            options={categoryOpts} 
            disabled={!fetched}
            loading={!fetched}
            placeholder="All Categories" 
            width={250} 
            value={value} 
            onChange={val => dispatch(setFilter('category', val))} />                
    );
};

export default CategoryFilter;
