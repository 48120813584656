import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = {};

export const courseVideoPositions = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_COURSE + '_FULFILLED': {
            const course = getDeepProp(action, 'payload', 'data', 'course') || {};
            const contents = course.contents || [];
            const positions = contents.reduce((a, chapter) => {
                chapter.videos.forEach(video => a[video.id] = video.position);

                return a;
            }, {});

            return {
                ...state,
                positions
            };
        }
        case ActionTypes.SET_VIDEO_POSITION: {
            const newPositions = {...state.positions};
            newPositions[action.payload.videoId] = action.payload.position;
            return {
                ...state,
                positions: newPositions
            };
        }
        default: {
            return state;
        }
    }
};
