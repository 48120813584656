import { ActionTypes } from 'utils/constants';

export const sales = (state = {}, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_ACTIVE_SALE + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_ACTIVE_SALE + '_FULFILLED': {
            const sale = action.payload.data;
            
            return {
                ...state,
                fetching: false,
                fetched: true,
                sale
            };
        }
        case ActionTypes.FETCH_ACTIVE_SALE + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: true
            };
        }
        case ActionTypes.FORGOT_PASSWORD + '_FULFILLED':
        case ActionTypes.LOGIN + '_FULFILLED': {
            return {};
        }
        default: {
            return state;
        }
    }
};
