import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { openCourseModal } from 'redux/actions';
import Items from './Items';


const BaseItem = styled.div`
    position: relative;
    z-index: 2;

    ${props => {
        if (props.title) {
            return `
                &:before {
                    content: '${props.title}';
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    width: 100%;
                    font-size: 
                    color: #fff;
                    margin-bottom: 35px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    font-style: italic;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    transform: rotate(45deg);
                    border: 3px solid #fff;
                    border-top: none;
                    border-left: none;
            
                    width: 16px;
                    height: 16px;
            
                    margin-left: -10px;
                    margin-bottom: 15px;
                }
            `;
        }
    }}
`;

const CourseItem = styled(BaseItem)`
    background-color: #666;
    text-align: center;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #333;
    }

    ${props => {
        if (props.complete) {
            return 'background-color: green;';
        }
    }}
`;

const GroupItem = styled(BaseItem)`
    border: 1px solid #333;
    border-radius: 10px;
    padding: 10px;
    max-width: 300px;
    margin: 0 auto;

    ${CourseItem} {
        margin: 5px 0;
    }
`;

const GroupTitle = styled.h4`
    text-align: center;
    font-size: 14px;
    margin: 5px 0 15px 0;
    text-transform: uppercase;
    color: #aaa;
`;

const EmptyItem = styled(BaseItem)``;

const Item = ({ item, setPos }) => {
    const dispatch = useDispatch();
    const [element, setElement] = useState();

    const setThePos = useCallback(() => {
        if (!element) {
            return;
        }

        setPos(element.getBoundingClientRect());
    }, [element, setPos]);

    useLayoutEffect(() => {
        let debouncer;

        function calculatePos() {
            clearTimeout(debouncer);
            debouncer = setTimeout(() => {
                setThePos();
            }, 500);
        };

        window.addEventListener('resize', calculatePos);
    
        return () => window.removeEventListener('resize', calculatePos);
    }, [setThePos]);

    const ref = el => {
        if (!el) return;

        setElement(el);
        setThePos();
    };

    if (item.type === 'course') {
        return <CourseItem ref={ref} title={item.show_title} complete={item.course.complete} onClick={() => dispatch(openCourseModal(item.course.slug, item.course.name))}>{item.course.name}</CourseItem>;
    }

    if (item.type === 'group') {
        return (
            <GroupItem ref={ref}>
                {item.title && <GroupTitle>{item.title}</GroupTitle>}
                <Items items={item.items} />
            </GroupItem>
        );
    }

    return <EmptyItem />;
};

export default Item;
