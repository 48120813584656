import { ActionTypes } from 'utils/constants';

const initialState = {};

export const impact = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.IMPACT_EMAIL_SENT: {
            return {
                ...state,
                emailSet: action.payload
            };
        }
        case ActionTypes.SET_IMPACT_CLICK_ID: {
            return {
                ...state,
                clickId: action.payload
            };
        }
        default: {
            return state;
        }
    }
};
