import React from 'react';
import { useSelector } from 'react-redux';

import { getEnvVar } from 'utils/get-env-var';
import UnderlineLink from 'components/_core/UnderlineLink';
import styled from 'styled-components';

const AccountListItem = styled.li`
`;

const AccountMenuItem = () => {
    const loggedIn = useSelector(state => state.user.auth.loggedIn);

    if (!loggedIn) {
        return null;
    }

    return <AccountListItem><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/account`}>Account</UnderlineLink></AccountListItem>;
};

AccountMenuItem.displayName = 'AccountMenuItem';

export default AccountMenuItem;
