import React from 'react';
import { useSelector } from 'react-redux';

import { Field, FieldWrap, FieldLabel, FieldValue } from './field-ui';

const ReferralCredits = () => {
    const credits = useSelector(state => state.user.referAFriend.balance);

    if (!credits || credits === 0) {
        return null;
    }

    return (
        <Field>
            <FieldWrap>
                <FieldLabel>Referral Credits</FieldLabel>
                <FieldValue>${credits}</FieldValue>
            </FieldWrap>
        </Field>
    );
};

export default ReferralCredits;
