import styled from "styled-components";
import { GridItem, ThreeGrid } from "components/_core/grids";

export const TestiGrid = styled(ThreeGrid)`
    margin: 30px 0;
`;

export const Testimonial = styled(GridItem)`
    color: #000;

    p {
        line-height: 1.4em;
        font-size: 17px;
        margin: 0;
    }

    .highlight {
        // highlight text with yellow background
        background-color: #faf586;
    }
    .teal {
        margin-top: 10px;
        color: ${props => props.theme.colors.teal};
        font-weight: 600;
        font-size: 15px;
    }

    .gray {
        margin-top: 10px;
        color: #999;
        font-size: 15px;
        font-weight: 600;
    }
`;
