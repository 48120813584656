import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const fetchCurriculum = () => (
    {
        type: ActionTypes.FETCH_CURRICULUM,
        payload: axios.get(`${getEnvVar('API_BASE')}/curriculum`)
    }
);

export const setCurriculumLinePos = (idx, pos) => (
    {
        type: ActionTypes.SET_CURRICULUM_LINE_POS,
        payload: { idx, pos }
    }
);
