import React, { useState, useEffect } from 'react';

const WithScreenWidthWrapper = ({ WrappedComponent, ...props }) => {
    const [width, setWidth] = useState(window.outerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.outerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [setWidth]);

    return <WrappedComponent windowWidth={width} {...props} />;
};

const WithScreenWidth = WrappedComponent => {
    return props => <WithScreenWidthWrapper WrappedComponent={WrappedComponent} {...props} />;
};

export default WithScreenWidth;
