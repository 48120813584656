import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListViewItem } from './ListView';
import { LEVEL_MAPPING } from 'utils/constants';
import { openCourseModal } from 'redux/actions';
import { humanList } from 'utils/human-list';

const stackPoint = '800px';

const CourseListItemWrap = styled(ListViewItem)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    text-decoration: none;

    cursor: pointer;

    &:hover {
        background-color: #333;
    }

    @media screen and (max-width: ${stackPoint}) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

const LeftInfo = styled.div`
    flex-grow: 1;

    display: flex;
    align-items: center;

    @media screen and (max-width: ${stackPoint}) {
        flex-basis: 100%;
        margin-bottom: 5px;
    }
`;

const CourseTitle = styled.div`
    font-size: 18px;
    margin-right: 15px;
`;

const CourseCategories = styled.div`
    font-size: 14px;
    color: #999;
    margin-right: 15px;
`;

const CourseLevel = styled.div`
    color: ${props => props.theme.levelColors[props.level]};
    margin-right: 15px;
    font-size: 16px;

    @media screen and (max-width: ${stackPoint}) {
        font-size: 14px;
    }
`;

const CourseLength = styled.div`
    color: ${props => props.theme.colors.lightGrayText};
    text-align: right;
    flex-basis: 100px;
    font-size: 16px;

    @media screen and (max-width: ${stackPoint}) {
        flex-basis: unset;
        font-size: 14px;
    }
`;

const CourseCompleteCheck = styled.div`
    background-color: rgba(0,0,0,0.3);
    border-radius: 5px;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    position: relative;

    &::after {
        content: '';
        display: block;
        border: 1px solid #fff;
        border-top: none;
        border-right: none;
        width: 12px;
        height: 6px;

        transform: rotate(-45deg);
        
        position: absolute;
        top: 7px;
        left: 5px;
    }
`;

const CourseListItem = props => {
    const dispatch = useDispatch();
    const allCategories = useSelector(state => state.courseList.categories.categories) || [];

    const mappedCategories = (props.categories || []).map(catSlug => {
        const foundCat = allCategories.find(cat => cat.slug === catSlug);
        return foundCat ? foundCat.name : null;
    }).filter(cat => cat);

    return (
        <CourseListItemWrap onClick={() => dispatch(openCourseModal(props.slug, props.name))}>
            <LeftInfo>
                <CourseTitle>{props.name}</CourseTitle>
                <CourseCategories>{humanList(mappedCategories)}</CourseCategories>
                {props.complete && <CourseCompleteCheck />}
            </LeftInfo>
            <CourseLevel level={props.level}>{LEVEL_MAPPING[props.level]}</CourseLevel>
            <CourseLength>{props.videoCount} Videos</CourseLength>
        </CourseListItemWrap>
    )
};

export default CourseListItem;
