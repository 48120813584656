const env = {
    local: {
        PDF_PATH: 'http://localhost/jp-bouvet-react/pdfs',
        UPLOAD_PATH: 'http://localhost/jp-bouvet-react/uploads',
        API_BASE: 'http://localhost/jp-bouvet-react/public-api',
        ROOT_ROUTE: '',
        STRIPE_PUBLIC_KEY: 'pk_test_5YT6o9bW7FpcSqpJsRn8OP7z'
    },
    production: {
        PDF_PATH: 'https://jpbouvetmethod.com/pdfs',
        UPLOAD_PATH: 'https://jpbouvetmethod.com/uploads',
        API_BASE: 'https://jpbouvetmethod.com/public-api',
        ROOT_ROUTE: '',
        STRIPE_PUBLIC_KEY: 'pk_live_911hStJeAHUnLvi00GwThjb9'
    }
};

export default env;
