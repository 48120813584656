import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import { paramsFromQuerystring }  from 'utils/params-from-querystring';
import { getEnvVar } from 'utils/get-env-var';

import Header from 'components/_core/Header';
import Container from 'components/_core/Container';
import LoginMenuItem from 'components/LoginMenuItem';
import Message from 'components/_core/Message';
import InlineLoader from 'components/_core/InlineLoader';

import Wrapper from 'components/_core/Wrapper';
import Footer from 'components/_core/Footer';

import { confirmEmail } from 'redux/actions';


const navItems = [
    () => <li><LoginMenuItem /></li>
];

const ResetPasswordContainer = styled(Container)`
    margin: 100px auto 60px;
    max-width: 600px;
`;

const ConfirmEmailWrap = ({ children }) => (
    <>
        <Wrapper>
            <Header navItems={navItems} />
            <ResetPasswordContainer>
                <h2>Confirm Your Email</h2>
                {children}
            </ResetPasswordContainer>
        </Wrapper>
        <Footer />
    </>
);

const ConfirmEmail = () => {
    const dispatch = useDispatch();
    const complete = useSelector(state => state.user.confirmEmail.complete);
    const error = useSelector(state => state.user.confirmEmail.error);

    const qsParams = paramsFromQuerystring();
    const token = qsParams.token;

    useEffect(() => {
        if (token) {
            dispatch(confirmEmail(token));
        }
    }, [dispatch, token]);
    
    const ROOT_ROUTE = getEnvVar('ROOT_ROUTE');

    if (!token) {
        return <Redirect to={`${ROOT_ROUTE}/`} />;
    }

    if (error) {
        return <ConfirmEmailWrap><Message error small style={{ textAlign: 'left' }}>{error}</Message></ConfirmEmailWrap>;
    }

    if (!complete) {
        return <ConfirmEmailWrap><InlineLoader text="Confirming..." /></ConfirmEmailWrap>;
    }

    return <Redirect to={`${ROOT_ROUTE}/`} />;
}

export default ConfirmEmail;
