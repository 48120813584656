import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import InlineLoader from 'components/_core/InlineLoader';
import { validateToken } from 'redux/actions';


const headerHeight = 80;

const Container = styled.div`
    position: absolute;
    top: ${headerHeight}px;
    left: 0;
    width: 100%;
    height: calc(100% - ${headerHeight}px);
    
    background-color: #17181a;
    overflow-y: auto;

    padding: 30px 15px;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const TokenValidator = (
    {
        children
    }
) => {
    const dispatch = useDispatch();
    const jwt = useSelector(state => state.user.auth.jwt);
    const jwtValidated = useSelector(state => state.user.auth.jwtValidated);

    useEffect(() => {
        if (jwt && !jwtValidated) {
            dispatch(validateToken(jwt));
        }
    }, [jwt, jwtValidated, dispatch]);

    if (jwt && !jwtValidated) {
        return <Container><InlineLoader text="Logging In" /></Container>;
    }

    return children;
};

export default TokenValidator;
