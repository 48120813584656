import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvVar } from 'utils/get-env-var';
import { setCookie } from 'utils/set-cookie';

import Wrapper from 'components/_core/Wrapper';
import Container from 'components/_core/Container';
import Header from 'components/_core/Header';
import Message from 'components/_core/Message';
import Hero from 'components/_core/Hero';
import UnderlineLink from 'components/_core/UnderlineLink';
import LoginMenuItem from 'components/LoginMenuItem';
import Footer from 'components/_core/Footer';
import { validateReferralCode } from 'redux/actions';
import InlineLoader from './_core/InlineLoader';

const navItems = [
    () => <li className="hide-mobile"><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/courses`}>Courses</UnderlineLink></li>,
    () => <li><LoginMenuItem /></li>
];

const ReferralCodeApplied = ({ match: { params: { code } }}) => {
    const dispatch = useDispatch();
    const reward = useSelector(state => state.user.referAFriend.reward);
    const valid = useSelector(state => state.user.referAFriend.valid);
    const error = useSelector(state => state.user.referAFriend.error);
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    useEffect(() => {
        dispatch(validateReferralCode(code));
    }, [dispatch, code]);

    useEffect(() => {
        if (valid && code) {
            setCookie('ref_code', code, 30);
        }
    }, [valid, code]);

    if (isSubscriber) {
        return <Redirect to='/' />;
    }

    if (error) {
        return (
            <>
                <Wrapper>
                    <Header hideLogoUntilScroll navItems={navItems} />
                    <Hero hideCoursesBtn>Welcome</Hero>
                </Wrapper>
                <Container>
                    <Message error small>Invalid referral code</Message>
                </Container>
                <Footer />
            </>
        );
    }

    if (!valid) {
        return <InlineLoader text="Validating referral code" />;
    }

    return (
        <>
            <Wrapper>
                <Header hideLogoUntilScroll navItems={navItems} />
                <Hero hideCoursesBtn subheading={`Subscribe now to claim your $${reward} credit`}>Welcome</Hero>
            </Wrapper>
            <Footer />
        </>
    );
};

export default ReferralCodeApplied;
