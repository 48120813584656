import { ActionTypes } from 'utils/constants';
import currentSectionIndex from 'utils/current-section-index';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const fetchCourse = slug => (
    {
        type: ActionTypes.FETCH_COURSE,
        payload: axios.get(`${getEnvVar('API_BASE')}/course/${slug}`)
    }
);

export const getCourseInProgress = () => (
    {
        type: ActionTypes.USER_CURRENT_COURSE,
        payload: axios.get(`${getEnvVar('API_BASE')}/user-current-course`)
    }   
);

export const copyModalCourse = () => {
    return (dispatch, getState) => {
        const state = getState();
        const modalCourse = state.courseList.courseModal.modalCourse;

        if (!modalCourse) {
            return;
        }

        dispatch({
            type: ActionTypes.COPY_MODAL_COURSE,
            payload: modalCourse
        });
    };
}

let progressDebouncer;
let lastSentPosition, lastSeenPosition;

export const setCurrentVideo = video => {
    return (dispatch, getState) => {
        progressDebouncer = null;

        const state = getState();
        const course = state.currentCourse.courseDetails.course;

        const activeSection = course ? currentSectionIndex(course, video) : -1;
        video.activeSection = activeSection;

        lastSentPosition = null;
        lastSeenPosition = null;

        dispatch({
            type: ActionTypes.SET_CURRENT_VIDEO,
            payload: video
        });

        if (!video.isPreview) {
            const payload = {
                action: 'SET_CURRENT_VIDEO',
                videoId: video.id,
                courseId: course.id
            };

            if (video.type === 'video') {
                dispatch({
                    type: ActionTypes.COURSE_PROGRESS,
                    payload: axios.post(`${getEnvVar('API_BASE')}/course-progress`, payload)
                });
            }
        }
    };
};

export const clearCurrentVideo = () => (
    {
        type: ActionTypes.CLEAR_CURRENT_VIDEO
    }
);

export const forceCurrentVideoForNextLoad = videoId => (
    {
        type: ActionTypes.FORCE_SET_CURRENT_VIDEO,
        payload: videoId
    }
);


export const toggleVideoComplete = (videoId, complete) => {
    return (dispatch, getState) => {
        const state = getState();
        const course = state.currentCourse.courseDetails.course;

        const payload = {
            action: 'TOGGLE_VIDEO_COMPLETE',
            videoId,
            courseId: course.id,
            complete
        };

        dispatch({
            type: ActionTypes.TOGGLE_VIDEO_COMPLETE,
            payload: { videoId }
        });

        dispatch({
            type: ActionTypes.TOGGLE_VIDEO_COMPLETE,
            payload: axios.post(`${getEnvVar('API_BASE')}/course-progress`, payload)
        });
    };
};

export const toggleCurrentVideoFavorite = favorited => {
    return (dispatch, getState) => {
        const state = getState();
        const course = state.currentCourse.courseDetails.course;
        const videoId = state.currentCourse.currentVideo.id;

        const payload = {
            action: 'TOGGLE_VIDEO_FAVORITE',
            videoId,
            courseId: course.id,
            favorited
        };

        dispatch({
            type: ActionTypes.TOGGLE_VIDEO_FAVORITE,
            payload: { videoId }
        });

        dispatch({
            type: ActionTypes.TOGGLE_VIDEO_FAVORITE,
            payload: axios.post(`${getEnvVar('API_BASE')}/course-progress`, payload)
        });
    };
};

export const setVideoPosition = position => {
    return (dispatch, getState) => {
        const state = getState();
        const course = state.currentCourse.courseDetails.course;
        const video = state.currentCourse.currentVideo;
  
        if (!video.isPreview) {
            const complete = position >= 0.9;

            // set position on the course
            dispatch({
                type: ActionTypes.SET_VIDEO_POSITION,
                payload: {
                    videoId: video.id,
                    position,
                    complete
                }
            });
    
            lastSeenPosition = position;

            const sendUpdate = () => {
                clearTimeout(progressDebouncer);
                progressDebouncer = null;

                const payload = {
                    action: 'VIDEO_PROGRESS',
                    videoId: video.id,
                    courseId: course.id,
                    position: lastSeenPosition,
                    complete
                };

                lastSentPosition = position;

                dispatch({
                    type: ActionTypes.SET_VIDEO_POSITION,
                    payload: axios.post(`${getEnvVar('API_BASE')}/course-progress`, payload)
                });
            };

            if (lastSentPosition && Math.abs(position - lastSentPosition) > 0.1) {
                sendUpdate();
                progressDebouncer = null;
            } else {
                if (!progressDebouncer) {
                    progressDebouncer = setTimeout(() => {
                        sendUpdate();
                    }, 5000);
                }
            }
        }
    };
};

export const setActiveSection = idx => (
    {
        type: ActionTypes.SET_COURSE_OUTLINE_ACCORDIAN_SECTION,
        payload: idx
    }
);

export const setCourseDetailsTab = tab => (
    {
        type: ActionTypes.SET_COURSE_DETAILS_TAB,
        payload: tab
    }
);

export const toggleCourseOutline = () => (
    {
        type: ActionTypes.TOGGLE_COURSE_OUTLINE
    }
);

export const addProgressPost = (videoId, progressPost) => {
    console.log({ progressPost });
    return (
        {
            type: ActionTypes.ADD_PROGRESS_POST,
            payload: { videoId, progressPost }
        }
    );
};