import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const Circle = styled.div.attrs(props => {
    props.diameter = props.diameter || 60;
    props.circumference = props.innerDiameter * Math.PI;
    return props;
})`
    width: ${props => props.diameter}px;
    height: ${props => props.diameter}px;
    
    position: relative;

    @media screen and (min-width: 701px) {
        margin: 0 15px;
    }

    @media screen and (max-width: 700px) {
        transform: scale(0.65);
    }

    &.no-margin {
        margin: 0;
    }

    .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justift-content: center;
        text-align: center;
        font-size: 14px;
        font-weight: 600;

        color: #fff;

        div {
            flex: 100% 0 0;
        }

        span {
            opacity: 0.7;
            color: #aaa;
        }

        ${props => {
            if (props.color === 'light') {
                return `
                    color: #000;

                    span {
                        color: #666;
                    }
                `;
            }
        }}
    }

    .progress-ring {
        width: ${props => props.diameter}px;
        height: ${props => props.diameter}px;
    }
    
    .complete-ring, .background-ring {
        // axis compensation
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        transition: all 0.5s;

        stroke-dasharray: ${props => `${props.circumference}px ${props.circumference}px`};
        fill: transparent;
        stroke-width: 4px;
    }
    
    .complete-ring {
        stroke: ${props => props.theme.colors.teal};
        stroke-dashoffset: ${props => props.circumference - props.circumference * props.percent}px; 
    }

    .background-ring {
        stroke: #424242;
    }
`;

const ProgressCircle = ({ percent, className, color = 'dark' }) => {
    const diameter = 54;
    const lineThickness = 4;
    const innerDiameter = diameter - lineThickness;

    return (
        <Circle color={color} percent={percent} diameter={diameter} innerDiameter={innerDiameter} className={className}>
            <svg className="progress-ring">
                <circle className="background-ring" cx={diameter / 2} cy={diameter / 2} r={innerDiameter / 2}></circle>
                <circle className="complete-ring" cx={diameter / 2} cy={diameter / 2} r={innerDiameter / 2}></circle>
            </svg>
            <div className="text">
                <div>
                    {percent < 1 ? <>{Math.ceil(percent * 100)}<span>%</span></> : <FontAwesomeIcon icon={faCheck} />}
                </div>
            </div>
        </Circle>
    );
};

export default ProgressCircle;
