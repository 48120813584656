import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = {};

export const userData = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.SUBSCRIBE + '_FULFILLED':
        case ActionTypes.VALIDATE_JWT + '_FULFILLED':
        case ActionTypes.CREATE_ACCOUNT + '_FULFILLED':
        case ActionTypes.RESET_PASSWORD + '_FULFILLED':
        case ActionTypes.SAVE_PROFILE + '_FULFILLED':
        case ActionTypes.LOGIN + '_FULFILLED': {
            const data = getDeepProp(action, 'payload', 'data', 'user_data');
            
            return {
                ...state,
                ...data
            };
        }
        case ActionTypes.LOGOUT: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};
