
import { ActionTypes } from 'utils/constants';
import { paramsFromQuerystring }  from 'utils/params-from-querystring';

const paramsFromQS = paramsFromQuerystring();

const initialState = {};

if (paramsFromQS.video) {
    initialState.overrideWithVideoId = +paramsFromQS.video;
}

export const currentVideo = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_CURRENT_VIDEO: {
            return {
                ...action.payload,
            };
        }
        case ActionTypes.FORCE_SET_CURRENT_VIDEO: {
            return {
                ...state,
                overrideWithVideoId: action.payload
            };
        }
        case ActionTypes.CLEAR_CURRENT_VIDEO: {
            return {};
        }
        case ActionTypes.COPY_MODAL_COURSE:
        case ActionTypes.FETCH_COURSE + '_FULFILLED': {
            if (state.currentVideoForced) {
                return state;
            }
            
            if (!action.payload.currentVideo) {
                return {};
            }
            
            return {
                ...action.payload.currentVideo
            };
        }
        case ActionTypes.ADD_PROGRESS_POST: {
            return {
                ...state,
                progress_post: action.payload.progressPost
            };
        }
        default: {
            return state;
        }
    }
};