import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Message from 'components/_core/Message';
import LoaderButton from 'components/_core/LoaderButton';

import { upgradeToAnnual } from 'redux/actions';

const savings = 110;
const annualPrice = 299.99;

const UpgradeSubscription = () => {
    const dispatch = useDispatch();
    const processing = useSelector(state => state.user.upgradeSubscription.processing);
    const error = useSelector(state => state.user.upgradeSubscription.error);
    const subState = useSelector(state => state.user.subscription.state) || {};

    const go = useCallback(() => {
        if (window.confirm(`Nice choice! You’ll be saving up to $${savings} per year. When you confirm, you will be charged $${annualPrice}, and you will lock that price in forever.`)) {
            dispatch(upgradeToAnnual(subState.subscription_id));
        }
    }, [dispatch, subState.subscription_id]);

    if (subState.plan === 'Annual') {
        return null;
    }

    if (error) {
        return <Message error>Error upgrading. Please contact support.</Message>
    }

    return (
        <>
            <LoaderButton disabled={processing} loading={processing} style={{ marginTop: '20px' }} orange onClick={go}>Upgrade to Annual Billing for ${annualPrice} / year</LoaderButton>
        </>
    );
};

export default UpgradeSubscription;
