import styled from 'styled-components';

const Input = styled.input`
    outline: none;
    border-radius: 5px;
    color: #919191;
    
    border: 1px solid #c4c4c4;
    padding: 12px 20px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    
    font-family: ${props => props.theme.alternativeFont};

    display: block;
    width: 100%;

    margin-bottom: 8px;

    &::placeholder {
        color: #b8b8b8;
    }

`;

export default Input;