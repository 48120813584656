import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../_core/Footer';


import { getEnvVar } from 'utils/get-env-var';
import { paramsFromQuerystring } from 'utils/params-from-querystring';
import axios from 'axios';

import LogoCircle from 'components/_core/LogoCircle';
import YouTubeVideoThumb from 'components/Home/VideoThumb';
import ThumbPhoto from 'img/thank-you-thumb.jpg';
import { ButtonLink } from 'components/_core/Button';
import { Testimonial } from 'components/Home/TestimonialGrid';

const stackPoint = 700;

const Container = styled.div`
    background-color: #f0fbfd;
    padding: 100px 15px;


    @media (max-width: ${stackPoint}px) {
        padding: 40px 15px;
    }
`;

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${stackPoint}px) {
        display: block;
    }
`;

const Left = styled.div`
    flex-basis: 50%;
    margin-right: 30px;

    h1, h2 {
        margin: 0;
    }

    h1 {
        font-size: 40px;
        margin-top: 5px;
    }

    h2 {
        // font-weight: normal;
        font-size: 18px;
    }

    p {
        line-height: 1.4em;
    }

    @media (max-width: ${stackPoint}px) {
        margin-right: 0;
        max-width: 500px;

        h1 {
            margin-top: 15px;
        }

        h2 {
            margin-bottom: 10px;
        }
    }
`;
const Right = styled.div`
    flex-basis: 50%;
    
    @media (max-width: ${stackPoint}px) {
        display: none;
    }
    
`;

const GettingStarted = styled.div`
    @media (min-width: ${stackPoint}px) {
        margin-top: -22px;
    }
`;

const StepWrap = styled.div`
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 0;
    margin: 0;
    margin-top: 20px;  
    border: 1px solid #666;

    @media (max-width: ${stackPoint}px) {
        padding: 0;
    }
`;

const Steps = styled.ol`
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;

    list-style: none;
    counter-reset: item;

    @media (max-width: ${stackPoint}px) {
        display: block;
        padding-top: 10px;
    }
`;

const Step = styled.li`
    flex-basis: 33%;
    padding: 0 20px 0 0;

    &:first-child {
        padding-left: 30px;
        flex-basis: 31%;
    }

    &:nth-child(2) {
        flex-basis: 31%;
    }

    &:last-child {
        flex-basis: 38%;
    }

    @media (max-width: ${stackPoint}px) {
        padding: 0 15px 15px 15px !important;

        &:last-child {
            padding-bottom: 0;
        }
    }

    margin: 0;
    display: flex;
    align-items: center;
    font-size: 14px;

    &::before {
        display: block;
        content: counter(item);
        counter-increment: item;

        color: ${props => props.theme.colors.teal};
        font-weight: 900;
        font-size: 75px;
        margin-right: 15px;
    }
`;

const MobileVideo = styled.div`
    display: none;

    @media (max-width: ${stackPoint}px) {
        display: block;
        margin: 30px auto;
    }
`;

const Bottom = styled.div`
    margin: 0 auto 0;
    text-align: center;

    ${Testimonial} {
        margin: 0 auto;
        max-width: 400px;

        p {
            font-size: 14px !important;
        }
    }
`;

const CTA = styled(ButtonLink)`
    text-transform: none;
    font-weight: bold;
    padding: 13px 50px;
    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;

    margin: 20px 0;
`;

const ThankYou = () => {
    useEffect(() => {
        const params = paramsFromQuerystring();

        if (params.checkout_session) {
            axios.post(`${getEnvVar('API_BASE')}/subscribe-after-success`, { id: params.checkout_session })
                .finally(() => window.history.pushState({}, document.title, window.location.pathname));
        } else {
            window.location.href = '/';
        }

    }, []);
 
    return (
        <>
            <Container>
                <Wrapper>
                    <Top>
                        <Left>
                            <LogoCircle size="small" />
                            <h1>Congratulations!</h1>
                            <h2>Your journey to improvisational freedom starts now.</h2>
                            <p>Thank you for trusting me with your drumming education. Let's get you oriented on the website and help you find the right courses to help you <strong><em>FIND YOUR FREEDOM</em></strong>.</p>
                        </Left>
                        <Right>
                            <YouTubeVideoThumb videoId="943260803" thumb={ThumbPhoto} isVimeo smallPlayBtn rounded playBtnText="Play Welcome Video" />
                        </Right>
                    </Top>
                    <GettingStarted>
                        <h2>Getting Started:</h2>
                        <StepWrap>
                            <Steps>
                                <Step>
                                    Watch the 90 second "Welcome Video" on this page.
                                </Step>
                                <Step>
                                    Check your email for the "Website Orientation video."
                                </Step>
                                <Step>
                                    Within 24 hours, you'll get an email from your Method Mentor. This is your personal coach. They'll help you decide which course(s) to start with.
                                </Step>
                            </Steps>
                        </StepWrap>
                    </GettingStarted>
                    
                    <MobileVideo>
                        <YouTubeVideoThumb videoId="943260803" thumb={ThumbPhoto} isVimeo smallPlayBtn rounded playBtnText="Play Welcome Video" />
                    </MobileVideo>

                    <Bottom>
                        <CTA to="/" pill large orange>Enter Website</CTA>
                        <Testimonial>
                            <p>“You and your site have changed my drumming life more than you could imagine. Thank you.”</p>
                            <p className="gray">&mdash; Nathan B.</p>
                        </Testimonial>
                    </Bottom>
                </Wrapper>
            </Container>
            <Footer />
        </>
    );
};

export default ThankYou;
