import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';
import { getCookie } from 'utils/get-cookie';

export const createAccount = user => {
    user.ref_code = getCookie('ref_code');

    return {
        type: ActionTypes.CREATE_ACCOUNT,
        payload: axios.post(`${getEnvVar('API_BASE')}/account`, user)
    }
};
