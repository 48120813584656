import React from 'react';
import styled from 'styled-components';
import CoursesHeroImage from 'img/courses-hero.jpg';
import Hero from 'components/_core/Hero';

const BackgroundImage = styled.div`
    background-size: cover;
    background-image: url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    margin: 0 -20px;
    padding: 0 20px 80px 20px;

`;

const CoursesHero = () => {
    return (
        <BackgroundImage backgroundImage={CoursesHeroImage}>
            <Hero hideCoursesBtn>Courses</Hero>
        </BackgroundImage>
    );
};

export default CoursesHero;