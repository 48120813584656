import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${props => props.theme.backgroundColor};
        margin: 0;
        font-family: ${props => props.theme.bodyFont};
    }

    * {
        box-sizing: border-box;
    }

    p {
        line-height: 1.7em;
    }

    h1, h2, h3, h4 {
        font-family: ${props => props.theme.alternativeFont};
    }
`;

export default GlobalStyle