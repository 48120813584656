import styled from 'styled-components';

import UnderlineButton from 'components/_core/UnderlineButton';
import { ModalWrapper, ModalClose, ModalBody } from 'components/_core/Modal';

export const UserModalWrapper = styled(ModalWrapper)`
    max-width: 500px;
    border-radius: 5px;
    text-align: center;
    color: #919191;
    font-family: ${props => props.theme.alternativeFont};
    font-size: 16px;

    h2 {
        font-weight: 700;
        font-size: 35px;
        margin: 0;
    }

    p {
        margin: 0 0 30px 0;
        font-weight: 400;
    }

    ${UnderlineButton} {
        font-family: ${props => props.theme.alternativeFont};
        color: #919191;
        font-size: inherit;
        font-weight: 900;
    }
`;

export const UserModalCloseBtn = styled(ModalClose)`
    position: absolute;
    top: 5px
    right: 12px;
    z-index: 10;
`;

export const UserModalBodyGray = styled(ModalBody)`
    background-color: #ebebeb;
    border-top: 2px solid #d3d3d3;
    font-size: 14px;
    border-radius: 0 0 5px 5px;
`;
