import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../_core/Button';
import { getEnvVar } from 'utils/get-env-var';

export const BrowseCoursesBtn = props => (
    <Button 
        as={Link} 
        to={`${getEnvVar('ROOT_ROUTE')}/courses`} 
        className="btn"
        {...props}
    >{props.children || 'Browse Courses'}</Button>
);
