import React, { useState, useEffect } from 'react';

const WithScrollPositionWrapper = ({ WrappedComponent, ...props }) => {
    const [position, setPosition] = useState(0);

    useEffect(() => {
        function handleScroll() {
            setPosition(window.scrollY);
        }

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [setPosition]);

    return <WrappedComponent scrollPosition={position} {...props} />;
};

const WithScrollPosition = WrappedComponent => {
    return props => <WithScrollPositionWrapper WrappedComponent={WrappedComponent} {...props} />;
};

export default WithScrollPosition;
