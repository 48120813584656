import { ActionTypes } from 'utils/constants';
import { getEnvVar } from 'utils/get-env-var';

import axios from 'axios';

export const fetchCategories = () => (
    {
        type: ActionTypes.FETCH_CATEGORIES,
        payload: axios.get(`${getEnvVar('API_BASE')}/categories`)
    }
);
