import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { PLAN_MAPPING, PLAN_PRICING, TRIAL_DAYS } from 'utils/constants';
import Button from 'components/_core/Button';
import UnderlineButton from 'components/_core/UnderlineButton';
import { ModalBody } from 'components/_core/Modal';
import { UserModalBodyGray } from 'components/_core/UserModal';
import { setJoinPlan } from 'redux/actions';
import { fetchSales } from 'redux/actions/sales';

const PlansWrap = styled.div`
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
`;

const Plan = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 40px 30px 20px 30px;
    position: relative;

    &:first-child {
        border-right: 2px solid #d3d3d3;
    }

    @media screen and (max-width: 550px) {
        flex-basis: 100%;
        padding: 20px 30px;

        &:first-child {
            border-right: 0;
            border-bottom: 2px solid #d3d3d3;
        }
    }
`;

const PlanDescription = styled.div`
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const PlanPricing = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
        margin: 0 !important;

        &:last-child {
            margin-bottom: 5px !important;
        }

        text-decoration: line-through;
        font-size: 14px;
        line-height: 1.4em;

        &.active {
            text-decoration: none;
            font-weight: 500;
            font-size: 18px;
        }

        &.charge-amount {
            text-decoration: none;
            font-size: 12px;
            margin-top: 10px !important;
            margin-bottom: 5px !important;
            // font-style: italic;
        }
    }
`;


const PlanAction = styled.div`

    ${Button} {
        margin-bottom: 5px;
    }

    p {
        font-size: 12px;
        font-weight: 300 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        &.login {
            font-size: 14px;
            margin-top: 10px !important;
        }
    }
`;

const Discount = styled.div`
    background-color: #47b74c;
    padding: 6px 20px;
    border-radius: 0 0 5px 5px;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    width: 145px;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
`;

const PlanUserModalBodyGray = styled(UserModalBodyGray)`
    padding: 30px 35px !important;
    border-radius: 0 0 5px 5px;

    p {
        margin: 0 !important;
    }

    @media screen and (max-width: 500px) {
        padding: 20px 35px !important;
    }
`;

const List = styled.ul`
    list-style: none;
    text-align: left;
    margin: 0 auto;
    max-width: 57   0px;
    display: flex;
    flex-flow: row wrap;

    background-color: #fff;
    border-radius: 20px;
    padding: 15px;
    border: 3px solid #47b74c;

    @media screen and (max-width: 500px) {
        display: block;
    }
`;

const ListItem = styled.li`
    padding-left: 28px;
    margin: 5px 0;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;

    position: relative;

    &::before {
        content: '';
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: #47b74c;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    &::after {
        content: '';
        height: 4px;
        width: 9px;
        margin-left: 4px;
        margin-top: -1px;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%) rotate(-45deg);
    }
`;

const Plans = (
    {
        goToLogin,
        loggedIn
    }
) => {
    const dispatch = useDispatch();
    const nextScreen = loggedIn ? 'subscribe' : 'create-account';
    const sale = useSelector(state => state.sales.sale);
    const saleFetched = useSelector(state => state.sales.fetched);

    useEffect(() => {
        if (!saleFetched) {
            dispatch(fetchSales());
        }
    }, [dispatch, saleFetched]);

    const pricing = useMemo(() => {
        return {
            monthly: PLAN_PRICING['monthly'],
            monthly_sale: sale?.monthly_sale_price,
            annual: PLAN_PRICING['annual'],
            annual_sale: sale?.annual_sale_price,
            annual_by_month: (PLAN_PRICING['annual'] / 12).toFixed(2),
            annual_sale_by_month: (sale?.annual_sale_price / 12).toFixed(2),
            annual_savings: Math.round(((sale?.monthly_sale_price || PLAN_PRICING['monthly']) * 12) - (sale?.annual_sale_price || PLAN_PRICING['annual']))
        };
        
    }, [sale]);

    return (
        <>
            <ModalBody>
                <PlansWrap>
                    <Plan>
                        <PlanDescription>
                            <h2>{PLAN_MAPPING['monthly']}</h2>
                            <PlanPricing>
                                <p className={!pricing.monthly_sale && 'active'}>
                                    {TRIAL_DAYS > 0 && `${TRIAL_DAYS}-day free trial then `}
                                    ${pricing.monthly}/month
                                </p>

                                {pricing.monthly_sale > 0 &&
                                    <p className="active">
                                        {TRIAL_DAYS > 0 && `${TRIAL_DAYS}-day free trial then `}
                                        ${pricing.monthly_sale}/month
                                    </p>
                                }

                                <p className="charge-amount">Charged ${pricing.monthly_sale || pricing.monthly} per month</p>
                            </PlanPricing>
                        </PlanDescription>
                        <PlanAction>
                            <Button fluid orange bold onClick={() => dispatch(setJoinPlan({ plan: 'monthly', nextScreen }))}>Sign Up Now</Button>
                            <p>100% money back guarantee</p>
                            {!loggedIn && <p className="login">Already have an account? <UnderlineButton onClick={() => goToLogin()}>Login</UnderlineButton></p>}
                        </PlanAction>
                    </Plan>
                    <Plan>
                        <Discount>Save ${pricing.annual_savings}/year</Discount>
                        <PlanDescription>

                            <h2>{PLAN_MAPPING['annual']}</h2>
                            <PlanPricing>
                                <p className={!pricing.annual_sale && 'active'}>
                                    {TRIAL_DAYS > 0 && `${TRIAL_DAYS}-day free trial then `}
                                    ${pricing.annual_by_month}/month
                                </p>

                                {pricing.annual_sale > 0 &&
                                    <p className="active">
                                        {TRIAL_DAYS > 0 && `${TRIAL_DAYS}-day free trial then `}
                                        ${pricing.annual_sale_by_month}/month
                                    </p>
                                }
                                <p className="charge-amount">Charged ${pricing.annual_sale || pricing.annual} per year</p>
                            </PlanPricing>
                        </PlanDescription>
                        <PlanAction>
                            <Button fluid orange bold onClick={() => dispatch(setJoinPlan({ plan: 'annual', nextScreen }))} style={{ marginTop: 0 }}>Sign Up Now</Button>
                            <p>100% money back guarantee</p>
                            {!loggedIn && <p className="login">Already have an account? <UnderlineButton onClick={() => goToLogin()}>Login</UnderlineButton></p>}

                        </PlanAction>
                    </Plan>
                </PlansWrap>
            </ModalBody>
            <PlanUserModalBodyGray>
                <List>
                    <ListItem>In-Depth Courses</ListItem>
                    <ListItem>Personalized Feedback</ListItem>
                    <ListItem>Priority Email Access to JP</ListItem>
                    <ListItem>Exclusive Student Community</ListItem>
                    <ListItem>Play Alongs</ListItem>
                    <ListItem>Worldclass Guest Teachers</ListItem>
                    <ListItem>Course Challenges and Giveaways</ListItem>
                    <ListItem>Downloadable Lessons</ListItem>
                    <ListItem>Live Group Sessions</ListItem>
                    <ListItem>Subscriber-Only AMA's</ListItem>
                </List>
            </PlanUserModalBodyGray>
        </>
    );

};

Plans.displayName = 'Plans';

export default Plans;
