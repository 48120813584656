import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from 'img/landingpage/logo.png';

const Logo = styled.img`
    width: 200px;
`;

const Container = styled.div`
    background-color: #fff;
    margin: 0;
    padding: 30px 0;
`;

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
`;

const Title = styled.div`
    padding: 30px 15px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    h1 {
        font-size: 5rem;
        margin: 0;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 1.2em;
    }

    @media screen and (max-width: 950px) {
        h1 {
            font-size: 4rem;
        }
    
    }

    @media screen and (max-width: 700px) {
        h1 {
            font-size: 3rem;
        }
    }

    @media screen and (max-width: 450px) {
        h1 {
            font-size: 2.3rem;
        }
    }
`;

const SubTitle = styled.div`
    max-width: 500px;
    margin: 0 auto;

    h2 {
        font-size: 1.8rem;
        font-weight: 400;
        margin: 0;
        font-family: 'Times New Roman', Times, serif;
        line-height: 1.3em;

        em {
            font-weight: 100;
        }
    }
`;


const BookHeader = () => {
    return (
        <Container>
            <Wrapper>
                <Link to="/"><Logo src={logo} alt="JP Bouvet Method Logo" /></Link>
                <Title>
                    <h1>On Drumming</h1>
                    <SubTitle>
                        <h2><em>The</em> PSYCHOLOGY <em>and</em> PHILOSOPHY <em>of</em> IMPROVISATION</h2>
                    </SubTitle>
                </Title>
                
            </Wrapper>
        </Container>
    );
};

export default BookHeader;
