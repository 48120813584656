import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { getEnvVar } from 'utils/get-env-var';

import InlineLoader from 'components/_core/InlineLoader';
import { ModalBody } from 'components/_core/Modal';
import Axios from 'axios';
import Message from 'components/_core/Message';

const Subscribe = () => {
    const plan = useSelector(state => state.user.join.plan);
    const impactClickId = useSelector(state => state.impact.clickId);
    const [error, setError] = useState();

    useEffect(() => {
        async function redirect() {
            try {
                console.log('Initiating checkout session');
                const checkoutResp = await Axios.get(`${getEnvVar('API_BASE')}/checkout-id?plan=${plan}&clickId=${impactClickId}`);
                console.log('Session initiated');
                const sessionId = checkoutResp.data.sessionId;
                console.log('Initiating stripe');
                const stripe = await loadStripe(getEnvVar('STRIPE_PUBLIC_KEY'));
                console.log('Redirecting to checkout');
                stripe.redirectToCheckout({ sessionId });
            } catch(err) {
                setError(err?.response?.data?.msg || 'An unexpected error occured');
            }
        }

        redirect();
    }, [plan, setError, impactClickId]);

    if (error) {
        return <ModalBody><Message heading="An error occured" error small>{error}</Message></ModalBody>;
    }

    return <ModalBody><InlineLoader dark text="Redirecting to Checkout" /></ModalBody>;
};

Subscribe.displayName = 'Subscribe';

export default Subscribe;
