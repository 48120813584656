import React from "react";
import { Modal, ModalBody } from "components/_core/Modal";
import { UserModalWrapper, UserModalCloseBtn } from "components/_core/UserModal";
import styled from "styled-components";
import Button, { ButtonA } from "components/_core/Button";
import UnderlineButton from 'components/_core/UnderlineButton';

const CancelModalWrapper = styled(UserModalWrapper)`
    color: #555;
    text-align: center;
    max-width: 450px;

    h2 {
        font-size: 25px;
    }

    .sub-head {
        font-size: 13px;
        color: #999;
    }
`;

const Reasons = styled.div`
    counter-reset: reasons;
`;

const Reason = styled.div`
    &::before {
        content: counter(reasons) '.';
        font-weight: bold;
        margin-right: 5px;
    }

    counter-increment: reasons;

    margin: 10px 0;
    font-size: 15px;
`;

const BailBtns = styled.div`
    margin: 20px 0;
`;


const BailBtn = styled.div`
    margin: 15px 0;

    span {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        font-style: italic;
        color: #999;
    }
`;

const ConfirmBtn = styled(UnderlineButton)`
`;

const FINANCIAL_SUBJECT = encodeURIComponent('Request to Skip Next Payment');
const FINANCIAL_BODY = encodeURIComponent('Dear JP,\n\nFor financial reasons, I would like to request to skip my next monthly payment.');
const STUCK_SUBJECT = encodeURIComponent('Seeking Guidance');
const STUCK_BODY = encodeURIComponent("Dear JP,\n\nI'm feeling a bit stuck or unsure where to turn next on the site. Could I get your advice?\n\n(Feel free to provide more detail).");

const CancelModal = ({ open, close, confirm }) => (
    <Modal open={open}>
        <CancelModalWrapper>
            <ModalBody>
                <UserModalCloseBtn onClick={close}>&times;</UserModalCloseBtn>
                <h2>Cancel Subscription</h2>
                <p className="sub-head">You are about to cancel your subscription.</p>
                <p><strong>You will lose access to:</strong></p>
                <Reasons>
                    <Reason>JPBouvetMethod Courses, Masterclasses, and Play Alongs </Reason>
                    <Reason>Your uploaded Progress Posts</Reason>
                    <Reason>Your personalized feedback videos</Reason>
                    <Reason>Access to the Improvisers Club Facebook Group</Reason>
                    <Reason>Priority email access to JP for advice</Reason>
                    <Reason>End-of-Course Challenges</Reason>
                    <Reason>Subscriber-only Zoom hangs and AMAs</Reason>
                    <Reason>Subscriber-exclusive deals at the Secret Store</Reason>
                </Reasons>
                <BailBtns>
                    <BailBtn>
                        <span>Is it financial?</span>
                        <ButtonA color="teal" href={`mailto:jp@jpbouvetmethod.com?subject=${FINANCIAL_SUBJECT}&body=${FINANCIAL_BODY}`}>Ask to skip next payment</ButtonA>
                    </BailBtn>
                    <BailBtn>
                        <span>Feeling lost or stuck?</span>
                        <ButtonA color="teal" href={`mailto:jp@jpbouvetmethod.com?subject=${STUCK_SUBJECT}&body=${STUCK_BODY}`}>Ask JP for guidance</ButtonA>

                    </BailBtn>
                    <BailBtn>
                        <span>Never mind!</span>
                        <Button color="orange" onClick={close}>Keep my subscription</Button>
                    </BailBtn>
                </BailBtns>
                <ConfirmBtn onClick={confirm}>Confirm subscription cancelation</ConfirmBtn>
            </ModalBody>
        </CancelModalWrapper>
    </Modal>
);

export default CancelModal;
