import { createSelector } from 'reselect';

const videoId = (state, ownProps) => ownProps.videoId;
const completeVideos = state => state.currentCourse.completeVideos.ids;

export const isVideoComplete = createSelector(
    [videoId, completeVideos],
    (id, completeIds) => {
        return completeIds.has(id);
    }
);
