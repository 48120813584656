import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Vimeo from '@vimeo/player';

import { getDeepProp } from 'utils/get-deep-prop';
import Button from 'components/_core/Button';

import { setCurrentVideo, setVideoPosition } from 'redux/actions';
import { nextVideoOfCourse } from 'redux/selectors/next-video-of-course';

const Stage = styled.div`
    background-color: #000;
    width: 100%;

    @media screen and (max-width: 450px) and (min-height: 600px) {
        position: fixed;
        z-index: 9;
    }
`;

const FixedStagePlaceholder = styled.div`
    @media screen and (max-width: 450px) and (min-height: 600px) {
        width: 100%;
        height: auto;

        padding-top: 56.25%;
    }
`;

const VideoWrap = styled.div`
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
`;

const Video = styled.div`
    width: 100%;
    height: auto;

    padding-top: 56.25%;

    ${props => {
        if (props.outlineOpen) {
            return `
                @media screen and (max-width: 1000px) {    
                    pointer-events: none;
                }
            `;
        }
    }}

    iframe { 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const NextVideo = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
`;

const CourseStage = ({ course }) => {
    const dispatch = useDispatch();

    const outlineOpen = useSelector(state => state.currentCourse.courseOutline.open);
    const isPreview = useSelector(state => state.currentCourse.currentVideo.isPreview);
    const currentVideoId = useSelector(state => state.currentCourse.currentVideo.videoId);
    const currentPosition = useSelector(state => getDeepProp(state, 'currentCourse', 'courseVideoPositions', 'positions', state.currentCourse.currentVideo.id)) || 0;    
    const nextVideo = useSelector(state => nextVideoOfCourse(state, { course }));
    const [showNextVideoBtn, setShowNextVideoBtn] = useState(false);
    const [player, setPlayer] = useState();
    
    useLayoutEffect(() => {

        if (player || !currentVideoId) {
            return;
        }
       
        const ele = document.getElementById('video-player');
        
        const opts = {
            width: '100%',
            height: '100%',
            autoplay: !isPreview,
            id: currentVideoId
        };

        const newPlayer = new Vimeo(ele, opts);
        
        newPlayer.on('timeupdate', e => {
            dispatch(setVideoPosition(e.percent));
        });
        
        newPlayer.on('ended', () => {
            // On video end show next video
            dispatch(setVideoPosition(1));
            setShowNextVideoBtn(true);
        });


        setPlayer(newPlayer);
        
    }, [player, isPreview, currentVideoId, dispatch, setShowNextVideoBtn]);

    const handleKeyPress = useCallback(e => {
        // if spacebar is pressed toggle playback state
        if (e.keyCode !== 32 || !player) return;

        e.preventDefault();

        (async () => {
            if (await player.getPaused()) {
                player.play();
            } else {
                player.pause();
            }
        })();

    }, [player]);
    
    useEffect(() => {
        window.addEventListener('keyup', handleKeyPress);
    
        return () => {
          window.removeEventListener('keyup', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {

        (async () => {
            if (!player) {
                return;
            }

            const playingVideoId = await player.getVideoId();

            if (playingVideoId !== +currentVideoId) {
                await player.loadVideo(currentVideoId);
                player.play();
                
                if (currentPosition && currentPosition < 0.95 && currentPosition > 0.05) {
                    // currentPosition is a float between 0 and 1
                    // setCurrentTime accepts time in seconds so we need to
                    // get the video duration and multiply it by the position
                    const duration = await player.getDuration();
                    player.setCurrentTime(duration * currentPosition); // accepts seconds
                }
                
                setShowNextVideoBtn(false);
            }
        })();

    }, [player, currentVideoId, currentPosition, setShowNextVideoBtn]);

    return (
        <>
            <Stage>
                <VideoWrap>
                    <Video outlineOpen={outlineOpen} id="video-player" />
                    {nextVideo && showNextVideoBtn && <NextVideo><Button onClick={() => dispatch(setCurrentVideo(nextVideo))} style={{ padding: '15px 30px' }} lightGray>Next Lesson</Button></NextVideo>}
                </VideoWrap>
            </Stage>
            <FixedStagePlaceholder />
        </>
    );
    
};

CourseStage.displayName = 'CourseStage';

export default CourseStage;
