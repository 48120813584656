import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoaderButton from 'components/_core/LoaderButton';
import Input from 'components/_core/Input';
import PasswordInput from 'components/_core/PasswordInput';
import UnderlineButton from 'components/_core/UnderlineButton';
import { ModalBody } from 'components/_core/Modal';
import { UserModalBodyGray } from 'components/_core/UserModal';
import Message from 'components/_core/Message';
import { createAccount, setEmailForImpact } from 'redux/actions';

const CreateAccount = (
    {
        goToLogin,
        setJoinModalScreen,
        loggedIn
    }
) => {
    const dispatch = useDispatch();
    const processing = useSelector(state => state.user.createAccount.processing);
    const backendError = useSelector(state => state.user.createAccount.error);
    const success = useSelector(state => state.user.createAccount.success);

    const [user, setUser] = useState({});
    const [validationError, setValidationError] = useState();

    const setUserProp = (key, e) => {
        const newUser = {
            ...user,
            [key]: e.target.value
        };
        setUser(newUser);
    };

    const blurEmail = e => {
        dispatch(setEmailForImpact(e.target.value));
    };

    const submit = e => {
        e.preventDefault();
        let goodToGo = true;

        const requiredProps = ['first_name', 'last_name', 'email', 'password'];
        requiredProps.forEach(prop => {
            const value = user[prop] || '';
            if (value.trim() === '') {
                goodToGo = false;
                setValidationError('Please fill out all fields');
            }
        });

        if (!goodToGo) {
            return;
        }

        const emailformat = /^("(?:[!#-[\]-\u{10FFFF}]|\\[\t -\u{10FFFF}])*"|[!#-'*+\-/-9=?A-Z^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z^-\u{10FFFF}])*)@([!#-'*+\-/-9=?A-Z^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z^-\u{10FFFF}])*|\[[!-Z^-\u{10FFFF}]*\])$/u;
        if (!emailformat.test(user.email)) {
            setValidationError('Please enter a valid email address');
            return;
        }
        
        const strongPassFormat = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/;
        if (!strongPassFormat.test(user.password)) {
            setValidationError(
                <>
                    <p>Your password must...</p>
                    <ul>
                        <li>be at least 8 characters long</li>
                        <li>contain at least one number</li>
                        <li>contain at least one uppercase letter</li>
                        <li>contain at least one lowercase letter</li>
                        <li>contain at least one special character</li>
                        <li>contain no whitespace</li>
                    </ul>
                </>
            );

            return;
        }

        setValidationError(null);
        dispatch(createAccount(user));

        return false;
    };

    return (
        <>
            <ModalBody>
                <h2>Welcome!</h2>
                <p>Create an account to continue</p>
                {(validationError || backendError) && <Message error small style={{ textAlign: 'left' }}>{validationError || backendError}</Message>}
                {success && <Message success small style={{ textAlign: 'left' }}>Account created!</Message>}
                <form onSubmit={submit}>
                    <Input 
                        name="first_name"
                        type="text" 
                        placeholder="Your first name" 
                        disabled={processing}
                        onChange={e => setUserProp('first_name', e)} />
                    <Input 
                        name="last_name"
                        type="text" 
                        placeholder="Your last name" 
                        disabled={processing}
                        onChange={e => setUserProp('last_name', e)} />
                    <Input 
                        name="email"
                        type="text" 
                        placeholder="Your email address"  
                        disabled={processing}
                        onBlur={blurEmail}
                        onChange={e => setUserProp('email', e)} />
                    <PasswordInput 
                        name="password"
                        placeholder="Create a password"  
                        disabled={processing}
                        onChange={e => setUserProp('password', e)} />
                    <LoaderButton orange fluid bold disabled={processing} loading={processing}>Continue</LoaderButton>
                </form>
                <UnderlineButton style={{ fontWeight: 300, marginTop: '10px' }} onClick={() => setJoinModalScreen('plans')}>Back</UnderlineButton>
            </ModalBody>
            {!loggedIn && <UserModalBodyGray>
                Already have an account? <UnderlineButton onClick={() => goToLogin()}>Login</UnderlineButton>.
            </UserModalBodyGray>}
        </>
    );

};

CreateAccount.displayName = 'CreateAccount';

export default CreateAccount;
