import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveProfile, setProfileEditingSection } from 'redux/actions';

import { Field, FieldWrap, FieldLabel, FieldValue, EditButton } from './field-ui';

const ProfileField = (
    {
        name,
        label,
        value: inputValue,
        Form,
        checkIfValidationError
    }
) => {
    const dispatch = useDispatch();
    const value = useSelector(state => state.user.userData[name]);
    const editingSection = useSelector(state => state.user.editProfile.editingSection);

    const setEditing = s => dispatch(setProfileEditingSection(s));

    if (editingSection === name) {
        return (
            <Field>
                <FieldWrap>
                    <FieldLabel>{label}</FieldLabel>
                    <Form 
                        value={value} 
                        cancel={() => setEditing(null)} 
                        save={(newProps) => dispatch(saveProfile(newProps))}
                        checkIfValidationError={checkIfValidationError}
                    />
                </FieldWrap>
            </Field>
        );
    }

    return (
        <Field>
            <FieldWrap>
                <FieldLabel>{label}</FieldLabel>
                <FieldValue>{inputValue || value}</FieldValue>
            </FieldWrap>
            <EditButton small onClick={() => setEditing(name)}>Update</EditButton>
        </Field>
    )
};

export default ProfileField;
