import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = {};

export const resetPassword = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.VALIDATE_RESET_PASSWORD_TOKEN + '_PENDING': {
            return {
                ...state,
                validatingToken: true
            };
        }
        case ActionTypes.VALIDATE_RESET_PASSWORD_TOKEN + '_REJECTED': {
            const tokenError = getDeepProp(action, 'payload', 'response', 'data', 'msg');

            return {
                ...state,
                tokenError
            };
        }
        case ActionTypes.VALIDATE_RESET_PASSWORD_TOKEN + '_FULFILLED': {
            return {
                ...state,
                tokenValid: true,
                validatingToken: false
            };
        }
        case ActionTypes.RESET_PASSWORD + '_PENDING': {
            return {
                ...state,
                processing: true,
                error: null,
                success: null
            };
        }
        case ActionTypes.RESET_PASSWORD + '_REJECTED': {
            const error = getDeepProp(action, 'payload', 'response', 'data', 'msg');

            return {
                ...state,
                error,
                processing: false
            };
        }
        case ActionTypes.RESET_PASSWORD + '_FULFILLED': {
            return {
                ...state,
                processing: false,
                success: true,
                error: null
            };
        }
        default: {
            return state;
        }
    }
};
