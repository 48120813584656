import React from 'react';

import { ListView } from 'components/_core/ListView';
import CourseListItem from 'components/_core/CourseListItem';

const CourseList = props => {
    return (
        <ListView>
            {props.courses.map((c, i) => (
                <CourseListItem key={i} {...c} />
            ))}
        </ListView>
    )
};

export default CourseList;