import { ActionTypes } from 'utils/constants';
const initialState = {};

export const courseModal = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.OPEN_COURSE_MODAL: {
            return {
                ...state,
                open: true,
                slug: action.payload.slug,
                name: action.payload.name
            }
        }
        case ActionTypes.CLOSE_COURSE_MODAL: {
            return {};
        }
        case ActionTypes.PLAY_COURSE_PREVIEW: {
            return {
                ...state,
                playingPreview: true
            };
        }
        case ActionTypes.STOP_COURSE_PREVIEW: {
            return {
                ...state,
                playingPreview: false
            };
        }
        case ActionTypes.FETCH_MODAL_COURSE + '_PENDING': {
            return {
                ...state,
                fetchingCourse: true
            };
        }
        case ActionTypes.FETCH_MODAL_COURSE + '_FULFILLED': {
            return {
                ...state,
                fetchingCourse: false,
                fetchedCourse: true,
                modalCourse: action.payload.data.course
            };
        }
        case ActionTypes.FETCH_MODAL_COURSE + '_REJECTED': {
            return {
                ...state,
                fetchingCourse: false,
                fetchedCourse: true,
                courseError: true
            };
        }
        default: {
            return state;
        }
    }
};