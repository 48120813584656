import React from 'react';
import AccountMenuItem from 'components/AccountMenuItem';
import LoginMenuItem from 'components/LoginMenuItem';

import WithScreenWidth from 'components/hoc/screen-width-provider';
import Wrapper from '../_core/Wrapper';
import Header from '../_core/Header';
import Hero from './Hero';
import Container from '../_core/Container';
import Footer from '../_core/Footer';
import CourseModal from '../Courses/CourseModal';
import CurriculumFetcher from './CurriculumFetcher';
import CurriculumGrid from './CurriculumGrid';
import MobileView from './MobileView';

const navItems = [
    () => <AccountMenuItem />,
    () => <li><LoginMenuItem /></li>
];

const Curriculum = ({ windowWidth }) => (
    <>
        <Wrapper style={{ marginBottom: '60px' }}>
            <Header hideLogoUntilScroll navItems={navItems} />
            <Hero />
            <Container>
                <CurriculumFetcher>
                    {windowWidth > 900 ? <CurriculumGrid /> : <MobileView />}
                </CurriculumFetcher>
            </Container>
        </Wrapper>
        <Footer />
        <CourseModal />
    </>
);

export default WithScreenWidth(Curriculum);
