import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ListView, ListViewItem } from 'components/_core/ListView';
import FavoritesFetcher from './FavoritesFetcher';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvVar } from 'utils/get-env-var';
import { forceCurrentVideoForNextLoad } from 'redux/actions';

const FavoriteListViewItem = styled(ListViewItem)`
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;

    cursor: pointer;

    & > * {
        margin-right: 10px;
    }

    .course-name {
        font-size: 13px;
        color: #777;
    }

    &:hover {
        background-color: #333;
    }

    @media screen and (max-width: 550px) {
        display: block;
        position: relative;

        span {
            display: block;
        }

        .video-name {
            margin-bottom: 5px;
        }
    }
`;

const NoFavorites = styled.div`
    text-align: center;
    color: #666;
    font-size: 25px;
    margin-top: 30px;
`;

const Favorites = () => {
    const dispatch = useDispatch();
    const favorites = useSelector(state => state.user.myFavorites.favorites) || [];

    return (
        <FavoritesFetcher>
            <ListView>
                {!favorites.length && <NoFavorites>You haven't favorited any lessons yet</NoFavorites>}
                {favorites.map((video, i) => (
                    <FavoriteListViewItem 
                        key={`favorite-${video.video_id}`} 
                        as={Link} 
                        onClick={() => dispatch(forceCurrentVideoForNextLoad(video.video_id))}
                        to={`${getEnvVar('ROOT_ROUTE')}/course/${video.course_slug}`}
                    >
                        <span className="video-name">{video.video_name}</span>
                        <span className="course-name">{video.course_name}</span>
                    </FavoriteListViewItem>
                ))}
            </ListView>
        </FavoritesFetcher>
        
    )
};

export default Favorites;