import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    position: relative;
    display: block;
    cursor: pointer;

    ${props => {
        if (props.disabled) {
            return `
                opacity: 0.6;
                cursor: default;
            `;
        }
    }}
`;

const Label = styled.label`
    margin: 0;
    user-select: none;
    position: relative;
    display: block;
    padding-top: 3px;
    padding-right: 6px;
    padding-bottom: 3px;
    padding-left: 28px;
    min-width: 19px;
    min-height: 19px;

    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 19px;
        height: 19px;
        transform: translateY(-50%);
        background-color: #fff;
        
        border: 1px solid #000;
        cursor: pointer;
    }
`;

const Check = styled.div`
    position: absolute;
    top: 50%;
    left: 0;

    width: 19px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;

    color: #000;
    transform: translateY(-50%) scale(0.7);
    opacity: 0;

    ${props => {
        if (props.checked) {
            return `
                transform: translateY(-50%) scale(1);
                opacity: 1;
            `;
        }

    }}
`;

const Spinner = styled.div`
    width: 34px;
    text-align: left;
    line-height: 20px;
    font-size: 16px;

    color: #fff;
`;

export default props => {
    const { checked, label, processing, disabled, onChange, style } = props;

    if (processing) {
        return (
            <Wrapper>
                <Spinner><FontAwesomeIcon spin={true} icon={faSpinner} /></Spinner>
            </Wrapper>
        );
    }

    const handleClick = e => { 
        e.stopPropagation(); 

        if (!disabled) {
            onChange(!checked); 
        }
    };

    return (
        <Wrapper disabled={disabled} onClick={handleClick} style={style}>
            <Label htmlFor="check1">{label}</Label>
            <Check checked={checked}><FontAwesomeIcon icon={faCheck} /></Check>
        </Wrapper>
    );
};
