import { ActionTypes } from 'utils/constants';

export const toggleJoinModal = () => {
    return (dispatch, getState) => {
        const state = getState();
        const open = state.user.join.modalOpen;
        
        document.body.style.overflow = open ? 'visible' : 'hidden';

        if (open) {
            // wait a second before resetting the modal
            setTimeout(() => {
                dispatch({
                    type: ActionTypes.SET_JOIN_MODAL_SCREEN,
                    payload: 'plans'
                });
            }, 1000);
        }

        dispatch({
            type: ActionTypes.TOGGLE_JOIN_MODAL
        });
    };
};

export const setJoinModalScreen = screen => (
    {
        type: ActionTypes.SET_JOIN_MODAL_SCREEN,
        payload: screen
    }
);

export const setJoinPlan = payload => (
    {
        type: ActionTypes.SET_JOIN_PLAN,
        payload
    }
);
