import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InlineLoader from 'components/_core/InlineLoader';
import Message from 'components/_core/Message';
import { fetchMyProgressPosts } from 'redux/actions';

const ProgressPostsFetcher = (
    {
        children
    }
) => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.user.myProgressPosts.fetched);
    const error = useSelector(state => state.user.myProgressPosts.error);

    useEffect(() => {
        dispatch(fetchMyProgressPosts());
    }, [dispatch])

    if (error) {
        return (
            <Message heading="Error loading courses" error noIcon small>
                Refresh the page to try again.
            </Message>
        );
    }

    if (!fetched) {
        return <InlineLoader />;
    }

    return children;
};

export default ProgressPostsFetcher;
