import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'components/_core/Select';
import { setFilter } from 'redux/actions';
import styled from 'styled-components';

const Wrap = styled.div`
    @media screen and (max-width: 750px) {
        flex: 150px 0 0;
    }
`;

const Sort = () => {
    const dispatch = useDispatch();
    const value = useSelector(state => state.courseList.filters.sort);

    const sortOpts = [
        { value: '-live_after', label: 'Newest' },
        { value: 'live_after', label: 'Oldest' },
        { value: 'name', label: 'A-Z' },
        { value: '-name', label: 'Z-A' } 
    ];

    return (
        <Wrap>
            <Select 
                options={sortOpts} 
                placeholder="Sort" 
                width={120} 
                value={value} 
                onChange={val => dispatch(setFilter('sort', val))} />   
        </Wrap>             
    );
};

export default Sort;
