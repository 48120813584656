import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const fetchCourseForModal = slug => (
    {
        type: ActionTypes.FETCH_MODAL_COURSE,
        payload: axios.get(`${getEnvVar('API_BASE')}/course/${slug}`)
    }
);

export const openCourseModal = (slug, name) => {
    document.body.style.overflow = 'hidden';

    return {
        type: ActionTypes.OPEN_COURSE_MODAL,
        payload: { slug, name }
    }
};

export const closeCourseModal = () => {
    document.body.style.overflow = 'visible';

    return {
        type: ActionTypes.CLOSE_COURSE_MODAL
    }
};

export const playCoursePreview = () => (
    {
        type: ActionTypes.PLAY_COURSE_PREVIEW
    }
);

export const stopCoursePreview = () => (
    {
        type: ActionTypes.STOP_COURSE_PREVIEW
    }
);