import React from 'react';
import styled from 'styled-components';

const Switch = styled.button`
    display: flex;
    border: 0;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0; 
    outline: none;
`;
    
const SwitchSide = styled.div`
    color: #fff;
    padding: 10px 20px;
    
    &:first-child {
        border-radius: 5px 0 0 5px;
    }

    &:last-child {
        border-radius: 0 5px 5px 0;
    }

    font-size: 14px;
    background-color: ${props => props.theme.colors.midGray}; 
    
    ${props => {
        if (props.active) {
            return `
                background-color: ${props.theme.colors.lightGray};
            `;
        }
    }}
`;

export default props => {
    const OffLabel = props.OffLabel;
    const OnLabel = props.OnLabel;

    return (
        <Switch onClick={() => props.action(!props.value)}>
            <SwitchSide active={!props.value}><OffLabel /></SwitchSide>
            <SwitchSide active={props.value}><OnLabel /></SwitchSide>
        </Switch>
    );
};
