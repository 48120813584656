import styled from 'styled-components';
import LoaderButton from 'components/_core/LoaderButton';

export const Field = styled.div`
    margin-bottom: 20px;
    padding-bottom: 20px;
    max-width: 400px;
    display: flex;
    align-items: center;

    border-bottom: 1px solid #222;

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
    }
`;

export const FieldWrap = styled.div`
    flex-grow: 1;
`;

export const FieldLabel = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 5px;
`;

export const FieldValue = styled.div``;

export const EditButton = styled(LoaderButton)`
    float: right;
`;
