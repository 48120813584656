import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = { videosProcessing: new Set() };

const favoriteVideosFromCourse = course => {
    const contents = course.contents || [];
    return contents.reduce((a, chapter) => {
        chapter.videos.forEach(video => {
            if (video.favorited) {
                a.add(video.id);
            }
        });

        return a;
    }, new Set());
};

export const favoriteVideos = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_COURSE + '_FULFILLED': {
            const course = getDeepProp(action, 'payload', 'data', 'course') || {};
            const ids = favoriteVideosFromCourse(course);

            return {
                ...state,
                ids
            };
        }
        case ActionTypes.COPY_MODAL_COURSE: {
            const ids = favoriteVideosFromCourse(action.payload);

            return {
                ...initialState,
                ids
            };
        }
        case ActionTypes.TOGGLE_VIDEO_FAVORITE: {
            const videosProcessing = new Set(state.videosProcessing);
            videosProcessing.add(action.payload.videoId);

            return {
                ...state,
                videosProcessing
            };
        }
        case ActionTypes.TOGGLE_VIDEO_FAVORITE + '_FULFILLED': {
            const videosProcessing = new Set(state.videosProcessing);
            const videoIdComplete = getDeepProp(action, 'payload', 'data', 'video_id')
            videosProcessing.delete(videoIdComplete);
            
            const favorited = getDeepProp(action, 'payload', 'data', 'favorited')
            const ids = new Set(state.ids);

            if (favorited) {
                ids.add(videoIdComplete);
            } else {
                ids.delete(videoIdComplete);
            }
            
            return {
                ...state,
                ids,
                videosProcessing
            };
        }
        default: {
            return state;
        }
    }
};
