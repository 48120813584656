import React from 'react';
import styled from 'styled-components';
import Accordian from 'components/_core/Accordian';

const CourseOutlineWrapper = styled.div`
    background-color: ${props => props.theme.colors.offWhite};
    border-top: 1px solid ${props => props.theme.colors.grayBorder};
    padding: 0 15px;
`;

const ChapterHeader = styled.div`
    padding: 25px 20px;
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.colors.grayBorder};

    h3 {
        color: ${props => props.theme.colors.medGrayText};
        font-weight: bold;
        font-size: 16px;
        margin: 0;
    }

    ${props => {
        if (props.active) {
            return `cursor: default;`;
        }
    }}

    @media screen and (max-width: 500px) {
        padding: 20px 10px;
    }
`;

const VideoList = styled.ul`
    padding: 0 15px 0 0;
    margin: 0;
    list-style: none;
`;

const Video = styled.li`
    color: ${props => props.theme.colors.medGrayText};
    padding: 15px 30px;
    border-bottom: 1px solid ${props => props.theme.colors.grayBorder};

    h3 {
        font-size: 16px;
        margin: 0 0 5px 0;
        font-weight: normal;
    }

    p {
        font-size: 13px;
        margin: 0;
    }

    @media screen and (max-width: 500px) {
        padding: 15px 15px;
    }
`;


const CourseOutline = ({ chapters }) => (
    <CourseOutlineWrapper>
        <Accordian
            internalState={true}
            sections={chapters}
            HeaderComponent={props => <ChapterHeader active={props.active} onClick={() => props.onClick()}><h3>{props.name}</h3></ChapterHeader>}
            ItemsComponent={props => (
                <VideoList>
                    {props.videos.filter(video => video.type === 'video').map((video, i) => <Video key={`video-${i}`}><h3>{video.name}</h3><p dangerouslySetInnerHTML={{ __html: video.description }} /></Video>)}
                </VideoList>
            )}
        />
    </CourseOutlineWrapper>
);

export default CourseOutline;
