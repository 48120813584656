import React from "react";
import styled from "styled-components";
import drumChannel from "img/landingpage/logos/drum-channel.png";
import drumeo from "img/landingpage/logos/drumeo.png";
import dw from "img/landingpage/logos/dw.png";
import gc from "img/landingpage/logos/gc.png";
import meinl from "img/landingpage/logos/meinl.png";
import remo from "img/landingpage/logos/remo.png";
import vai from "img/landingpage/logos/vai.png";
import vf from "img/landingpage/logos/vf.png";

const Container = styled.div`
    background-color: #eee;
    padding: 25px 15px;

    h2 {
        text-align: center;
        font-size: 20px;
        margin: 0;
        color: #999;
        font-weight: 400;
    }
`;

const Logos = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
        margin: 15px;
    }
`;


const SupportedBy = () => {
    return (
        <Container>
            <h2>As seen in | Endorsed by</h2>
            <Logos>
                <img src={gc} width={130} alt="Guitar Center Drum-Off" />
                <img src={drumChannel} width={130} alt="Drum Channel" />
                <img src={drumeo} width={110} alt="Drumeo" />
                <img src={vai} width={100} alt="Steve Vai" />
                <img src={meinl} width={100} alt="Meinl Cymbals" />
                <img src={dw} width={100} alt="DW Drums" />
                <img src={remo} width={100} alt="Remo" />
                <img src={vf} width={100} alt="Vic Firth" />
            </Logos>
        </Container>
    );
};

export default SupportedBy;
