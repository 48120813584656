import React from "react";
import { useSelector } from "react-redux";
import Courses from "./Courses";
import Favorites from "./Favorites";
import MyProgressPosts from "./MyProgressPosts";

const MyLearningView = () => {
    const view = useSelector(state => state.courseList.filters.myLearningView);

    switch(view) {
        case 'favorites':
            return <Favorites />;
        case 'progress-posts':
            return <MyProgressPosts />;
        case 'guest-courses':
            return <Courses type="guest_course" />;
        case 'play-alongs':
            return <Courses type="play_along" showLevels={true} />;
        case 'all': 
            return <Courses />;
        default:
            return <Courses type="course" showSearch={true} showCategories={true} showLevels={true} />;
    }
};

export default MyLearningView;
