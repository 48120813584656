import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getEnvVar } from 'utils/get-env-var';

import { toggleLoginModal } from 'redux/actions';

const Account = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleLoginModal());
    }, [dispatch]);

    return <Redirect to={`${getEnvVar('ROOT_ROUTE')}/`} />; 
};

export default Account;
