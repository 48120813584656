import { createSelector } from 'reselect';

const videoId = state => state.currentCourse.currentVideo.id;
const processingVideos = state => state.currentCourse.favoriteVideos.videosProcessing;

export const isCurrentVideoFavoritenessProcessing = createSelector(
    [videoId, processingVideos],
    (id, processingVideosIds) => {
        return processingVideosIds.has(id);
    }
);
