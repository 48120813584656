import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Message from './_core/Message';
import InlineLoader from './_core/InlineLoader';
import LandingPage from './LandingPage';

import WhiteContainer from './_core/WhiteContainer';
import Header from './_core/Header';
import UnderlineLink from './_core/UnderlineLink';
import LoginMenuItem from 'components/LoginMenuItem';
import { getEnvVar } from 'utils/get-env-var';

import Wrapper from './_core/Wrapper';
import Footer from './_core/Footer';
import PageContent from './PageContent';

const navItems = [
    () => <li className="hide-mobile"><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/courses`}>Courses</UnderlineLink></li>,
    () => <li><LoginMenuItem /></li>
];

const StaticPageContainer = styled(WhiteContainer)`

margin: 100px auto 60px;

h1 {
    margin-top: 0;
}
`;

const PageWrapper = ({ children }) => (
    <>
        <Wrapper>
            <Header navItems={navItems} />
            <StaticPageContainer>
                {children}
            </StaticPageContainer>
        </Wrapper>
        <Footer />
    </>
);

const StaticPage = ({ location: { pathname } }) => {
    const slug = pathname.replace('/', '', pathname);

    const [pageContent, setPageContent] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        axios.get(`${getEnvVar('API_BASE')}/static-page/${slug}`)
            .then(fetchResponse => setPageContent(fetchResponse.data))
            .catch(err => setError(err));
    }, [slug, setPageContent]);  
    
    if (error) {
        return <PageWrapper><Message error>{error.message.includes('404') ? 'Page not found' : 'An unexpected error occured'}</Message></PageWrapper>;
    }

    if (!pageContent) {
        return <PageWrapper><InlineLoader /></PageWrapper>;
    }

    if (pageContent.isLandingPage) {
        return <LandingPage {...pageContent} />;
    }

    return (
        <PageWrapper>
            <PageContent {...pageContent} />
        </PageWrapper>
    );
}

export default StaticPage;
