import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ButtonCreator = (as = 'button') => styled(as).attrs(props => {
    if (!props.color) {
        if (props.orange) {
            props.color = 'orange';
        }

        if (props.teal) {
            props.color = 'teal';
        }

        if (props.lightGray) {
            props.color = 'light-gray';
        }

        if (props.lighterGray) {
            props.color = 'lighter-gray';
        }
    }

    props.weight = props.bold ? '600 !important' : 300;
    props.color = props.color || 'gray';
    props.radius = props.radius || '5px';
    props.padding = props.padding || '12px 30px';
    props.fontSize = props.fontSize || '14px';

    if (props.pill) {
        props.radius = '32px';
    }

    if (props.large) {
        props.fontSize = '17px';
        props.padding = '18px 40px';
    }
    if (props.small) {
        props.fontSize = '12px';
        props.padding = '10px 15px';
    }

    switch(props.color) {
        case 'orange':
            props.backgroundColor = props.theme.colors.orange;
            props.hoverBackgroundColor = '#d26b18';
            props.color = '#fff';
            break;
        case 'teal':
            props.backgroundColor = props.theme.colors.teal;
            props.hoverBackgroundColor = '#52c4c5';
            props.color = '#fff';
            break;

        case 'light-gray': 
            props.backgroundColor = '#555';
            props.hoverBackgroundColor = '#666';
            props.color = '#fff';
            break;
        case 'lighter-gray':
            props.backgroundColor = '#eee';
            props.hoverBackgroundColor = '#ccc';
            break;
        default:
            props.backgroundColor = props.theme.colors.midGray;
            props.hoverBackgroundColor = '#393a3e';
            props.color = '#fff';
            break;
    }
})`
    outline: none;
    border-radius: ${props => props.radius};
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    padding: ${props => props.padding};
    text-align: center;
    font-weight: ${props => props.weight};
    text-transform: uppercase;
    border: none;
    font-size: ${props => props.fontSize};
    text-decoration: none;
    display: inline-block;
    font-family: ${props => props.theme.alternativeFont};

    ${props => {
        if (props.fluid) {
            return `
                display: block;
                width: 100%;
            `;
        }
    }}

    & + button, & + a {
        margin-left: 10px;
    }

    &:hover:not(:disabled) {
        background-color: ${props => props.hoverBackgroundColor};
    }

    &:disabled {
        cursor: normal;
        opacity: 0.5;
    }
`;

export const ButtonA = ButtonCreator('a');
export const ButtonLink = ButtonCreator(Link);
export default ButtonCreator('button');