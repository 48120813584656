import { ActionTypes } from 'utils/constants';
import { toast } from 'react-toastify';

const initialState = {};

export const toastNotifications = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FORGOT_PASSWORD  + '_FULFILLED': {
            toast.info('Reset password link sent');
            return {};
        }
        case ActionTypes.RESET_PASSWORD  + '_FULFILLED': {
            toast.info('Your password has been reset and you are now logged in');
            return {};
        }
        case ActionTypes.CONFIRM_EMAIL  + '_FULFILLED': {
            toast.info('Your email has been confirmed');
            return {};
        }
        case ActionTypes.LOGIN + '_FULFILLED': {
            toast.info('You are now logged in');
            return {};
        }
        default: {
            return state;
        }
    }
};
