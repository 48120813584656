import React from "react";

const NextAMA = ({ date: isoDate }) => {
    if (!isoDate) {
        return null;
    }
    // format as m/d/Y H:i am/pm
    const date = new Date(isoDate).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });

    return (
        <>
            <h3 style={{ marginTop: '30px' }}>Next Subscriber AMA</h3>
            <p>{date}</p>
        </>
    );
};

export default NextAMA;
