import React from 'react';
import styled from 'styled-components';

import WithScreenWidth from 'components/hoc/screen-width-provider';

const TestimonialWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, -50%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
`;

const TestimonalWrap = styled.div`
    flex: 33.333334% 0 0;
    max-width: 450px;

    ${props => {
        if (props.two) {
            return `flex: 50% 0 0;`;
        } else if (props.one) {
            return `flex: 100% 0 0;`;
        }
    }}
`;

const StyledTestimonial = styled.blockquote`
    background-color: ${props => props.theme.colors.teal};
    color: #fff;
    text-align: center;
    padding: 12px 15px;
    font-style: italic;
    font-weight: bold;

    .byline {
        font-weight: normal;
        font-style: normal;
        margin-top: 5px;
        font-size: 0.95em;
        
        &::before {
            content: '-';
            margin-right: 5px;
        }
    }
`;

const Testimonials = ({ windowWidth }) => {
    const quotes = [
        {
            'content': 'A lot of my improvisation concepts came from things I learned from JP.',
            'byline': 'Matt Halpern (Periphery)'
        },
        {
            'content': 'JP is one of the most gifted drum educator out there today.',
            'byline': 'Drumeo'
        },
        {
            'content': 'There\'s not enough I can say about this young man\'s professionalism.',
            'byline': 'Steve Vai'
        }
    ];

    if (windowWidth < 700) {
        return (
            <TestimonialWrapper>
                <TestimonalWrap one><Testimonial quote={quotes[1]} /></TestimonalWrap>
            </TestimonialWrapper>
        );
    }

    if (windowWidth < 1300) {
        return (
            <TestimonialWrapper>
                <TestimonalWrap two><Testimonial quote={quotes[0]} /></TestimonalWrap>
                <TestimonalWrap two><Testimonial quote={quotes[1]} /></TestimonalWrap>
            </TestimonialWrapper>
        );
    }
    return (
        <TestimonialWrapper>
            <TestimonalWrap two><Testimonial quote={quotes[0]} /></TestimonalWrap>
            <TestimonalWrap two><Testimonial quote={quotes[1]} /></TestimonalWrap>
            <TestimonalWrap two><Testimonial quote={quotes[2]} /></TestimonalWrap>
        </TestimonialWrapper>
    );
};

const Testimonial = ({
    quote
}) => (
    <StyledTestimonial>
        &#8220;{quote.content}&#8221;
        <div className="byline">{quote.byline}</div>
    </StyledTestimonial>
);

export default WithScreenWidth(Testimonials);
