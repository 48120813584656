import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ToolTip from 'components/_core/ToolTip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons'

import { toggleCurrentVideoFavorite } from 'redux/actions';
import { isCurrentVideoFavorite } from 'redux/selectors/is-current-video-favorite';
import { isCurrentVideoFavoritenessProcessing } from 'redux/selectors/is-current-video-favoriteness-processing';


const Circle = styled.button`
    position: relative;

    cursor: pointer;

    margin: 0 3px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #333;
    transition: background-color 0.3s;

    border: none;
    outline: none;

    font-size: 18px;

    color: #fff;

    ${props => {
        if (props.favorite) {
            return `color: ${props.theme.colors.orange}`;
        }
    }}
    
    &:hover {
        background-color: #555;

        ${ToolTip} {
            opacity: 1;
        }
    }
`;


const FavoriteBtn = () => {
    const dispatch = useDispatch();
    const favorite = useSelector(isCurrentVideoFavorite);
    const processing = useSelector(isCurrentVideoFavoritenessProcessing);

    const toggleFavorite = () => dispatch(toggleCurrentVideoFavorite(!favorite));
    const icon = processing ? faSpinner : (favorite ? faStar : faStarOutline);

    return (
        <Circle favorite={favorite} onClick={toggleFavorite}>
            <FontAwesomeIcon icon={icon} spin={processing} />
            <ToolTip>{favorite ? 'Remove from' : 'Add to'} Favorites</ToolTip>
        </Circle>
    );
};

export default FavoriteBtn;
