import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'components/_core/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { toggleListView } from 'redux/actions';

const ViewSwitch = () => {
    const dispatch = useDispatch();
    const listViewActive = useSelector(state => state.courseList.filters.listView);

    return (
        <Switch 
            OnLabel={() => <FontAwesomeIcon icon={faList} />} 
            OffLabel={() => <FontAwesomeIcon icon={faThLarge} />} 
            action={() => dispatch(toggleListView())}
            value={listViewActive}
        />
    );
};

export default ViewSwitch;
