import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getEnvVar } from 'utils/get-env-var';

import Button from 'components/_core/Button';
import ProgressCircle from 'components/_core/ProgressCircle';

const ResumeWrap = styled.div`
    border: 2px solid #111;
    border-radius: 10px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    margin: 40px 0;
    text-decoration: none;
    background-color: #111;
    
    ${props => {
        if (props.color === 'light') {
            return `
                background-color: #fff;
                border: none;
                padding: 0;
            `;
        }
    }}
`;

const Details = styled.div`
    margin-left: 20px;
`;

const CourseTitle = styled.h3`
    color: #eee;
    margin: 0 0 10px 0;
    font-size: 16px;

    ${props => {
        if (props.color === 'light') {
            return `
                color: #333;
                font-weight: 400;
            `;
        }
    }}
`;

const ResumeButton = styled(Button)`
    font-size: 12px;
    padding: 8px 12px;
`;

const CourseProgress = ({ course, color = 'dark', style = {} }) => {
    if (!course) {
        return null;
    }
    
    return (
        <ResumeWrap color={color} as={Link} to={`${getEnvVar('ROOT_ROUTE')}/course/${course.slug}`} style={style}>
            <ProgressCircle color={color} className="no-margin" percent={course.percent_complete} />
            <Details>
                <CourseTitle color={color}>{course.name}</CourseTitle>
                <ResumeButton teal>Resume Course</ResumeButton>
            </Details>
        </ResumeWrap>
    );
};

export default CourseProgress;
