
import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

export const currentVideoDetails = store => next => action => {
    if (action.type === ActionTypes.FETCH_COURSE + '_FULFILLED' || action.type === ActionTypes.COPY_MODAL_COURSE) {
        const course = getDeepProp(action, 'payload', 'data', 'course') ||  getDeepProp(action, 'payload') || {};
        const contents = course.contents || [];

        const state = store.getState();
        const currentVideoId = state.currentCourse.currentVideo.overrideWithVideoId || course.current_video;
        
        if (currentVideoId) {
            let videos, video;
            for (let i = 0, e = contents.length; i < e; i++) {
                videos = contents[i].videos;
                for (let j = 0, f = videos.length; j < f; j++) {
                    video = videos[j];
                    if (video.id === currentVideoId) {
                        action.payload.currentVideo = video;
                        action.payload.activeSection = i;
                    }
                }
            }
        }
    }

    next(action);
};
