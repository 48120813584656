import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const LoaderWrap = styled.div`
    color: #fff;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => {
        if (props.dark) {
            return 'color: #4e4e4e;';
        }
    }}
`;

const LoaderText = styled.div`
    margin-left: 15px;
`;

export default ({ text, dark }) => {
    return (
        <LoaderWrap dark={dark}>
            <FontAwesomeIcon icon={faCircleNotch} spin />
            <LoaderText>{text || 'Loading'}</LoaderText>
        </LoaderWrap>
    );
};