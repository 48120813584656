import styled from 'styled-components';

export const GridItem = styled.div`

`;

export const ThreeInLine = styled.div.attrs(props => {
    props.stackPoint = props.stackPoint || '800px';
})`
    display: flex;
    justify-content: space-between;

    margin: 0 -15px;

    ${GridItem} {
        flex-basis: 33.333%;
        padding: 0 15px;
    }

    @media screen and (max-width: ${props => props.stackPoint}) {
        display: block
        margin: 0;

        ${GridItem} {
            padding: 15px 0;
        }
    }
`;

export const FourGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    margin: -15px;

    ${GridItem} {
        flex-basis: 25%;
        padding: 15px;
    }

    @media screen and (max-width: ${props => props.threeBreak || '1000px'}) {
        ${GridItem} {
            flex-basis: 33.33334%;
        }
    }

    @media screen and (max-width: ${props => props.twoBreak || '800px'}) {
        ${GridItem} {
            flex-basis: 50%;
        }
    }

    @media screen and (max-width: ${props => props.stackPoint || '400px'}) {
        display: block;
    }
`;


export const ThreeGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    margin: -15px;

    ${GridItem} {
        flex-basis: 33.333%;
        padding: 15px;
    }

    @media screen and (max-width: ${props => props.twoBreak || '800px'}) {
        ${GridItem} {
            flex-basis: 50%;
        }
    }

    @media screen and (max-width: ${props => props.stackPoint || '400px'}) {
        display: block;
    }
`;


export const TwoGrid = styled.div.attrs(props => props.stackPoint = props.stackPoint || '400px')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: -15px;

    ${GridItem} {
        flex-basis: 50%;
        padding: 15px;
    }

    @media screen and (max-width: ${props => props.stackPoint}) {
        flex-direction: column;

        ${GridItem} {
            flex-basis: 100%;
        }
    }
`;