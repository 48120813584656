import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setImpactClickId } from "redux/actions";

const ImpactClickId = () => {
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);
    const impactEmail = useSelector(state => state.impact.emailSet);

    useEffect(() => {
        if (initialized || !impactEmail) {
            return;
        }

        setInitialized(true);

        window.ire('generateClickId', async (clickId) => {
            dispatch(setImpactClickId(clickId));
        });
    }, [initialized, impactEmail, dispatch]);

    return null;
};

export default ImpactClickId;
