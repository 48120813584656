import { combineReducers } from 'redux';

import { currentVideo } from './current-video';
import { courseDetails } from './course-details';
import { courseOutline } from './course-outline';
import { courseVideoPositions } from './course-video-positions';
import { completeVideos } from './complete-videos';
import { favoriteVideos } from './favorite-videos';

export default combineReducers({
    currentVideo,
    courseDetails,
    courseOutline,
    courseVideoPositions,
    completeVideos,
    favoriteVideos
});
