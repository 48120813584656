import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const confirmEmail = token => (
    {
        type: ActionTypes.CONFIRM_EMAIL,
        payload: axios.post(`${getEnvVar('API_BASE')}/confirm-email`, { token })
    }
);
