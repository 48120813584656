export const currencySymbol = currency => {
    currency = currency.toUpperCase();
    switch (currency) {
        case 'USD':
            return 'USD $';
        case 'AUD':
            return 'AUD $';
        case 'CAD':
            return 'CAD $';
        case 'SGD':
            return 'SGD $';
        case 'GBP':
            return '£';
        case 'EUR':
            return '€';
        default:
            return '';
    }
};
