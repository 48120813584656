import React, { useState } from 'react';
import styled from 'styled-components';

import ToolTip from 'components/_core/ToolTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import getActionsFromVideo from 'utils/get-actions-from-video';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
    position: relative;

    &:hover {
        > ${ToolTip} {
            opacity: 1;
        }
    }

    ${props => {
        if (props.circle) {
            return `
                cursor: pointer;
                margin: 0 3px;

                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 1px;
            
                border-radius: 50%;
                background-color: #333;
                transition: background-color 0.3s;
            
                &:hover {
                    background-color: #555;
                }
            `;
        }
    }}
`;

const Trigger = styled.button`
    cursor: pointer;
    background: none;
    outline: none;
    appearance: none;
    border: none;
    color: #fff;
    position: relative;
`;

const Curtain = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 888;
`;

const MenuItem = styled.button`
    padding: 10px 15px;
    color: #fff;
    display: flex;
    align-items: center;
    
    whitespace: no-wrap;
    cursor: pointer;
    text-decoration: none;
    background: none;
    border: none;
    width: 100%;
    background-color: ${props => props.theme.colors.lightGray};
    position: relative;

    border-bottom: 1px solid #444;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: ${props => props.theme.colors.teal};
        
        ${ToolTip} {
            opacity: 1;
        }
    }

    ${props => {
        if (props.disabled) {
            return `
                color: #999;
                background-color: ${props.theme.colors.lightGray} !important;
                cursor: not-allowed;
            `;
        }
    }}
`;

const Menu = styled.div`
    background-color: ${props => props.theme.colors.lightGray};
    
    position: absolute;
    top: calc(100% + 15px);
    right: -10px;
    z-index: 999;
    width: 220px;

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

    ${MenuItem}:first-child {
        &:after, &:before {
            bottom: 100%;
            right: 10px;

            ${props => {
                if (props.circle) {
                    return `right: 20px`;
                }
            }}
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        
        &:after {
            border-color: rgba(213, 0, 0, 0);
            border-bottom-color: ${props => props.theme.colors.lightGray};
            border-width: 10px;
            margin-left: -10px;
        }
        &:before {
            border-color: rgba(0, 0, 0, 0);
            border-bottom-color: #0;
            border-width: 16px;
            margin-left: -16px;
        }

        &:hover {
            &:after {
                border-bottom-color: ${props => props.theme.colors.teal};
            }
        }
    }
`;

const MenuItemIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
`;

const MenuItemLabel = styled.h4`
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 15px;
`;

const MenuHelpText = styled.p`
    font-size: 10px;
    text-align: left;
    margin: 5px 0;
`;

export default ({ video, course, circle }) => {
    const [open, setOpen] = useState(false);
    const canDownloadVideos = useSelector(state => state.user.downloads.can_download);
    const dispatch = useDispatch();

    if (!video) {
        return null;
    }

    const actions = getActionsFromVideo(video, course, canDownloadVideos) || [];

    if (!actions.length) {
        return null;
    }

    return (
        <Wrapper circle={circle} onClick={() => setOpen(!open)}>
            <Trigger onClick={e => { e.stopPropagation(); setOpen(!open); }}><FontAwesomeIcon icon={circle ? faDownload : faEllipsisV} /></Trigger>
            {circle && !open && <ToolTip direction="right">Downloads</ToolTip>}
            {open && <>
                <Curtain onClick={() => setOpen(false)} />
                <Menu>
                    {actions.map((action, i) => (
                        <>
                            <MenuItem disabled={action.disabled} onClick={() => action.dispatchAction ? dispatch(action.action()) : action.action()} key={`video-${video.id}-action-${i}`}>
                                <MenuItemIcon icon={action.icon} />
                                <MenuItemLabel>
                                    {action.label}
                                    {action.disabled && <MenuHelpText>{action.disabledReason}</MenuHelpText>}
                                </MenuItemLabel>
                            </MenuItem>
                        </>
                    ))}
                </Menu>
            </>}
        </Wrapper>
    );
};