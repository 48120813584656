import React from 'react';
import styled from 'styled-components';

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { GridItem, FourGrid } from 'components/_core/grids';
import YouTubeVideoThumb from './VideoThumb';

const Wrapper = styled(W)`
    background-color: #fff;
`;

const Container = styled(C)`
    padding: 5px 0 30px 0;
`;

const TextHeading = styled.div`
    margin: 0 auto 30px;
    max-width: 655px;
    color: #000;
    text-align: center;

    h2 {
        font-size: 30px;
        margin: 0;
        font-weight: 200;

        strong {
            font-style: italic;
            font-weight: 700;
        }
    }

    p {
        line-height: 1.4em;
        color: #666;
        font-style: italic;
        margin-top: 7px;
    }
`;

const TikTokGrid = styled(FourGrid)`
    margin: -2px;

    ${GridItem} {
        padding: 2px;
    }

    @media screen and (max-width: 1000px) {
        ${GridItem}:nth-child(n+7) {
            display: none;
        }
    }

    @media screen and (max-width: 400px) {
        ${GridItem}:nth-child(n+4) {
            display: none;
        }
    }
`;

const NotEnterainment = () => {
    return (
        <Wrapper>
            <Container>
                <TextHeading>
                    <h2>
                        <strong>Not entertainment.</strong> Real methods that work.
                    </h2>
                    <p>The real fun and fulfillment of learning an instrument comes from developing mastery. In these “What I’m Working On” videos, students explain how the courses are leading to improvisational freedom.</p>
                </TextHeading>

                <TikTokGrid>
                    <GridItem>
                        <YouTubeVideoThumb videoId="QpfEHS1LeCg" ratio={9/16} verticalEmbed />
                    </GridItem>
                    <GridItem>
                        <YouTubeVideoThumb videoId="ZLR_REsqlXg" ratio={9/16} />
                    </GridItem>
                    <GridItem>
                        <YouTubeVideoThumb videoId="5yq0xEcZJDU" ratio={9/16} verticalEmbed />
                    </GridItem>
                    <GridItem>
                        <YouTubeVideoThumb videoId="mq4zYkHAwYs" ratio={9/16} verticalEmbed />
                    </GridItem>
                    <GridItem>
                        <YouTubeVideoThumb videoId="nJJSA1ooGA0" ratio={9/16} verticalEmbed />
                    </GridItem>
                    <GridItem>
                        <YouTubeVideoThumb videoId="KWgE8bAh1Hw" ratio={9/16}  />
                    </GridItem>
                    <GridItem>
                        <YouTubeVideoThumb videoId="VGRAsaYeigo" ratio={9/16}  />
                    </GridItem>
                    <GridItem>
                        <YouTubeVideoThumb videoId="egloB2z2SSY" ratio={9/16}  />
                    </GridItem>
                </TikTokGrid>
            </Container>
        </Wrapper>
    );
};

export default NotEnterainment;
