import React from 'react';
import ProfileField from './ProfileField';

import Form from './Form';
import PasswordInput from 'components/_core/PasswordInput';
import BillingInfo from './BillingInfo';
import ReferralCredits from './ReferralCredits';

const Profile = () => {        
    return (
        <>
            <ProfileField 
                label="First Name"
                name="first_name"
                checkIfValidationError={value => {
                    if (!value) {
                        return 'Please enter your first name.';
                    }
                    
                    if (value.length < 3) {
                        return 'Please enter at least 3 characters.';
                    }

                    return false;
                }}
                Form={props => <Form placeholder="First Name" name="first_name" {...props} />}
            />
            <ProfileField 
                label="Last Name"
                name="last_name"
                checkIfValidationError={value => {
                    if (!value) {
                        return 'Please enter your last name.';
                    }
                    
                    if (value.length < 3) {
                        return 'Please enter at least 3 characters.';
                    }

                    return false;
                }}
                Form={props => <Form placeholder="Last Name" name="last_name" {...props} />}
            />
            <ProfileField 
                label="Email"
                name="email"
                checkIfValidationError={value => {
                    if (!value) {
                        return 'Please enter an email.';
                    }
                    
                    const emailformat = /^("(?:[!#-[\]-\u{10FFFF}]|\\[\t -\u{10FFFF}])*"|[!#-'*+\-/-9=?A-Z^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z^-\u{10FFFF}])*)@([!#-'*+\-/-9=?A-Z^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z^-\u{10FFFF}])*|\[[!-Z^-\u{10FFFF}]*\])$/u;
                    if (!emailformat.test(value)) {
                        return 'Please enter a valid email address.';
                    }

                    return false;
                }}
                Form={props => <Form placeholder="Email" name="email" inputType="email" {...props} />}
            />
            <ProfileField 
                label="Password"
                name="password"
                checkIfValidationError={value => {
                    if (!value) {
                        return 'Please enter a password.';
                    }
                    
                    const strongPassFormat = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/;
                    if (!strongPassFormat.test(value)) {
                        return (
                            <>
                                <p>Your password must...</p>
                                <ul>
                                    <li>be at least 8 characters long</li>
                                    <li>contain at least one number</li>
                                    <li>contain at least one uppercase letter</li>
                                    <li>contain at least one lowercase letter</li>
                                    <li>contain at least one special character</li>
                                    <li>contain no whitespace</li>
                                </ul>
                            </>
                        );
                    }

                    return false;
                }}
                value="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                Form={props => <Form placeholder="Password" name="password" FormInput={PasswordInput} {...props} />}
            />
            <BillingInfo />
            <ReferralCredits />
        </>
    );
};

export default Profile;
