import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleLoginModal, logout } from 'redux/actions';

import UnderlineButton from 'components/_core/UnderlineButton';

const LoginMenuItem = () => {
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => state.user.auth.loggedIn);

    if (loggedIn) {
        return <UnderlineButton onClick={() => dispatch(logout())}>Logout</UnderlineButton>;
    }

    return <UnderlineButton onClick={() => dispatch(toggleLoginModal())}>Login</UnderlineButton>;
};

LoginMenuItem.displayName = 'LoginMenuItem';

export default LoginMenuItem;
