import React from 'react';
import { Link } from 'react-router-dom';
import { getEnvVar } from 'utils/get-env-var';

import styled from 'styled-components';
import logo3x from 'img/logo@3x.png';
import logo2x from 'img/logo@2x.png';
import logo1x from 'img/logo.png';

const LogoImg = styled.img.attrs(props => {
    switch(props.size) {
        case 'xsmall':
            props.width = '50px';
            break;
        case 'small': 
            props.width = '70px';
            break;
        case 'large':
            props.width = '110px';
            break;
        case 'xlarge':
            props.width = '150px';
            break;
        default:
            props.width = '100px';
            break;
    }

    return props;
})`
    width: ${props => props.width};
    
    ${props => {
        if (props.gray) {
            return `
                filter: brightness(40%) grayscale(100%);
            `;
        }
    }}
`;

const LogoCircle = props => <Link to={`${getEnvVar('ROOT_ROUTE')}/`}><LogoImg className="logo-circle" src={logo1x} srcSet={`${logo2x} 2x, ${logo3x} 3x`} {...props}/></Link>;

export default LogoCircle;