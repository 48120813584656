import React from 'react';
import styled from 'styled-components';
import LogoCircle from './LogoCircle';
import LogoText from './LogoText';

const FullLogoWrap = styled.div`
    display: flex;

    ${props => {
        if (props.stacked) {
            return `
                flex-direction: column;
                justify-content: flex-start;
                
                & > a > img {
                    margin-bottom: 10px;
                }
            `;
        }

        return `
            align-items: center;   
            
            & > a > img {
                margin-right: 15px;
            }
        `;
    }}
`;

const FullLogo = props => (
    <FullLogoWrap className="full-logo" {...props}>
        <LogoCircle {...props} />
        <LogoText {...props} />
    </FullLogoWrap>
);

export default FullLogo;