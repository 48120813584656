import React from 'react';
import styled from 'styled-components';

import WithScrollPosition from '../hoc/scroll-position-provider';

import Container from './Container';
import LogoCircle from './LogoCircle';
import InlineNav from './InlineNav';

import { getEnvVar } from 'utils/get-env-var';
import  UnderlineLink, { UnderlineATag } from './UnderlineLink';
import FooterMailChimp from './FooterMailChimp';
import AccountMenuItem from 'components/AccountMenuItem';

const FooterWrapper = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colors.darkGray};
    padding: 0 ${props => props.theme.horizontalPadding};

    & > ${Container} {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 40px 0;

        & > ${InlineNav} {
            flex-grow: 1;

            a, button {
                color: ${props => props.theme.colors.offWhite};
                text-decoration: none;
                font-weight: 100;
                font-size: 14px;
            }

            li {
                margin-right: 30px;
            }
        }
    }

    @media screen and (max-width: 950px) {
        & > ${Container} {
            flex-direction: column;

            & > ${InlineNav} {
                ul {
                    padding: 0;
                }
            }
        }
    }
`;

const FooterInlineNav = styled(InlineNav)`
    @media screen and (max-width: 500px) {
        ul {
            flex-wrap: wrap;
            justify-content: center;
            
            li {
                margin-bottom: 15px;
            }
        }
    }
`;

const MailingListWrapper = styled.div`
    max-width: 250px;
`;

const MailingListHeader = styled.h3`
    color: #ccc;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
`;

const Disclaimer = styled.p`
    font-size: 10px;
    color: #888;
`;

const ROOT_ROUTE = getEnvVar('ROOT_ROUTE');

const Footer = props => (
    <FooterWrapper {...props}>
        <Container>
            <LogoCircle size="small" gray={true} />
            <FooterInlineNav>
                <ul>
                    <li><UnderlineLink to={`${ROOT_ROUTE}/courses`}>Courses</UnderlineLink></li>
                    <li><UnderlineLink to={`${ROOT_ROUTE}/faq`}>FAQ</UnderlineLink></li>
                    <li><UnderlineLink to={`${ROOT_ROUTE}/contact`}>Contact</UnderlineLink></li>
                    <li><UnderlineATag href="https://www.facebook.com/groups/improvisersclub" target="_blank" rel="noopener noreferrer">Improvisors Club</UnderlineATag></li>
                    <li><UnderlineATag href="https://www.rhythmbot.app/" target="_blank" rel="noopener noreferrer">RhythmBot</UnderlineATag></li>
                    <AccountMenuItem />
                </ul>
            </FooterInlineNav>
            
            <MailingListWrapper>
                <MailingListHeader>Sign Up For Email Updates</MailingListHeader>
                <FooterMailChimp />
                <Disclaimer>By entering your email address you agree to receive emails from JPBouvetMethod.com</Disclaimer>
            </MailingListWrapper>
        </Container>
    </FooterWrapper>
);

export default WithScrollPosition(Footer);
