import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSales } from "redux/actions/sales";
import styled from "styled-components";

const Banner = styled.div`
    text-align: center;
    padding: 0 15px;
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 500px) {
        font-size: 14px;
        padding: 0 10px;
    }
`;

const SaleBanner = () => {
    const dispatch = useDispatch();
    const sale = useSelector(state => state.sales.sale);
    const saleFetched = useSelector(state => state.sales.fetched);

    useEffect(() => {
        if (!saleFetched) {
            dispatch(fetchSales());
        }
    }, [dispatch, saleFetched]);

    if (!sale) {
        return null;
    }

    return <Banner style={{ backgroundColor: sale.banner_background_color, color: sale.banner_text_color }}><span dangerouslySetInnerHTML={{ __html: sale.banner_text }} /></Banner>;
};

export default SaleBanner;
