import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProgressPost } from 'redux/actions';

import Button from 'components/_core/Button';
import ProgressPostModal from './ProgressPostModal';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';
import LoaderButton from 'components/_core/LoaderButton';
import Message from 'components/_core/Message';

const ProgressPostDetails = ({ currentVideo, showResponse, setShowResponse }) => {
    const [modalOpen, setModalOpen] = useState(false);

    // if no video uploaded show upload button
    
    return (
        <>
            <Content 
                showResponse={showResponse}
                setShowResponse={setShowResponse}
                setModalOpen={setModalOpen}
                progressPost={currentVideo?.progress_post} 
                videoId={currentVideo?.id}
            />
           
            <ProgressPostModal videoId={currentVideo.id} open={modalOpen} closeModal={() => setModalOpen(false)} />
        </>
    );
};

const Content = ({ progressPost, showResponse, setShowResponse, setModalOpen, videoId }) => {
    const dispatch = useDispatch();
    const [resetting, setResetting] = useState(false);
    const [errorResetting, setErrorResetting] = useState(false);
    const courseId = useSelector(state => state.currentCourse.courseDetails.course.id);

    const reset = useCallback(() => {
        setResetting(true);

        (async () => {
            try {
                await axios.post(`${getEnvVar('API_BASE')}/save-progress-post`, { videoId, courseId, removeVideo: true });
                dispatch(addProgressPost(videoId, null));

            } catch {
                setErrorResetting(true);
            }
        })();

        setResetting(false);
    }, [videoId, courseId, dispatch]);

    const confirmReset = useCallback(() => {
        if (!window.confirm('Are you sure you want to reset this progress post? This will remove your video and the response video.')) {
            return;
        }
        
        reset();
    }, [reset]);

    const confirmRemove = useCallback(() => {
        if (!window.confirm('Are you sure you want to reset this progress post? This will remove your video and the response video.')) {
            return;
        }
        
        reset();
    }, [reset]);

    if (!progressPost) {
        return (
            <>
                <p>Click the button below to upload a Progress Post.</p>
                <Button teal onClick={() => setModalOpen(true)}>Upload a Video</Button>
            </>
        );
    }

    if (!progressPost.response_youtube_id) {
        return (
            <>
                {errorResetting && <Message error small>Error resetting post. Please try again.</Message>}
                {progressPost.response_requested && <p><em>Awaiting a response</em></p>}
                <LoaderButton loading={resetting} disabled={resetting} onClick={confirmRemove}>Remove Video</LoaderButton>
            </>
        );
    }

    if (progressPost.response_youtube_id) {
        return (
            <>
                {errorResetting && <Message error small>Error resetting post. Please try again.</Message>}
                <div style={{ display: 'flex' }}>
                    <Button teal onClick={() => setShowResponse(!showResponse)}>{ showResponse ? 'View My Post' : 'View Response Video' }</Button> 
                    <LoaderButton loading={resetting} disabled={resetting} onClick={confirmReset}>Reset this Progress Post</LoaderButton>
                </div>
            </>
        );
    }

    return null;
}

export default ProgressPostDetails;
