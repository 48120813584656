import { ActionTypes } from 'utils/constants';
import { getDeepProp } from 'utils/get-deep-prop';

const initialState = {};

export const downloads = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_USER_DOWNLOAD_DATA + '_PENDING': {            
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_USER_DOWNLOAD_DATA + '_FULFILLED':
        case ActionTypes.DOWNLOAD_VIDEO + '_FULFILLED': {  
            const data = getDeepProp(action, 'payload', 'data', 'download_data') || {};
          
            return {
                ...state,
                ...data
            };
        }
        case ActionTypes.FETCH_USER_DOWNLOAD_DATA + '_REJECTED': {    
            const error = getDeepProp(action, 'payload', 'response', 'data', 'msg');
        
            return {
                ...state,
                fetching: false,
                fetched: false,
                error
            };
        }
        default: {
            return state;
        }
    }
};
