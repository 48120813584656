import React, { useCallback, useState } from 'react';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { getEnvVar } from 'utils/get-env-var';

import { Field, FieldWrap, FieldLabel, FieldValue, EditButton } from './field-ui';

const BillingInfo = () => {
    const [redirecting, setRedirecting] = useState();
    const cardType = useSelector(state => state.user.subscription.state?.card_type);
    const lastFour = useSelector(state => state.user.subscription.state?.last_four);
    const subId = useSelector(state => state.user.subscription.state?.sub?.id);

    const redirectToStripe = useCallback(() => {
        async function redirect() {
            const checkoutResp = await Axios.get(`${getEnvVar('API_BASE')}/billing-session-id?subId=${subId}`);
            const sessionId = checkoutResp.data.sessionId;
            const stripe = await loadStripe(getEnvVar('STRIPE_PUBLIC_KEY'));
            stripe.redirectToCheckout({ sessionId });
        }

        setRedirecting(true);
        redirect();
    }, [subId, setRedirecting]);

    const cardDescription = cardType && lastFour ? `${cardType} ending in ${lastFour}` : '';

    return (
        <Field>
            <FieldWrap>
                <FieldLabel>Billing Info</FieldLabel>
                <FieldValue>{cardDescription}</FieldValue>
            </FieldWrap>
            <EditButton disabled={redirecting} loading={redirecting} small onClick={() => redirectToStripe()}>Update</EditButton>
        </Field>
    );
};

export default BillingInfo;
