import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import FullLogo from 'components/_core/FullLogo';
import InlineNav from 'components/_core/InlineNav';
import UnderlineLink from 'components/_core/UnderlineLink';
import ProgressCircle from 'components/_core/ProgressCircle';
import { getEnvVar } from 'utils/get-env-var';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl, faTimes } from '@fortawesome/free-solid-svg-icons'

import { toggleCourseOutline } from 'redux/actions';

const CourseHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: ${props => props.theme.colors.darkGray};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    & > ${InlineNav} {
        a {
            color: ${props => props.theme.textColor};
            text-decoration: none;
            font-weight: 100;
            text-transform: uppercase;
            font-size: 16px;
        }

        li {
            margin-right: 45px;
        }
    }
`;

const CourseLogo = styled(FullLogo)`
    @media screen and (max-width: 1000px) {
        flex-grow: 1;
    }
`;

const CourseTitle = styled.div`
    color: ${props => props.theme.textColor};
    margin: 0 30px;
    padding-left: 30px;
    font-weight: 200;
    font-size: 20px;
    border-left: 1px solid #444;
    line-height: 40px;
    flex-grow: 1;

    @media screen and (max-width: 1000px) {
        display: none;
    }
`;

const OutlineTrigger = styled.div`
    color: #ccc;
    font-size: 25px;
    cursor: pointer;
    width: 34px;
    text-align: center;

    @media screen and (min-width: 1001px) {
        display: none;
    }
`;

const percentCompleteSelector = state => {
    const totalCount = state.currentCourse?.courseDetails?.course?.videoCount;
    const completeCount = state.currentCourse?.completeVideos?.ids?.size;

    if (totalCount > 0 && completeCount > 0) {
        return completeCount / totalCount;
    }

    return 0;
};

export default ({ title }) => {
    const dispatch = useDispatch();
    const percentComplete = useSelector(percentCompleteSelector);
    const outlineOpen = useSelector(state=> state.currentCourse.courseOutline.open);

    return (
        <CourseHeader>
            <CourseLogo size="xsmall" />
            <CourseTitle>{title}</CourseTitle>
            <InlineNav>
                <ul>
                    <li><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/courses`}>Courses</UnderlineLink></li>
                </ul>
            </InlineNav>

            <ProgressCircle percent={percentComplete} />

            <OutlineTrigger onClick={() => dispatch(toggleCourseOutline())}><FontAwesomeIcon icon={outlineOpen ? faTimes : faListUl} /></OutlineTrigger>
        </CourseHeader>
    )
};