import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Container from './Container';
import { SubscribeBtn, BrowseCoursesBtn } from 'components/buttons';
import FullLogo from './FullLogo';
import { TRIAL_DAYS } from 'utils/constants';

const HeroContainer = styled(Container)`
    ${props => {
        if (props.backgroundImage) {
            return `
                background-size: auto 100%;
                background-position: right top;
                background-repeat: no-repeat;
                background-image: url(${props.backgroundImage});
                
                @media screen and (max-width: 800px) {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        z-index: 2;
                    }

                    .hero-contents {
                        position: relative;
                        z-index: 3;
                    }
                }

                @media screen and (max-width: 550px) {
                    background-size: 130% auto;
                    background-position: center top;
                }
            `;
        }
    }}

    padding: 90px 0 40px;

    @media screen and (max-width: 800px) {
        padding-bottom: 0;
    }

    @media screen and (max-width: 550px) {
        padding-top: 30px;
    }
`;

const HeroHeading = styled.h1`
    text-transform: uppercase;
    font-family: ${props => props.theme.alternativeFont};
    font-size: 75px;
    font-weight: bold;
    margin: 0;
    margin-top: -5px;
    // text-shadow: 0 0 20px rgba(0, 0, 0, 0.7);

    @media screen and (max-width: 600px) {
        font-size: 45px;
    }
`;

const HeroSubHeading = styled.h2`
    font-family: ${props => props.theme.alternativeFont};
    font-size: 40px;
    font-weight: 100;
    margin: 0;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.7);

    @media screen and (max-width: 600px) {
        font-size: 30px;
    }
`;

const CTA = styled(SubscribeBtn)`
    text-transform: none;
    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;
`;

const Hero = props => {
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    return (
        <HeroContainer backgroundImage={props.backgroundImage}>
            <div className="hero-contents">
                <FullLogo stacked />
                <HeroHeading>{props.children}</HeroHeading>
                {props.subheading && <HeroSubHeading>{props.subheading}</HeroSubHeading>}
                {!props.hideSalesPitch && !isSubscriber && (
                    <>
                        <CTA large orange pill bold>{TRIAL_DAYS > 0 ? 'Try 7 Days Free' : 'Join Now'}</CTA>
                        {!props.hideCoursesBtn && <BrowseCoursesBtn />}
                    </>
                )}
            </div>
        </HeroContainer>
    );
};

export default Hero;