
import React from "react";
import styled from "styled-components";

import RhythmBotImg from "img/homepage/rhythm-bot.png";

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { ButtonA } from "components/_core/Button";

const Wrapper = styled(W)`
    background-color: ${props => props.theme.colors.teal};
`;

const Container = styled(C)`
    padding: 40px 0;
`;

const Layout = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;

    img {
        width: 100%;
    }

    div {
        flex-basis: 50%;
        flew-grow: 1;
        flex-shrink: 1;
    }

    h2 {
        margin-bottom: 20px;
        font-size: 60px;
        line-height: 1.1em;
        text-shadow: 0 5px #00000020;


        .teal {
            color: ${props => props.theme.colors.teal};
        }

        @media screen and (max-width: 600px) {
            font-size: 40px;
        }
    }
    
    h3 {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 1.2em;
        font-weight: 500;
    }

    .content {
        padding-left: 150px;
        max-width: 550px;

        @media screen and (max-width: 1200px) {
            padding-left: 50px;
        }
    }

    .testi {        
        p {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5em;
            font-style: italic;

            &.byline {
                font-style: normal;
            }
        }
    }

    ${ButtonA} {
        text-transform: none;
        border: 2px solid #fff;
        box-shadow: 0px 6px 0 0 #00000021;
        font-weight: 600;
    }

    @media screen and (max-width: 850px) {
        flex-direction: column-reverse;
        text-align: center;

        div {
            flex-basis: 100%;
            margin-top: 50px;
        }

        .content {
            padding-left: 0;
        }
    }
`;

const RhythmBot = () => {
    return (
        <Wrapper>
            <Container>
                <Layout>
                    <div>
                        <img src={RhythmBotImg} alt="RhythmBot"  />
                    </div>
                    <div className="content">
                        <h2>RhythmBot Integration</h2>
                        <h3>Learn to read and create rhythms fluently without even trying.</h3>

                        <div className="testi">
                            <p>“RhythmBot made everything possible for me.”</p>
                            <p className="byline">&mdash; Lyam S.</p>
                        </div>

                        <ButtonA pill teal large href="https://rhythmbot.app/" target="_blank" rel="noopener noreferrer">Explore RhythmBot</ButtonA> 
                    </div>
                </Layout>
            </Container>
        </Wrapper>
    )
    
};

export default RhythmBot;
