import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InlineLoader from 'components/_core/InlineLoader';
import { ListView } from 'components/_core/ListView';
import CourseListItem from 'components/_core/CourseListItem';
import { toggleCourseSearch } from 'redux/actions';

const SearchWrapper = styled.div`
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;

    padding: 60px 15px;

    background-color: #000;

    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s;

    ${props => {
        if (props.open) {
            return `
                opacity: 1;
                pointer-events: all;
            `;
        }
    }}
`;

const SearchContainer = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    transform: translate(-50%, 0);
`;

const SearchTop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;
    height: 150px;
    z-index: 3;

    @media screen and (max-width: 1200px) {
        padding: 30px 15px;
    }
`;

const CloseBtn = styled.div`
    position: absolute;
    top: 49px;
    right: 30px;

    font-size: 80px;
    line-height: 0.6em;
    font-weight: 100;
    cursor: pointer;
    color: #999;

    @media screen and (max-width: 650px) {
        top: 45px;
        font-size: 50px;
    }
`;

const SearchBox = styled.input`
    background: #000;
    outline: none;
    border: none;
    font-size: 50px;
    font-weight: 200;
    -webkit-appearance: none;
    border: 1px solid #999;
    border-radius: 10px;
    width: 100%;
    padding: 10px 100px 10px 30px;

    box-shadow: 0 0 12px 0 rgba(0,0,0,0.5);

    color: #ccc;
    font-family: ${props => props.theme.bodyFont};

    @media screen and (max-width: 650px) {
        font-size: 30px;
        padding: 10px 60px 10px 15px;
    }
`;

const Results = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 150px;

    overflow-y: auto;
    z-index: 2;

    @media screen and (max-width: 650px) {
        padding-top: 120px;
    }
`;

const CourseSearch = () => {
    const dispatch = useDispatch();
    const searchInput = useRef();

    const open = useSelector(state => state.courseList.filters.searchOpen);
    const courses = useSelector(state => state.courseList.courses.courses) || [];
    
    const [searchTerm, setSearchTerm] = useState();

    const results = courses.filter(c => {
        if (!searchTerm || searchTerm.length < 3) {
            return false;
        }

        return (c.name || '').toUpperCase().includes(searchTerm.toUpperCase()) || (c.description || '').toUpperCase().includes(searchTerm.toUpperCase());
    });

    useEffect(() => {
        if (open) {
            searchInput.current.focus();
        }
     },[open]);

    return (
        <SearchWrapper open={open}>
            <SearchContainer>
                <SearchTop>
                    <CloseBtn onClick={() => dispatch(toggleCourseSearch())}>&times;</CloseBtn>
                    <SearchBox ref={searchInput} placeholder="Search Courses" type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                </SearchTop>
                <Results>
                    {!courses.length && <InlineLoader text="Loading Courses" />}
                    <ListView>
                        {results.map(c => <CourseListItem key={c.id} {...c} />)}
                    </ListView>
                </Results>
            </SearchContainer>
        </SearchWrapper>
    );
};

CourseSearch.displayName = 'CourseSearch';

export default CourseSearch;
