import { faFilePdf, faFileVideo } from '@fortawesome/free-solid-svg-icons'
import { getEnvVar } from './get-env-var';
import { downloadVideo } from 'redux/actions';

export default (video, course, canDownloadVideos) => {
    if (!video) {
        return [];
    }

    const actions = [];

    if (video.pdf) {
        actions.push({
            action: () => window.open(`${getEnvVar('PDF_PATH')}/${video.pdf}`),
            icon: faFilePdf,
            label: 'Lesson PDF'
        });
    }

    if (course?.booklet) {
        actions.push({
            action: () => window.open(`${getEnvVar('PDF_PATH')}/${course.booklet}`),
            icon: faFilePdf,
            label: 'Course Book'
        });
    }
    
    const overDownloadLimitText = 'Download limit reached (6)';

    if (video.downloadLinkHD) {
        actions.push({
            disabled: !canDownloadVideos,
            disabledReason: overDownloadLimitText,
            dispatchAction: true,
            action: () => downloadVideo(video.id, 'hd'),
            icon: faFileVideo,
            label: 'Download Video (HD)'
        });
    }

    if (video.downloadLinkSD) {
        actions.push({
            disabled: !canDownloadVideos,
            disabledReason: overDownloadLimitText,
            dispatchAction: true,
            action: () => downloadVideo(video.id, 'sd'),
            icon: faFileVideo,
            label: 'Download Video (SD)'
        });
    }

    return actions;
};