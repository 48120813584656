import { createSelector } from 'reselect';

const course = (state, props) => props.course;

export const firstVideoOfCourse = createSelector(
    [course],
    (course) => {
        if (!course) {
            return null;
        }

        const chapters = course.contents || [];

        if (!chapters.length) {
            return null;
        }

        const videos = chapters[0].videos || [];

        if (!videos.length) {
            return null;
        }

        return videos[0];
    }
);