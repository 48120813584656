import React from 'react';

import Hero from 'components/_core/Hero';
import CoursesHeroImage from 'img/courses-hero.jpg';

const CoursesHero = () => {
    return (
        <Hero hideCoursesBtn backgroundImage={CoursesHeroImage}>Core Curriculum</Hero>
    );
};

export default CoursesHero;