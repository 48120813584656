import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const sendResetPasswordLink = payload => (
    {
        type: ActionTypes.FORGOT_PASSWORD,
        payload: axios.post(`${getEnvVar('API_BASE')}/init-reset-password`, payload)
    }
);

export const validateResetPasswordToken = token => (
    {
        type: ActionTypes.VALIDATE_RESET_PASSWORD_TOKEN,
        payload: axios.post(`${getEnvVar('API_BASE')}/validate-reset-password-token`, { token })
    }
);

export const resetPassword = payload => (
    {
        type: ActionTypes.RESET_PASSWORD,
        payload: axios.post(`${getEnvVar('API_BASE')}/reset-password`, payload)
    }
);
