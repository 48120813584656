import styled from 'styled-components';

const UnderlineButton = styled.button`
    position: relative;
    padding: 0;
    padding-bottom: 3px;
    background: none;
    outline: none;
    appearance: none;
    border: none;
    cursor: pointer;
    font-family: ${props => props.theme.alternativeFont};
    font-weight: 500 !important;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        border-bottom: 2px solid ${props => props.theme.colors.teal};
        transition: width 0.3s;
    }

    &:hover { 
        &:after {
            width: 100%;
        }
    }
`;

export default UnderlineButton;