import { ActionTypes } from 'utils/constants';

export const categories = (state = {}, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_CATEGORIES + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_CATEGORIES + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                error: true
            };
        }
        case ActionTypes.FETCH_CATEGORIES + '_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                categories: action.payload?.data?.categories || []
            };
        }
        default: {
            return state;
        }
    }
};
