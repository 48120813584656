import React, { useState } from "react";
import styled from "styled-components";

const Thumb = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: ${props => props.ratio * 100}%;
    background-color: #000;
    cursor: pointer;

    ${props => !props.largePlayBtn && `
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 0 20px 30px;
            border-color: transparent transparent transparent #fff;
        }
    `}

    // add a light overlay
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.1);
    }


    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${props => props.rounded && `
        border-radius: 10px;

        &::before, img {
            border-radius: 10px;
        }
    `}
`;

const VideoPop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background-color: rgba(0,0,0,0.85);

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 90%;
        max-height: 90%;
    }
`;

const CloseBtn = styled.div`
    position: fixed;
    top: 0px;
    right: 20px;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
`;

const VideoBackground = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    background-color: #000;
    cursor: pointer;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const LargePlayBtn = styled.div`
    background-color: rgba(0,0,0,0.3);
    border: 2px solid #fff;
    border-radius: 8px;
    padding: 10px 20px 10px 45px;

    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);

    font-weight: bold;
    font-size: 18px;
    color: #fff;

    white-space: nowrap;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        z-index: 3;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 14px;
        border-color: transparent transparent transparent #fff;
    }

    ${props => props.small && `
        padding: 5px 10px 5px 30px;
        font-size: 14px;
        bottom: 20px;

        &::after {
            left: 10px;
            border-width: 6px 0 6px 10px;
        }
    `}
`;

const YouTubeVideoThumb = ({ videoId, ratio = 9/16, verticalEmbed, thumb: thumbOverride, largePlayBtn, smallPlayBtn, isVimeo = false, backgroundThumb = false, rounded = false, playBtnText = 'Play Video' }) => {
    const [playing, setPlaying] = useState(false);

    const width = verticalEmbed ? 563 : 1000;
    const height = verticalEmbed ? 1000 : 563;

    const thumb = thumbOverride || `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`;
    const src = isVimeo ? `https://player.vimeo.com/video/${videoId}` : `https://www.youtube.com/embed/${videoId}`;

    return (
        <>
            {backgroundThumb ?
                 <VideoBackground onClick={() => setPlaying(true)}>
                    <iframe 
                        src={src + "?background=1&mute=1"} 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                        title="JPBouvet Method Video"
                    ></iframe>
                    <LargePlayBtn small={smallPlayBtn}>{playBtnText}</LargePlayBtn>
                </VideoBackground>
            : <Thumb ratio={ratio} onClick={() => setPlaying(true)} largePlayBtn={largePlayBtn || smallPlayBtn} rounded={rounded}>
                <img src={thumb} alt="Video Thumbnail" loading="lazy" />
                {(largePlayBtn || smallPlayBtn) && <LargePlayBtn small={smallPlayBtn}>{playBtnText}</LargePlayBtn>}
            </Thumb>}
            {
            playing && 
                <VideoPop onClick={() => setPlaying(false)}>
                    <iframe 
                        width={width} 
                        height={height}
                        src={src + "?autoplay=1"} 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                        title="JPBouvet Method Video"
                    ></iframe>
                    <CloseBtn>&times;</CloseBtn>
                </VideoPop>
            }
        </>
    );
};

export default YouTubeVideoThumb;
