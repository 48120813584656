import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from 'components/_core/Button';
import { fetchReferralCode } from 'redux/actions';
import InlineLoader from 'components/_core/InlineLoader';

const Referrals = styled.div`
    max-width: 450px;
    margin: 60px auto;
    text-align: center;

    h2 {
        margin: 0;
        font-size: 22px;
        text-transform: uppercase;
    }

    h3 {
        margin: 0;
        font-size: 35px;
        text-transform: uppercase;
        color: ${props => props.theme.colors.teal};

        &.used {
            font-size: 20px;
        }
    }
`;

const ReferralCode = styled.div`
    background-color: #ddd;
    border: 1px solid #666;
    color: #666;
    padding: 15px 20px;
    margin: 15px 0;
`;

const ReferFriend = () => {
    const dispatch = useDispatch();
    const code = useSelector(state => state.user.referAFriend.code);
    const reward = useSelector(state => state.user.referAFriend.reward);
    const uses = useSelector(state => state.user.referAFriend.uses);
    const limit = useSelector(state => state.user.referAFriend.limit);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        dispatch(fetchReferralCode());
    }, [dispatch])

    if (!code) {
        return <InlineLoader text="Loading referral code" />;
    }

    const link = `https://jpbouvetmethod.com/referral/${code}`;

    if (uses >= limit) {
        return (
            <Referrals>
                <h2>Refer a Friend</h2>
                <h3 className="used">You have used all of your referrals!</h3>
            </Referrals>
        );
    }

    return (
        <Referrals>
            <h2>Refer a Friend</h2>
            <h3>Give ${reward}, get ${reward}</h3>
            {uses && uses > 0 ? <h2>{uses} of {limit} used</h2> : null}
            <p>When they sign up using your share link, a $30 credit will be automatically added to your account.</p>
            <ReferralCode>{link}</ReferralCode>
            <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
                <Button orange>{copied ? 'Copied' : 'Copy Link'}</Button>
            </CopyToClipboard>
        </Referrals>
    );
};

export default ReferFriend;
