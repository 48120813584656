import styled from 'styled-components';

const ToolTip = styled.div`
    position: absolute;
    bottom: 100%;
   
    background-color: #555;
    padding: 8px 12px;
    color: #fff;
    font-size: 11px;
    white-space: nowrap;
    margin-bottom: 15px;
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
        top: 100%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(51, 51, 51, 0);
        border-top-color: #555;
        border-width: 9px;
    }

    ${props => {
        if (props.direction === 'right') {
            return `
                right: -5px;

                &:after {
                    right: 15px;
                }
            `;
        } else {
            return `
                left: 50%;
                transform: translateX(-50%);

                &:after {
                    left: 50%;
                    margin-left: -9px;
                }
            `;
        }
    }}
`;

export default ToolTip;
