export const humanList = list => {
    if (!list || list.lenght === 0) {
        return '';
    }

    if (list.length === 1){
        return list[0];
    }

    return list.join(', ');
};
