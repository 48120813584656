import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';
import { setAxiosAuthHeader } from 'utils/set-axios-auth-header';

export const validateToken = jwt => {
    setAxiosAuthHeader(jwt);

    return {
        type: ActionTypes.VALIDATE_JWT,
        payload: axios.post(`${getEnvVar('API_BASE')}/validate-jwt`, { jwt })
    }
};

export const logout = () => (
    {
        type: ActionTypes.LOGOUT
    }
);
