import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Items from './Items';
import Lines from './Lines';

const Wrapper = styled.div`
    text-align: center;
`;

const NodesWrapper = styled.div``;

const LevelLabel = styled.h2`
    text-transform: uppercase;
    display: inline-block;
    padding: 0 20px;
    font-size: 24px;
    margin: 0 0 15px 0;
    position: relative;
    z-index: 2;
    background-color: #000;

    color: ${props => props.theme.levelColors[props.level]};
`;

const LevelWrapper = styled.div`
    padding: 30px;
    border-bottom: 1px dashed #666;

    &:last-child {
        border-bottom: none;
    }

    &:first-child {
        ${LevelLabel} {
            margin-bottom: 30px;
        }
    }
`;

const Level = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
`;

const CurriculumGrid = () => {
    const curriculum = useSelector(state => state.courseList.curriculum.data);
    const levels = ['beginner', 'intermediate', 'advanced'];

    return (
        <Wrapper>
            <Items items={curriculum.items} />
            <NodesWrapper>
                {levels.map((level, i) =>
                    <LevelWrapper key={`level-${i}`}>
                        <LevelLabel level={level}>{level}</LevelLabel>
                        <Level>
                            {curriculum.nodes[level].map((row, e) => <Items key={`row-${e}`} storePos items={row} />)}
                        </Level>
                    </LevelWrapper>
                )}
            </NodesWrapper>
            <Lines />
        </Wrapper>
    )
};

export default CurriculumGrid;
