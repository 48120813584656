import React from 'react';
import { useDispatch } from 'react-redux';

import Item from './Item';
import { setCurriculumLinePos } from 'redux/actions';

const Items = ({ items, storePos }) => {
    const dispatch = useDispatch();

    const maybeStorePos = (idx, pos, item) => {
        if (storePos && item.type !== 'blank') {
            dispatch(setCurriculumLinePos(idx, pos));
        }
    };
    
    return (items || []).map((item, idx) => {
        return <Item key={`item-${idx}`} setPos={pos => maybeStorePos(idx, pos, item)} item={item} />;
    });
};

export default Items;
