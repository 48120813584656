const { useEffect } = require("react")
const { useDispatch, useSelector } = require("react-redux");
const { fetchUserDownloadData } = require("redux/actions");

const DownloadDataFetcher = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.user.downloads.fetched);

    useEffect(() => {
        if (!fetched) {
            dispatch(fetchUserDownloadData());
        }
    }, [dispatch, fetched]);

    return null;
};

export default DownloadDataFetcher;
