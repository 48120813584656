import React, { useMemo } from "react";
import styled from "styled-components";
import { getEnvVar } from "utils/get-env-var";


const PreviewListWrap = styled.div`
    margin-top: 30px;
    border-top: 1px solid #eee;
    padding-top: 10px;

    h2 {
        @media screen and (max-width: 500px) {
            font-size: 20px
        }
    }
`;


const PreviewList = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

const Video = styled.div`
    padding: 0 10px;
    flex-basis: 33.3333%;

    @media screen and (max-width: 768px) {
        flex-basis: 50%;
    }
`;

const PreviewThumb = styled.div`
    cursor: pointer;
    position: relative;

    width: 100%;
    padding-top: 56.25%;

    img {
        width: 100%;    
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 1;
        border-radius: 5px;
    }

    &:before {
        content: '';
        display: block;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        transition: background-color 0.3s;
        z-index: 2;
    }

    &:after {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-left-color: rgba(255, 255, 255, 0.8);
        transition: border-left-color 0.3s;

        border-width: 10px;
        border-left-width: 15px;
        margin-left: 8px;
        z-index: 3;
    }

    &:hover {
        &:before {
            background-color: rgba(0, 0, 0, 0.85);
        }

        &:after {
            border-left-color: rgba(255, 255, 255, 1);
        }
    }

    img {
        width: 100%;
    }
`;

const PreviewText = styled.p`
    color: ${props => props.theme.colors.lightGrayText};
    margin: 10px 0 10px;
    font-weight: 600;
    font-size: 13px;
`;

const PreviewVideoList = ({ videos: _videos = [], openPreview, mainPreviewId, mainPreviewPhoto }) => {    
    const videos = useMemo(() => {
        const videos = [...(_videos || [])];

        if (mainPreviewId) {
            videos.unshift({
                vimeo_id: mainPreviewId,
                thumb: mainPreviewPhoto,
                name: "Course Introduction",
            });
        }

        return videos;
    }, [_videos, mainPreviewId, mainPreviewPhoto]);

    if (!videos.length) {
        return null;
    }
    

    return (
        <PreviewListWrap>
            <h2>What Am I Getting Into?</h2>
            <PreviewList>
                {videos.map((video, i) => (
                    <Video key={i} onClick={() => openPreview(video.vimeo_id)}>
                        <PreviewThumb>
                            <img src={`${getEnvVar('UPLOAD_PATH')}/thumb_${video.thumb}`} alt={video.name} />
                        </PreviewThumb>
                        <PreviewText>{video.name}</PreviewText>
                    </Video>
                ))}
            </PreviewList>
        </PreviewListWrap>
    );
};

export default PreviewVideoList;
