
import React from "react";
import styled from "styled-components";

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { SubscribeBtn as S } from "components/buttons";
import { TRIAL_DAYS } from "utils/constants";
import { Testimonial } from "./TestimonialGrid";

const Wrapper = styled(W)``;

const SubscribeBtn = styled(S)`
    text-transform: none;
    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;
`;

const Container = styled(C)`
    padding: 30px 0;
    text-align: center;
    max-width: 650px;

    h2 {
        font-size: 35px;
        font-weight: 300;
        line-height: 1.2em;
        
        strong {
            font-style: italic;
            font-weight: 700;
        }
    }

    ${Testimonial} {
        color: #fff;
        font-weight: 600;
        margin-top: 30px;
        font-size: 18px;
        margin: 30px auto 0;

        max-width: 400px;

        p {
            font-style: italic;

            &.byline {
                font-style: normal;
            }
        }
    }
`;

const FinalWords = () => {
    return (
        <Wrapper>
            <Container>
                <h2>Join the most <strong>academically rigorous</strong> online drum school in the world, and <strong>finally play what's in your head.</strong></h2>
                <SubscribeBtn pill large>{TRIAL_DAYS ? `Start ${TRIAL_DAYS} days FREE` : 'Join Now'}</SubscribeBtn>

                <Testimonial>
                    <p>“You and your site have changed my drumming life more than you could imagine. Thank you.”</p>
                    <p className="teal byline">&mdash; Nathan B.</p>
                </Testimonial>
            </Container>
        </Wrapper>
    );
};

export default FinalWords;
