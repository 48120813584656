import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import { paramsFromQuerystring }  from 'utils/params-from-querystring';
import { getEnvVar } from 'utils/get-env-var';

import Header from 'components/_core/Header';
import Container from 'components/_core/Container';
import LoginMenuItem from 'components/LoginMenuItem';
import Message from 'components/_core/Message';
import LoaderButton from 'components/_core/LoaderButton';
import PasswordInput from 'components/_core/PasswordInput';
import InlineLoader from 'components/_core/InlineLoader';

import Wrapper from 'components/_core/Wrapper';
import Footer from 'components/_core/Footer';

import { validateResetPasswordToken as validateToken, resetPassword } from 'redux/actions';

const navItems = [
    () => <li><LoginMenuItem /></li>
];

const ResetPasswordContainer = styled(Container)`
    margin: 100px auto 60px;
    max-width: 600px;
`;

const ResetPageWrap = ({ children }) => (
    <>
        <Wrapper>
            <Header navItems={navItems} />
            <ResetPasswordContainer>
                <h2>Reset Your Password</h2>
                {children}
            </ResetPasswordContainer>
        </Wrapper>
        <Footer />
    </>
);

const ResetPassword = () => {
    const dispatch = useDispatch();
    const processing = useSelector(state => state.user.resetPassword.processing);
    const backendError = useSelector(state => state.user.resetPassword.error);
    const success = useSelector(state => state.user.resetPassword.success);
    const tokenValid = useSelector(state => state.user.resetPassword.tokenValid);
    const tokenError = useSelector(state => state.user.resetPassword.tokenError);
    
    const [password, setPassword] = useState();
    const [validationError, setValidationError] = useState();

    const qsParams = paramsFromQuerystring();
    const token = qsParams.token;

    useEffect(() => {
        if (token) {
            dispatch(validateToken(token));
        }
    }, [dispatch, token]);

    if (tokenError) {
        return <ResetPageWrap><Message error small style={{ textAlign: 'left' }}>{tokenError}</Message></ResetPageWrap>;
    }

    if (!tokenValid) {
        return <ResetPageWrap><InlineLoader text="Validating request" /></ResetPageWrap>;
    }

    if (success) {
        const ROOT_ROUTE = getEnvVar('ROOT_ROUTE');
        return <Redirect to={`${ROOT_ROUTE}/`} />;
    }
    
    const submit = e => {
        e.preventDefault();
        if (!password) {
            return setValidationError('Please enter a new password');
        }

        const strongPassFormat = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/;
        if (!strongPassFormat.test(password)) {
            setValidationError(
                <>
                    <p>Your password must...</p>
                    <ul>
                        <li>be at least 8 characters long</li>
                        <li>contain at least one number</li>
                        <li>contain at least one uppercase letter</li>
                        <li>contain at least one lowercase letter</li>
                        <li>contain at least one special character</li>
                        <li>contain no whitespace</li>
                    </ul>
                </>
            );

            return;
        }

        setValidationError(null);

        return dispatch(resetPassword({ token, password }));
    };

    return (
        <ResetPageWrap>
            <p>Enter your new password below</p>
            {(validationError || backendError) && <Message error small style={{ textAlign: 'left' }}>{validationError || backendError}</Message>}
            <form onSubmit={submit}>
                <PasswordInput 
                    name="password"
                    placeholder="Password"  
                    disabled={processing}
                    onChange={e => setPassword(e.target.value)} />
                <LoaderButton orange fluid bold disabled={processing} loading={processing}>Save Password</LoaderButton>
            </form>
        </ResetPageWrap>
    );
}

export default ResetPassword;
