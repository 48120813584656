import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/_core/Button';
import { toggleJoinModal } from 'redux/actions';

export const SubscribeBtn = (
    {
        children,
        ...props
    }
) => {
    const dispatch = useDispatch();
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    if (isSubscriber) {
        return null;
    }

    return (
        <Button className="btn" orange bold {...props} onClick={() => dispatch(toggleJoinModal())}>{children || 'Sign Up Now'}</Button>
    );
};
