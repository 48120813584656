import React from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';

const Lines = () => {
    const linePos = useSelector(state => state.courseList.curriculum.linePos);
    return linePos.map((pos, idx) => <Line key={`line-${idx}`} pos={pos} />);
};

const TheLine = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: 0;
    height: ${props => props.height}px;

    border-left: 1px dashed #666;
    z-index: 1;
`;

const Line = ({ pos }) => {
    const top = pos.top.top;
    const height = (pos.bottom.top + pos.bottom.height) - top;
    const left = pos.bottom.left + (pos.bottom.width / 2) - 1;

    return <TheLine clasName="line" top={top} height={height} left={left} />;
};

export default Lines;
