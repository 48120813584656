import React from 'react';

const PageContent = ({ name, copy }) => {

    return (
        <>
            <h1>
                <div dangerouslySetInnerHTML={{ __html: name }} />
            </h1>
            <div className="page-copy">
                <div dangerouslySetInnerHTML={{ __html: copy }} />
            </div>
        </>
    );
}

export default PageContent;
