import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LEVEL_MAPPING } from 'utils/constants';

import Select from 'components/_core/Select';
import { setFilter } from 'redux/actions';

const LevelFilter = () => {
    const dispatch = useDispatch();
    const value = useSelector(state => state.courseList.filters.level);
    const levelOpts = Object.keys(LEVEL_MAPPING).map(value => ({ value: value !== 'all_levels' ? value : '', label: LEVEL_MAPPING[value] }));

    return (
        <Select 
            options={levelOpts} 
            placeholder="All Levels" 
            width={180} 
            useLevelColors={true} 
            value={value} 
            onChange={val => dispatch(setFilter('level', val))} 
        />
            
    );
};

export default LevelFilter;
