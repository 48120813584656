import styled from 'styled-components';
import Container from '../_core/Container';

const WhiteContainer = styled(Container)`
    margin: 60px auto;
    padding: 60px;
    background-color: #fff;
    color: ${props => props.theme.colors.midGray};

    h2 {
        font-size: 50px;
        margin: 0;
        font-weight: 600;
    }

    p {
        font-size: 17px;
        line-height: 1.8em;
    }

    @media screen and (max-width: 650px) {
        margin-left: -20px;
        width: calc(100% + 40px);
        padding: 40px 20px;
    }
`;

export default WhiteContainer;