import { createSelector } from 'reselect';

const course = (state, props) => props.course;
const currentVideo = state => state.currentCourse.currentVideo;

export const nextVideoOfCourse = createSelector(
    [course, currentVideo],
    (course, currentVideo) => {
        if (!course) {
            return null;
        }

        const chapters = course.contents || [];

        const allVideos = chapters.reduce((a, c) => {
            const chapterVideos = (c.videos || []);

            a = a.concat(chapterVideos);

            return a;
        }, []);

        let currentIndex = -1;
        if (currentVideo && currentVideo.id && !currentVideo.isPreview) {
            // find index of video
            currentIndex = ([...allVideos].map(v => v.id)).indexOf(currentVideo.id);
        }


        if (allVideos.length <= currentIndex + 1) {
            return null;
        }
        
        const checkIfNextIdxIsVideo = nextIdx => {
            const video = allVideos[nextIdx];
            if (!video || video.type !== 'link') {
                return video;
            }

            return checkIfNextIdxIsVideo(++nextIdx);
        };

        const nextVideo = checkIfNextIdxIsVideo(currentIndex + 1);

        return nextVideo;
    }
);